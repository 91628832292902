import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import {
  useListenDraftPrimaryColorQuery,
  useListenSalesAboutImageQuery,
  useUpdateSalesAboutSectionMutation,
} from 'modules/PageBuilder/pageBuilderApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { SALES_FIELDS_MAXLENGTH } from 'modules/PageBuilder/constants/salesConstants'
import PreviewInput from '../Inputs/PreviewInput'
import ImageUpload from '../Inputs/ImageUpload'
import { inputNames, inputRefsSortMethod } from '../../constants/salesInputConstants'
import { getLegacyAboutMeBodyText } from 'modules/PageBuilder/utils/textFormatUtils'

const LINE_BREAK_REGEX = /(\r\n|\n|\r)/gm
const DRAFT_IMG_ID = 'sales-single-images'
const IMG_ASPECT_RATIO = '9/16'
const W_TO_H_RATIO = 9 / 16

export default function SalesAboutPreview({ coachOrgId, section, isMob }) {
  const [header, setHeader] = useState(section.header)
  const [body, setBody] = useState(section.aboutBodyText)
  const [aboutMeCTA, setAboutMeCTA] = useState(section.aboutMeCTA)

  const { data: isImgUploading } = useListenExistingItemDraftsQuery({ coachOrgId, id: DRAFT_IMG_ID })
  const { data: aboutImg } = useListenSalesAboutImageQuery({ coachOrgId })
  const { data: primaryColorData } = useListenDraftPrimaryColorQuery({ coachOrgId })
  const primaryColor = primaryColorData || '#000'

  const [updateSection] = useUpdateSalesAboutSectionMutation()

  useEffect(() => {
    // If heroHeading was never edited in the dashboard, populate it with legacy mainText and mainTextLine2
    const legacyAboutMeBodyText = getLegacyAboutMeBodyText(section)
    if (typeof section?.aboutBodyText !== 'string' && legacyAboutMeBodyText.length !== 0) {
      updateSection({ coachOrgId, section: { ...section, aboutBodyText: legacyAboutMeBodyText } })
    }
    // eslint-disable-next-line
  }, [section])

  const debouncedHeader = useDebounce(header, 500)
  useDebouncedTextMutation({
    stateText: header,
    dbText: section.header,
    mutation: updateSection,
    debouncedStateText: debouncedHeader,
    mutationArgs: {
      coachOrgId,
      section: { ...section, header: debouncedHeader },
    },
  })

  const debouncedBody = useDebounce(body, 500)
  useDebouncedTextMutation({
    stateText: body,
    dbText: section.aboutBodyText,
    mutation: updateSection,
    debouncedStateText: debouncedBody,
    mutationArgs: {
      coachOrgId,
      section: { ...section, aboutBodyText: debouncedBody },
    },
  })

  const debouncedAboutMeCTA = useDebounce(aboutMeCTA, 500)
  useDebouncedTextMutation({
    stateText: aboutMeCTA,
    dbText: section.aboutMeCTA,
    mutation: updateSection,
    debouncedStateText: debouncedAboutMeCTA,
    mutationArgs: {
      coachOrgId,
      section: { ...section, aboutMeCTA: debouncedAboutMeCTA },
    },
  })

  useEffect(() => {
    setHeader(section.header)
    setBody(section.aboutBodyText)
    setAboutMeCTA(section.aboutMeCTA)
  }, [section])

  const { addInputRef } = useFormRefsControl()
  const aboutTitleRef = useRef()
  const aboutDescriptionRef = useRef()
  const aboutBtnTextRef = useRef()
  useEffect(() => {
    addInputRef({ ref: aboutTitleRef, name: inputNames.aboutTitle, sortMethod: inputRefsSortMethod })
    addInputRef({
      ref: aboutDescriptionRef,

      name: inputNames.aboutDescription,
      sortMethod: inputRefsSortMethod,
    })
    addInputRef({ ref: aboutBtnTextRef, name: inputNames.aboutBtnText, sortMethod: inputRefsSortMethod })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='bg-white'>
      <div
        className='flex items-center justify-center w-full max-w-5xl py-20 mx-auto'
        css={[isMob && tw`px-2 py-5 flex-col-reverse`]}
      >
        <div css={[tw`flex flex-col`, isMob ? tw`w-full mr-0 mt-5` : tw`w-1/2 mr-12`]}>
          <PreviewInput
            inputRef={aboutTitleRef}
            name={inputNames.aboutTitle}
            inputCss={[isMob ? tw`text-xl` : tw`text-4xl`, tw`font-bold mb-2`]}
            value={header}
            maxLength={SALES_FIELDS_MAXLENGTH.about.header}
            onChange={(_, value) => setHeader(value.replace(LINE_BREAK_REGEX, ''))}
          />
          <PreviewInput
            inputRef={aboutDescriptionRef}
            name={inputNames.aboutDescription}
            inputCss={[isMob ? tw`text-base` : tw`text-lg`, tw`text-gray-500`]}
            value={body}
            maxLength={SALES_FIELDS_MAXLENGTH.about.body}
            onChange={(_, value) => setBody(value)}
          />
          <PreviewInput
            inputRef={aboutBtnTextRef}
            name={inputNames.aboutBtnText}
            inputType='button'
            inputCss={[isMob ? tw`text-xl w-44 mt-6 mx-auto` : tw`ml-2 mt-10 `, tw`placeholder:text-gray-200`]}
            value={aboutMeCTA}
            maxLength={SALES_FIELDS_MAXLENGTH.about.aboutCTA}
            onChange={(_, value) => setAboutMeCTA(value)}
            style={{ backgroundColor: primaryColor }}
          />
        </div>
        <div css={[isMob ? tw`w-full px-2` : tw`w-1/2`]} className='group'>
          <div
            className='relative rounded-none mx-auto'
            css={[!aboutImg && tw`bg-gray-200 rounded-lg`, isMob ? tw`h-[450px]` : tw`h-[550px]`]}
            style={{
              aspectRatio: IMG_ASPECT_RATIO,
            }}
          >
            {aboutImg && <img src={aboutImg} alt='single-img' className='flex w-full h-full' />}
            <div
              className='hidden group-hover:flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
              css={(isImgUploading || !aboutImg) && tw`flex`}
            >
              <ImageUpload
                coachOrgId={coachOrgId}
                id={DRAFT_IMG_ID}
                uploadType='sales-single-image'
                aspectRatio={IMG_ASPECT_RATIO}
                wToHRatio={W_TO_H_RATIO}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
