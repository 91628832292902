import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty, sortBy } from 'lodash'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenProductPricingQuery,
  useListenCouponsQuery,
  useListenLegacyProductPricingQuery,
  useListenStripeProductIdQuery,
  useSetProductPricesMutation,
} from '../productInfoApi'

import { createUID } from 'common/utils/createUID'

import ProductInfoLayout from '../ProductInfoLayout'
import PricingList from '../components/PricingList/PricingList'

export default function ProductInfoPricing() {
  const navigate = useNavigate()
  const { userId } = useAuth()

  const [setProductPrices, { isLoading: isUpdatingProductPrices }] = useSetProductPricesMutation()

  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: legacyPlansData } = useListenLegacyProductPricingQuery({ coachOrgId })
  const legacyProductPlansLoading = legacyPlansData === undefined || legacyPlansData?.isLoading
  const legacyProductPlans = legacyProductPlansLoading || !legacyPlansData ? [] : legacyPlansData

  const { data: plansData } = useListenProductPricingQuery({ coachOrgId })
  const productPlansLoading = plansData === undefined || plansData?.isLoading
  const productPlans = productPlansLoading || !plansData ? {} : plansData
  const sortedProductPlans = sortBy(productPlans, (plan) => plan.idx)

  const { data: stripeProductId } = useListenStripeProductIdQuery({ coachOrgId })
  const stripeProductIdLoading = stripeProductId === undefined || stripeProductId?.isLoading
  // Prefetch coupons for pricing form
  const { data: coupons } = useListenCouponsQuery({ coachOrgId })
  const couponsLoading = coupons === undefined || coupons?.isLoading

  const isLoading =
    legacyProductPlansLoading ||
    productPlansLoading ||
    couponsLoading ||
    stripeProductIdLoading ||
    isUpdatingProductPrices

  useEffect(() => {
    if (!isLoading && !stripeProductId) {
      navigate('/productinfo')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, stripeProductId])

  useEffect(() => {
    // Migrate legacy array based plans to new object based plans
    if (!productPlansLoading && !legacyProductPlansLoading && isEmpty(productPlans) && !isEmpty(legacyProductPlans)) {
      let updatedPlans = {}
      legacyProductPlans.forEach((plan, index) => {
        const planId = createUID()
        updatedPlans[planId] = {
          ...plan,
          idx: plan?.idx || index,
          id: planId,
          stripe_plan_id: plan?.stripe_price_id || plan?.stripe_plan_id,
        }
      })
      setProductPrices({ coachOrgId, plans: updatedPlans })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPlansLoading, legacyProductPlansLoading])

  return (
    <ProductInfoLayout hasStripeProductId={Boolean(stripeProductId)}>
      <PricingList coachOrgId={coachOrgId} coupons={coupons} plans={sortedProductPlans} loading={isLoading} />
    </ProductInfoLayout>
  )
}
