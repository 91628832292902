import { each, find, round, startCase } from 'lodash'
import { capitalizeFirstLetter, maybePluralize } from 'common/utils/stringUtils'
import { createUID } from 'common/utils/createUID'
import { MIN_LIFETIME_PLAN_DISCOUNTED_PRICE, MIN_REPEATING_COUPON_DISCOUNTED_PRICE } from '../constants/priceConstants'

export const showDiscountByType = ({ type, discount }) => {
  if (type === 'trialOnly') {
    return 'None'
  } else if (type === 'fixed') {
    return `$${(discount / 100).toFixed(2)} USD`
  } else {
    return `${discount}%`
  }
}

export const getCouponDurationText = ({ coupon }) => {
  if (coupon.type === 'trialOnly') {
    return !coupon?.trialPeriodDays ? 'Set on plan' : `${startCase(coupon.trialPeriodDays)} days`
  } else if (coupon.duration === 'repeating') {
    return `${coupon.durationInMonths} ${maybePluralize({ count: coupon.durationInMonths, text: 'month' })}`
  } else {
    return startCase(coupon.duration)
  }
}

export const getCouponSuggestionDurationText = ({ coupon }) => {
  if (coupon.type === 'trialOnly') {
    return !coupon?.trialPeriodDays ? 'Set on plan' : `${startCase(coupon.trialPeriodDays)} days`
  } else {
    return startCase(coupon.duration)
  }
}

export const calcDiscountValue = ({ type, price, discount }) => {
  if (type === 'fixed') {
    return discount
  } else {
    const discountCents = (price * discount) / 100
    return round(discountCents / 100, 2) * 100 // convert from cents, round 2 two decimal points and return cents
  }
}

export const calcPriceWithDiscount = ({ type, price, discount }) => {
  if (type === 'trialOnly') {
    return price
  }

  const priceWithDiscount = price - calcDiscountValue({ type, price, discount })
  if (priceWithDiscount < 0) {
    return 0
  }

  return priceWithDiscount
}

export const getUnusedBillingPeriod = (plans) => {
  const periods = { 'month-1': false, 'month-6': false, 'year-1': false, lifetime: false }

  each(plans, (plan) => {
    if (plan?.isLifetimePurchase) {
      periods.lifetime = true
    } else {
      periods[`${plan.time_unit}-${plan.time_val}`] = true
    }
  })

  const firstUnused = find(Object.entries(periods), ([_, isUsed]) => !isUsed)?.at(0) || 'month-1'

  if (firstUnused === 'lifetime') {
    return { unit: 'lifetime', val: 1 }
  } else {
    return { unit: firstUnused.split('-')[0], val: firstUnused.split('-')[1] }
  }
}

export const getDefaultPlanPrice = ({ interval, intervalCount }) => {
  if (interval === 'year') {
    return 15999
  } else if (interval === 'month' && Number(intervalCount) === 6) {
    return 9599
  } else if (interval === 'month' && Number(intervalCount) === 1) {
    return 1999
  } else if (interval === 'lifetime') {
    return 29999
  }
}

export const getDefaultNextPlan = (plans) => {
  const billingPeriod = getUnusedBillingPeriod(plans)
  const defaultPlanPrice = getDefaultPlanPrice({ interval: billingPeriod.unit, intervalCount: billingPeriod.val })

  if (billingPeriod?.unit === 'lifetime') {
    return {
      id: createUID(),
      idx: plans.length,
      price: defaultPlanPrice,
      isLifetimePurchase: true,
      time_unit: 'lifetime',
      time_val: '1',
    }
  } else {
    return {
      id: createUID(),
      idx: plans.length,
      price: defaultPlanPrice,
      time_unit: billingPeriod.unit,
      time_val: Number(billingPeriod.val),
    }
  }
}

export const getPlanPromoPrice = ({ coupon, plan }) => {
  const isFree =
    (coupon.type === 'percentage' && coupon.discount === 100) ||
    (coupon.type === 'fixed' && coupon.discount >= plan.price)
  const discountPriceValue = calcDiscountValue({
    type: coupon.type,
    discount: coupon.discount,
    price: plan.price,
  })
  const priceWithDiscount = plan.price - discountPriceValue

  return isFree ? 0 : priceWithDiscount
}

export const getPlanIntervalText = (plan) => {
  const pluralizedTimeUnit = maybePluralize({ count: plan.time_val, text: plan.time_unit })

  if (plan?.isLifetimePurchase) {
    return 'for lifetime access'
  } else {
    return `billed ${plan.time_val > 1 ? `every ${plan.time_val}` : 'once a'} ${pluralizedTimeUnit}`
  }
}

export const getPlanPromoIntervalText = (plan) => {
  if (plan?.isLifetimePurchase) {
    return 'for lifetime access'
  } else {
    return plan.time_unit === 'month' && plan.time_val === 1 ? 'first month' : 'first payment'
  }
}

export const getPaymentIntervalText = ({ plan }) => {
  const pluralizedTimeUnit = maybePluralize({ count: plan.time_val, text: plan.time_unit })

  if (plan.time_val !== 1) {
    return `every ${plan.time_val} ${pluralizedTimeUnit}`
  } else if (plan.time_unit === 'month') {
    return 'monthly'
  } else {
    return 'anually'
  }
}

export const getPaymentIntervalInMonths = ({ plan }) => {
  if (plan.time_unit === 'month') {
    return Number(plan.time_val)
  } else if (plan.time_unit === 'year') {
    return Number(plan.time_val) * 12
  }
}

export const getPlanPromoBottomText = ({ coupon, plan }) => {
  const isFree =
    (coupon.type === 'percentage' && coupon.discount === 100) ||
    (coupon.type === 'fixed' && coupon.discount >= plan.price)
  const interval = getPlanPromoIntervalText(plan)
  const discountPriceValue = calcDiscountValue({
    type: coupon.type,
    discount: coupon.discount,
    price: plan.price,
  })

  const planPriceText = `$${(plan.price / 100).toFixed(2)}`
  const payIntervalText = getPaymentIntervalText({
    plan,
  })
  const trialPeriodDays = coupon?.trialPeriodDays || plan.trialPeriodDays || null
  const trialText = trialPeriodDays ? ` ${trialPeriodDays} day trial.` : ''

  if (coupon?.type === 'trialOnly') {
    return `Coupon applied: ${trialText}`
  }

  if (isFree) {
    if (plan?.isLifetimePurchase) {
      return 'Coupon applied: FREE lifetime access'
    } else {
      if (coupon?.duration === 'forever') {
        return 'Coupon applied: FREE forever'
      } else {
        const paymentIntervalInMonths = getPaymentIntervalInMonths({ plan })
        if (coupon?.duration === 'repeating' && Number(coupon?.durationInMonths) > paymentIntervalInMonths) {
          const couponAppliedTimes = Math.ceil(Number(coupon?.durationInMonths) / paymentIntervalInMonths)
          const freeText = `${couponAppliedTimes * paymentIntervalInMonths} months FREE`
          return `Coupon applied: ${freeText}. ${planPriceText} ${payIntervalText} after. ${trialText}`
        } else {
          const freeText = `${capitalizeFirstLetter(interval)} FREE`
          return `Coupon applied: ${freeText}. ${planPriceText} ${payIntervalText} after. ${trialText}`
        }
      }
    }
  } else {
    const discountPriceText =
      coupon.type === 'percentage' ? `${coupon.discount}%` : `$${(discountPriceValue / 100).toFixed(2)}`
    if (plan?.isLifetimePurchase) {
      return `Coupon applied: ${discountPriceText} OFF lifetime access`
    } else {
      if (coupon?.duration === 'forever') {
        return `Coupon applied: ${discountPriceText} OFF all future payments. ${trialText}`
      } else {
        const paymentIntervalInMonths = getPaymentIntervalInMonths({ plan })
        const planPriceText = `$${(plan.price / 100).toFixed(2)}`
        const payAfterDiscountText = `${planPriceText} ${payIntervalText} after`
        if (coupon?.duration === 'repeating' && Number(coupon?.durationInMonths) > paymentIntervalInMonths) {
          const couponAppliedTimes = Math.ceil(Number(coupon?.durationInMonths) / paymentIntervalInMonths)
          const promoText = `${discountPriceText} OFF ${couponAppliedTimes * paymentIntervalInMonths} months`
          return `Coupon applied: ${promoText}. ${payAfterDiscountText}. ${trialText}`
        } else {
          const promoText = `${discountPriceText} OFF first ${interval === 'first month' ? 'month' : 'payment'}`
          return `Coupon applied: ${promoText}. ${payAfterDiscountText}. ${trialText}`
        }
      }
    }
  }
}

export const getIsValidPlanDiscount = ({ plan, coupon }) => {
  const discountedPrice = getPlanPromoPrice({ plan, coupon })
  if (
    plan?.isLifetimePurchase &&
    (discountedPrice < MIN_LIFETIME_PLAN_DISCOUNTED_PRICE || coupon?.type === 'trialOnly')
  ) {
    return false
  } else if (
    !plan?.isLifetimePurchase &&
    (coupon?.duration === 'forever' || coupon?.duration === 'repeating') &&
    discountedPrice < MIN_REPEATING_COUPON_DISCOUNTED_PRICE
  ) {
    return false
  } else {
    return true
  }
}

export const getIsCouponActive = ({ promo, coupon }) => {
  const nowTime = new Date()
  // promo is the legacy alwaysActive, startDate and expiration
  // they were moved to coupon
  const alwaysActive = typeof coupon?.alwaysActive !== 'undefined' ? coupon?.alwaysActive : Boolean(promo?.alwaysActive)
  const startTime = typeof coupon?.startDate !== 'undefined' ? coupon?.startDate : promo?.startDate
  const expirationTime = typeof coupon?.expiration !== 'undefined' ? coupon?.expiration : promo?.expiration
  const isCouponActive = alwaysActive || (nowTime > startTime && nowTime < expirationTime)
  return isCouponActive
}
