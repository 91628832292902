import 'twin.macro'
import React, { useEffect, useState } from 'react'

import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { Sortable } from 'common/components/Sortable/Sortable'
import { dropAnimation } from 'common/utils/dndUtils'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useSetTabContentMutation } from '../../layoutApi'

import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { rowContainerBaseStyles } from '../styles'
import { LayoutRow } from '../Row/LayoutRow'

export default function LayoutRows({ tabContent, selectedTabEntry, handleAddRow }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [tabId] = selectedTabEntry

  const [saveTabContent] = useSetTabContentMutation()

  // Drag and drop
  const [activeDragRow, setActiveDragRow] = useState(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    setActiveDragRow(tabContent.find((row) => row.id === active.id))
  }

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = tabContent.findIndex((row) => row.id === active.id)
      const newIndex = tabContent.findIndex((row) => row.id === over.id)
      const updatedTabContent = arrayMove(tabContent, oldIndex, newIndex)
      await saveTabContent({
        coachOrgId,
        tabContent: updatedTabContent,
        tabId,
      })
      setActiveDragRow(null)
    }
  }

  const noRows = !tabContent || tabContent?.length === 0
  useEffect(() => {
    if (noRows) {
      handleAddRow()
    }
    // eslint-disable-next-line
  }, [])

  if (noRows) {
    return <EmptyStateContainer text='' action={handleAddRow} actionText='Add row' />
  }

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={tabContent.map((item) => item.id)} strategy={verticalListSortingStrategy}>
          {tabContent.map((item) => (
            <Sortable
              key={item.id}
              id={item.id}
              withHandle={true}
              css={rowContainerBaseStyles}
              draggingClasses='ring-2 ring-tGreen opacity-50'
              className='group'
            >
              <LayoutRow
                id={item.id}
                currentRow={item}
                currentTabContent={tabContent}
                coachOrgId={coachOrgId}
                tabId={tabId}
              />
            </Sortable>
          ))}
        </SortableContext>
        <DragOverlay
          zIndex={10}
          dropAnimation={dropAnimation}
          className='cursor-move'
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          {activeDragRow ? (
            <LayoutRow
              id={activeDragRow.id}
              currentRow={activeDragRow}
              currentTabContent={tabContent}
              coachOrgId={coachOrgId}
              tabId={tabId}
              isDragging={true}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  )
}
