import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import {
  useListenSalesHighlightsImageQuery,
  useUpdateSalesHightlightsSectionMutation,
} from 'modules/PageBuilder/pageBuilderApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { SALES_FIELDS_MAXLENGTH } from 'modules/PageBuilder/constants/salesConstants'
import ImageUpload from '../Inputs/ImageUpload'
import PreviewInput from '../Inputs/PreviewInput'
import { HighlightsInputList } from '../HighlightsList/HighlightsInputList'
import { inputNames, inputRefsSortMethod } from '../../constants/salesInputConstants'

const LINE_BREAK_REGEX = /(\r\n|\n|\r)/gm
const HIGHLIGHTS_IMG_DRAFT_ID = 'sales-trifold-image'
const IMG_ASPECT_RATIO = '1/1'
const W_TO_H_RATIO = 1 / 1

export default function SalesHighlightsPreview({ coachOrgId, section, isMob }) {
  const [header, setHeader] = useState(section.header)

  const { data: isImgUploading } = useListenExistingItemDraftsQuery({ coachOrgId, id: HIGHLIGHTS_IMG_DRAFT_ID })
  const { data: hightlightsImg } = useListenSalesHighlightsImageQuery({ coachOrgId })

  const [updateSection] = useUpdateSalesHightlightsSectionMutation()

  const debouncedHeader = useDebounce(header, 500)
  useDebouncedTextMutation({
    stateText: header,
    dbText: section.header,
    mutation: updateSection,
    debouncedStateText: debouncedHeader,
    mutationArgs: {
      coachOrgId,
      section: { ...section, header: debouncedHeader },
    },
  })

  useEffect(() => {
    setHeader(section.header)
  }, [section])

  const { addInputRef } = useFormRefsControl()
  const hlSectionTitleRef = useRef()
  useEffect(() => {
    addInputRef({ ref: hlSectionTitleRef, name: inputNames.hlSectionTitle, sortMethod: inputRefsSortMethod })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='flex items-center justify-center py-5 bg-white w-full' css={[!isMob && tw`py-20`]}>
      <div css={[tw`flex items-center justify-center w-full max-w-5xl`, isMob && tw`flex-col`]}>
        <div css={[isMob ? tw`w-full px-4` : tw`w-1/2`]} className='group'>
          <div
            className='relative w-full rounded-none mx-auto'
            css={[!hightlightsImg && tw`bg-gray-200 rounded-lg`]}
            style={{
              aspectRatio: IMG_ASPECT_RATIO,
            }}
          >
            {hightlightsImg && <img src={hightlightsImg} alt='trifold-img' className='flex w-full h-full' />}
            <div
              className='hidden group-hover:flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
              css={(isImgUploading || !hightlightsImg) && tw`flex`}
            >
              <ImageUpload
                coachOrgId={coachOrgId}
                id={HIGHLIGHTS_IMG_DRAFT_ID}
                uploadType={HIGHLIGHTS_IMG_DRAFT_ID}
                aspectRatio={IMG_ASPECT_RATIO}
                wToHRatio={W_TO_H_RATIO}
              />
            </div>
          </div>
        </div>
        <div css={[tw`flex flex-col`, isMob ? tw`w-full mt-5 ml-0` : tw`w-1/2 ml-12`]}>
          <div css={[isMob && tw`px-2`]}>
            <PreviewInput
              inputRef={hlSectionTitleRef}
              name={inputNames.hlSectionTitle}
              inputCss={[isMob ? tw`text-xl` : tw`text-4xl`, tw`font-bold mb-2`]}
              value={header}
              maxLength={SALES_FIELDS_MAXLENGTH.programSummary.header}
              onChange={(_, value) => setHeader(value.replace(LINE_BREAK_REGEX, ''))}
            />
          </div>
          <HighlightsInputList coachOrgId={coachOrgId} highlights={section.highlights || []} isMob={isMob} />
        </div>
      </div>
    </div>
  )
}
