import { useEffect, useRef, useState } from 'react'

import {
  useListenSalesImagesQuery,
  useUpdateSalesHeroImgDeskPosMutation,
  useUpdateSalesHeroImgMobPosMutation,
} from '../pageBuilderApi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'

const INITIAL_IMG_BG_POSITION = { x: 0, y: 0 }

export function useImgReposition({ coachOrgId, isMob }) {
  const { createAlert } = useAlert()

  const heroImgContainerRef = useRef()

  const [isImgPosEditActive, setIsImgPosEditActive] = useState(false)
  const [draggingImg, setDraggingImg] = useState(false)
  const [backgroundPosition, setBackgroundPosition] = useState(INITIAL_IMG_BG_POSITION)
  const [start, setStart] = useState(null)
  const [initialPosition, setInitialPosition] = useState(null)

  const [updateSalesHeroImgMobPos] = useUpdateSalesHeroImgMobPosMutation()
  const [updateSalesHeroImgDeskPos] = useUpdateSalesHeroImgDeskPosMutation()

  const { data: salesImageData } = useListenSalesImagesQuery({ coachOrgId })
  const imagesLoading = salesImageData === undefined || salesImageData?.isLoading
  const mobileImgPosition = salesImageData?.heroImgMobilePosition
  const desktopImgPosition = salesImageData?.heroImgDesktopPosition

  useEffect(() => {
    if (!imagesLoading && isMob && mobileImgPosition) {
      setIsImgPosEditActive(false)
      setBackgroundPosition(mobileImgPosition || INITIAL_IMG_BG_POSITION)
    }

    if (!imagesLoading && !isMob && desktopImgPosition) {
      setIsImgPosEditActive(false)
      setBackgroundPosition(desktopImgPosition || INITIAL_IMG_BG_POSITION)
    }
    //
  }, [isMob, imagesLoading, mobileImgPosition, desktopImgPosition])

  const startRepositioning = () => {
    setIsImgPosEditActive(true)
  }

  const handleMouseDown = (e) => {
    if (!isImgPosEditActive) {
      return
    }

    setDraggingImg(true)
    setStart({ x: e.clientX, y: e.clientY })
    setInitialPosition(backgroundPosition)
  }

  const handleMouseMove = (e) => {
    if (!draggingImg || !isImgPosEditActive) {
      return
    }

    const deltaX = (e.clientX - start.x) * 10 // move 10 times more than than cursor for more responsive repositioning
    const deltaY = (e.clientY - start.y) * 10 // move 10 times more than than cursor for more responsive repositioning

    const heroImgContainerRect = heroImgContainerRef?.current?.getBoundingClientRect()
    const newX = Math.max(
      0,
      Math.min(100, initialPosition.x + (deltaX / (heroImgContainerRect?.width || window.innerWidth)) * 100)
    )
    const newY = Math.max(
      0,
      Math.min(100, initialPosition.y + (deltaY / (heroImgContainerRect?.height || window.innerHeight)) * 100)
    )

    setBackgroundPosition({ x: newX, y: newY })
  }

  const handleMouseUp = () => {
    setDraggingImg(false)
  }

  const cancelRepositioning = () => {
    const bgPosition = isMob
      ? mobileImgPosition || INITIAL_IMG_BG_POSITION
      : desktopImgPosition || INITIAL_IMG_BG_POSITION
    setInitialPosition(bgPosition)
    setBackgroundPosition(bgPosition)
    setIsImgPosEditActive(false)
  }

  const savePosition = () => {
    if (isMob) {
      updateSalesHeroImgMobPos({ coachOrgId, position: backgroundPosition })
    } else {
      updateSalesHeroImgDeskPos({ coachOrgId, position: backgroundPosition })
    }
    setIsImgPosEditActive(false)
    createAlert({ text: 'Image position updated!', type: 'success' })
  }

  return {
    heroImgContainerRef,
    isImgPosEditActive,
    backgroundPosition,
    draggingImg,
    startRepositioning,
    savePosition,
    cancelRepositioning,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  }
}
