import { sortBy } from 'lodash'

export const getLegacyAboutMeBodyText = (aboutMeData) => {
  let aboutMeBodyValues = []

  Object.entries(aboutMeData).forEach(([key, text]) => {
    if (key.includes('body')) {
      // body texts can be body_2, body_3, etc.
      const index = key.split('_')[1] || 0
      aboutMeBodyValues.push({ text, index })
    }
  })
  const aboutMeBodyText = sortBy(aboutMeBodyValues, (a) => a.index)
    .map((a) => a.text)
    .join('\n')
  return aboutMeBodyText
}

export function getLegacyHeroText(heroData) {
  let heroMainTextValues = []

  Object.entries(heroData).forEach(([key, text]) => {
    if (key.includes('mainText')) {
      // hero mainTexts can be mainText, mainTextLine2, etc.
      const index = Number(key[key.length - 1]) || 0
      heroMainTextValues.push({ text, index })
    }
  })
  const heroMainText = sortBy(heroMainTextValues, (a) => a.index)
    .map((a) => a.text)
    .join('\n')
  return heroMainText
}
