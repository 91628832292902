import React, { useEffect, useRef, useState } from 'react'
import { CgCheckO, CgCloseO, CgInfo, CgTrash } from 'react-icons/cg'
import { FiMove } from 'react-icons/fi'
import tw from 'twin.macro'
import { isEqual } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { Combobox } from '@headlessui/react'
import { format } from 'date-fns'
import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import {
  useListenCouponsQuery,
  useListenStripeProductIdQuery,
  useListenStripeResponseQuery,
  useSetProductPriceMutation,
  useSetProductPricePromoMutation,
  useSetProductPricePromosMutation,
  useSetStripeRequestMutation,
} from 'modules/ProductInfo/productInfoApi'

import { Dialog, DialogContent } from 'common/components/Dialog/Dialog'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { createUID } from 'common/utils/createUID'
import PriceInput from './PriceInput'
import BillTypeInput from './BillTypeInput'
import { Switch } from 'common/components/Switch/Switch'
import { Input, inputBase } from 'common/components/Input/Input'
import CouponsSuggestions from './CouponsSuggestions'
import { CreateDialogBanner } from 'common/components/CreateDialogBanner/CreateDialogBanner'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { dropAnimation } from 'common/utils/dndUtils'
import { Sortable } from 'common/components/Sortable/Sortable'
import { usePopper } from 'common/hooks/usePopper'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { MAX_TRIAL_DAYS } from '../../constants/trialConstants'
import { getIsCouponActive } from 'modules/ProductInfo/utils/utils'

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack`
export const InputError = tw.p`flex text-xs mt-1 text-tRed`

export function PricingForm({ coachOrgId, plan, plans, closeForm, handleDelete, isCreateForm = false }) {
  const { createAlert } = useAlert()

  const [withTrial, setWithTrial] = useState(!!plan.trialPeriodDays)
  const [couponSearchValue, setCouponSearchValue] = useState('')
  const [trialPeriodErr, setTrialPeriodErr] = useState(null)
  const [billTypeErr, setBillTypeErr] = useState(null)
  const [priceErr, setPriceErr] = useState(null)
  const [isCouponMoved, setIsCouponMoved] = useState(false)

  const [setPricePromos] = useSetProductPricePromosMutation()
  const [setPrice] = useSetProductPriceMutation()
  const [setStripeRequest] = useSetStripeRequestMutation()

  const { data: stripeProductId } = useListenStripeProductIdQuery({ coachOrgId })
  const { data: stripeResponse } = useListenStripeResponseQuery({ coachOrgId, responseId: plan?.id })
  const stripeResponsePending = stripeResponse?.status === 'pending'
  const { data: couponsData } = useListenCouponsQuery({ coachOrgId })
  const coupons = couponsData || {}

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [addCouponConfirmation, setAddCouponConfirmation] = useState(null)

  const defaultValues = {
    idx: plan.idx,
    id: plan.id,
    price: plan.price,
    time_unit: plan?.isLifetimePurchase ? 'lifetime' : plan.time_unit,
    time_val: plan?.isLifetimePurchase ? '1' : plan.time_val,
    promo: plan?.promo || [],
    promoText: plan?.promoText || '',
    trialPeriodDays: plan?.trialPeriodDays || null,
    stripe_plan_id: plan?.stripe_plan_id || null,
    isLifetimePurchase: plan?.isLifetimePurchase || null,
    paymentType: plan?.paymentType || 'subscription',
  }
  const methods = useForm({
    defaultValues,
  })
  const {
    watch,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()

  useEffect(() => {
    setValue('promo', plan?.promo || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan?.promo])

  let usedBillingTypes = []
  plans.forEach((pln) => {
    if (pln.idx === plan.idx) {
      return
    }
    if (pln.time_unit === 'year' && pln.time_val === 1) {
      usedBillingTypes.push('year-1')
    } else if (pln.time_unit === 'month' && pln.time_val === 1) {
      usedBillingTypes.push('month-1')
    } else if (pln.time_unit === 'month' && pln.time_val === 6) {
      usedBillingTypes.push('month-6')
    } else {
      usedBillingTypes.push('lifetime-1')
    }
  })

  const validatePrice = (price) => {
    if (formState?.isLifetimePurchase && Number(price) < 29999) {
      setPriceErr('Lifetime plan price should be at least $299.99')
      return false
    } else if (Number(price) < 499) {
      setPriceErr('Price should be at least $4.99')
      return false
    } else {
      setPriceErr(null)
      return true
    }
  }

  const handleCreatePlan = async ({ formData }) => {
    const payload = {
      idx: formData.idx,
      stripeProductId,
      unitAmount: formData.price,
      interval: formData?.isLifetimePurchase ? 'lifetime' : formData.time_unit,
      intervalCount: formData?.isLifetimePurchase ? '1' : Number(formData.time_val),
      trybePlanId: formData.id,
    }
    await setPrice({ coachOrgId, plan: formData, planId: formData.id })
    const requestId = createUID()
    setStripeRequest({
      requestId,
      coachOrgId,
      type: 'CREATE_PRICE',
      payload,
    })
    closeForm()
    createAlert({ text: 'Plan created!', type: 'success' })
  }

  const handleUpdatePlan = async ({ defaultValues, formData }) => {
    const oneOfStripeFieldsChanged =
      formData.price !== defaultValues.price ||
      formData.time_unit !== defaultValues.time_unit ||
      Number(formData.time_val) !== Number(defaultValues.time_val)

    const updatedPlan = {
      ...formData,
      interval: formData?.isLifetimePurchase ? 'lifetime' : formData.time_unit,
      intervalCount: formData?.isLifetimePurchase ? '1' : Number(formData.time_val),
    }
    await setPrice({ coachOrgId, plan: updatedPlan, planId: formData.id })
    createAlert({ text: 'Plan updated!', type: 'success' })
    closeForm()

    if (oneOfStripeFieldsChanged) {
      const payload = {
        stripeProductId,
        unitAmount: formData.price,
        interval: formData?.isLifetimePurchase ? 'lifetime' : formData.time_unit,
        intervalCount: formData?.isLifetimePurchase ? '1' : Number(formData.time_val),
        stripePriceId: formData.stripe_plan_id,
        trybePlanId: formData?.id,
      }
      const requestId = createUID()
      setStripeRequest({
        requestId,
        coachOrgId,
        type: 'UPDATE_PRICE',
        payload,
      })
    }
  }

  const onSubmit = async (data) => {
    const isPriceValid = validatePrice(data?.price)
    if (priceErr || !isPriceValid) {
      priceInputRef?.current?.focus()
      return
    } else if (trialPeriodErr) {
      trialPeriodInputRef?.current?.focus()
      return
    } else if (billTypeErr) {
      billTypeInputRef?.current?.focus()
      return
    }

    const trialDays = Number(data.trialPeriodDays) || null
    const formData = {
      ...data,
      trialPeriodDays: data?.isLifetimePurchase ? null : trialDays,
      isLifetimePurchase: data?.isLifetimePurchase ? true : null,
      paymentType: data?.isLifetimePurchase ? 'one-time' : 'subscription',
    }

    if (isCreateForm) {
      handleCreatePlan({ formData })
    } else {
      handleUpdatePlan({ defaultValues, formData })
    }
  }

  const handleAddCoupon = async (couponId) => {
    const coupon = coupons[couponId]

    if (
      !addCouponConfirmation &&
      Boolean(coupon?.trialPeriodDays) &&
      Boolean(plan?.trialPeriodDays) &&
      Number(coupon.trialPeriodDays) < Number(plan.trialPeriodDays)
    ) {
      setAddCouponConfirmation(coupon)
      return
    }

    const pricingPromo = {
      stripeCouponId: coupon.id,
      isAutoApplied: false,
    }

    const newPromos = plan.promo ? [...plan.promo, pricingPromo] : [pricingPromo]
    await setPricePromos({ coachOrgId, planId: plan.id, promos: newPromos })
    createAlert({ text: 'Coupon is added', type: 'success' })
  }

  const handleDeletePromo = ({ stripeCouponId }) => {
    const newPromos = plan.promo.filter((pr) => pr.stripeCouponId !== stripeCouponId)
    setPricePromos({ coachOrgId, planId: plan.id, promos: newPromos })
    createAlert({ text: 'Coupon removed', type: 'success' })
  }

  // Drag and drop
  const [activeDragInput, setActiveDragInput] = useState(null)

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    const activePromo = plan.promo?.find((prm) => prm.stripeCouponId === active.id)
    const coupon = Object.values(coupons).find((coupon) => coupon.id === activePromo.stripeCouponId)
    setActiveDragInput({ promo: activePromo, coupon })
  }

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = plan.promo?.findIndex((prm) => prm.stripeCouponId === active.id)
      const newIndex = plan.promo?.findIndex((prm) => prm.stripeCouponId === over.id)

      const newPromos = arrayMove(plan.promo, oldIndex, newIndex)
      await setPricePromos({ coachOrgId, planId: plan.id, promos: newPromos })
      setActiveDragInput(null)
      setIsCouponMoved(true)
    }
  }

  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const planPromoIds = plan?.promo?.map((p) => p.stripeCouponId)

  const { addInputRef, removeInputRef, moveFocusOnKeyPress } = useFormRefsControl()
  const inputRefsSortMethod = [
    'price',
    'billType',
    'promoText',
    'trialPeriodDays',
    'startDate',
    'endDate',
    'coupUpdateBtn',
    'couponSearch',
    'submit',
  ]
  const priceInputRef = useRef()
  const billTypeInputRef = useRef()
  const promoTextInputRef = useRef()
  const trialPeriodInputRef = useRef()
  const couponInputRef = useRef()
  const submitRef = useRef()
  useEffect(() => {
    addInputRef({ ref: priceInputRef, name: 'price', sortMethod: inputRefsSortMethod })
    addInputRef({ ref: billTypeInputRef, name: 'billType', sortMethod: inputRefsSortMethod })
    addInputRef({ ref: promoTextInputRef, name: 'promoText', sortMethod: inputRefsSortMethod })
    addInputRef({ ref: couponInputRef, name: 'couponSearch', sortMethod: inputRefsSortMethod })
    addInputRef({ ref: submitRef, name: 'submit', sortMethod: inputRefsSortMethod })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (withTrial) {
      addInputRef({ ref: trialPeriodInputRef, name: 'trialPeriodDays', sortMethod: inputRefsSortMethod })
    } else {
      removeInputRef(trialPeriodInputRef, 'trialPeriodDays')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withTrial])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, () => onSubmit(formState)))

  return (
    <>
      <FormProvider {...methods}>
        <div className='divide-y divide-gray-200 overflow-auto' css={[!isCreateForm && tw`border-b border-gray-200`]}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id='pricingForm'
            name='Pricing form'
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault() //Otherwise form autosubmits on each enter press
              }
            }}
          >
            <div className='flex flex-col p-4' css={[isCreateForm && tw`px-10`]}>
              <div className='mb-2'>
                <Label htmlFor='price' tw='mb-1'>
                  Price
                </Label>
                <PriceInput
                  inputRef={priceInputRef}
                  name='price'
                  placeholder='0.00'
                  value={formState.price}
                  onChange={(price) => {
                    validatePrice(price)
                    setValue('price', Number(price))
                  }}
                  error={Boolean(priceErr)}
                  autoFocus={true}
                />
                {Boolean(priceErr) && <InputError>{priceErr}</InputError>}
              </div>
            </div>
            <div className='flex flex-col p-4 border-t border-gray-200' css={[isCreateForm && tw`px-10`]}>
              <div className='mb-2'>
                <Label htmlFor='time_unit' tw='mb-1'>
                  Billing period
                </Label>
                <BillTypeInput
                  inputRef={billTypeInputRef}
                  value={`${formState.time_unit}-${formState.time_val}`}
                  setValue={setValue}
                  usedBillingTypes={usedBillingTypes}
                  error={billTypeErr}
                  setError={setBillTypeErr}
                />
                {errors && errors.time_unit && <InputError>{errors.time_unit.message}</InputError>}
              </div>
            </div>
            <div className='flex flex-col p-4 border-t border-gray-200' css={[isCreateForm && tw`px-10`]}>
              <div className='mb-2'>
                <Label htmlFor='promoText' tw='mb-1'>
                  Promo text
                </Label>
                <Input
                  inputRef={promoTextInputRef}
                  name='promoText'
                  register={register}
                  placeholder='Enter promo text'
                />
                {errors && errors.promoText && <InputError>{errors.promoText.message}</InputError>}
              </div>
            </div>
            {formState?.isLifetimePurchase ? (
              <div className='flex flex-col p-4 border-t border-gray-200' css={[isCreateForm && tw`px-10`]}>
                <p className='text-tBlack font-semibold'>Include a free trial</p>
                <p className='text-gray-500 text-sm'>Free trials are only compatible with subscriptions</p>
              </div>
            ) : (
              <div className='flex flex-col p-4 border-t border-gray-200' css={[isCreateForm && tw`px-10`]}>
                <Switch
                  onChange={() => {
                    setWithTrial(!withTrial)
                    if (!withTrial) {
                      setValue('trialPeriodDays', 7)
                    } else {
                      setValue('trialPeriodDays', null)
                    }
                  }}
                  label='Include a free trial'
                  isChecked={withTrial}
                />
                {withTrial && (
                  <div className='mt-2'>
                    <Label htmlFor='trialPeriodDays' tw='mb-1'>
                      Trial period in days
                    </Label>
                    <Input
                      inputRef={trialPeriodInputRef}
                      type='number'
                      name='trialPeriodDays'
                      register={register}
                      min={1}
                      autoFocus={!defaultValues.trialPeriodDays}
                      registerOptions={{
                        onChange: (e) => {
                          const value = parseInt(e.target.value) || ''
                          if (value < 1) {
                            setTrialPeriodErr('Trial period must be at least 1 day')
                          } else if (Boolean(value) && value > MAX_TRIAL_DAYS) {
                            setTrialPeriodErr(`Trial period must be less than ${MAX_TRIAL_DAYS} days`)
                          } else {
                            setTrialPeriodErr(null)
                          }
                          setValue('trialPeriodDays', value)
                        },
                      }}
                      error={trialPeriodErr}
                    />
                    {trialPeriodErr && <InputError>{trialPeriodErr}</InputError>}
                  </div>
                )}
              </div>
            )}
            {!isCreateForm && (
              <div className='flex flex-col p-4 border-t border-gray-200'>
                <div className='flex items-center mb-1'>
                  <Label htmlFor='headlessui-combobox-input-1' tw='mr-1'>
                    Coupons
                  </Label>
                  <Tooltip
                    content='If multiple coupons are active, only the first one will be applied'
                    triggerClasses='cursor-pointer text-gray-500 hover:text-tGreen'
                  >
                    <CgInfo className='w-4 h-4' />
                  </Tooltip>
                </div>
                {Boolean(plan.promo) && (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext items={planPromoIds} strategy={verticalListSortingStrategy}>
                      {plan.promo.map((promo, idx) => {
                        const coupon = Object.values(coupons).find((coupon) => coupon.id === promo.stripeCouponId)

                        return (
                          <Sortable
                            key={promo.stripeCouponId}
                            id={promo.stripeCouponId}
                            withHandle={true}
                            className='group relative mb-2.5 last:mb-0'
                            draggingClasses='ring-2 ring-tGreen opacity-50 rounded-lg'
                          >
                            <PromoCoupon
                              coachOrgId={coachOrgId}
                              promo={promo}
                              plan={plan}
                              coupon={coupon}
                              promoIdx={idx}
                              handleDeletePromo={handleDeletePromo}
                            />
                          </Sortable>
                        )
                      })}
                    </SortableContext>
                    <DragOverlay
                      zIndex={10}
                      className='cursor-move'
                      dropAnimation={dropAnimation}
                      modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
                    >
                      {activeDragInput && (
                        <PromoCoupon promo={activeDragInput.promo} coupon={activeDragInput.coupon} isDragging={true} />
                      )}
                    </DragOverlay>
                  </DndContext>
                )}
                {(plan.promo?.length || !plan.promo <= 10) && (
                  <div className='relative'>
                    <Combobox
                      value={null}
                      onChange={(couponId) => {
                        if (couponId) {
                          handleAddCoupon(couponId)
                        }
                      }}
                    >
                      <div ref={popperTrigger} className='w-full'>
                        <Combobox.Input
                          name='couponSearch'
                          ref={(e) => {
                            couponInputRef.current = e
                          }}
                          placeholder='Enter coupon name'
                          autoComplete='off'
                          displayValue={(couponId) => (couponId ? coupons[couponId].name || '' : '')}
                          css={[inputBase, tw`px-4`]}
                          onChange={(e) => setCouponSearchValue(e.target.value)}
                        />
                      </div>
                      {couponSearchValue.length > 0 && (
                        <CouponsSuggestions
                          coupons={coupons}
                          searchValue={couponSearchValue}
                          plan={plan}
                          inputRef={couponInputRef}
                          popperContainer={popperContainer}
                        />
                      )}
                    </Combobox>
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
        {deleteConfirmation && !isCreateForm && (
          <DeleteConfirmationBanner
            text='Are you sure?'
            handleDelete={handleDelete}
            handleGoBack={() => setDeleteConfirmation(false)}
            loading={stripeResponsePending}
            containerClasses='shadow-none !px-4'
          />
        )}
        {!deleteConfirmation && (
          <UpdateActions
            ref={submitRef}
            itemKey={isCreateForm ? null : `${plan?.idx}`}
            containerClasses={isCreateForm ? null : 'shadow-none !px-4'}
            loading={stripeResponsePending}
            disabled={isCreateForm ? false : isEqual(formState, defaultValues) && !isCouponMoved}
            handleDelete={isCreateForm ? null : () => setDeleteConfirmation(true)}
            hideDelete={!`${plan?.idx}`}
            actionText={isCreateForm ? 'Add plan' : 'Update'}
            form='pricingForm'
          />
        )}
      </FormProvider>
      <Dialog open={Boolean(addCouponConfirmation)} setOpen={setAddCouponConfirmation}>
        <DialogContent>
          <CreateDialogBanner
            text={`If applied, the coupon that you are adding will reduce the plan trial period from ${plan?.trialPeriodDays} to ${addCouponConfirmation?.trialPeriodDays}.`}
            handleCreate={() => handleAddCoupon(addCouponConfirmation?.id)}
            createBtnText='Add'
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

const PromoCoupon = React.memo(
  ({ coachOrgId, plan, promo, coupon, promoIdx, isDragging, handleDeletePromo, listeners, attributes }) => {
    const [setPricePromo] = useSetProductPricePromoMutation()
    const startDate = typeof coupon?.startDate !== 'undefined' ? coupon?.startDate : promo?.startDate
    const expirationDate = typeof coupon?.expiration !== 'undefined' ? coupon?.expiration : promo?.expiration
    const isCouponActive = getIsCouponActive({ promo, coupon })

    return (
      <div className='flex rounded-lg border-2 border-gray-300 bg-white'>
        <div className='flex flex-1 items-start '>
          <div className='flex flex-col flex-1 px-2.5 py-2'>
            <p className='text-sm font-medium mb-1'>{coupon?.name || 'Coupon name'}</p>
            <span className='text-xs font-medium text-gray-500'>
              {`ID: ${promo.stripeCouponId}` || '(couponid-autogenerated)'}
            </span>
          </div>
          <div className='flex flex-col px-2.5 py-2 w-2/12'>
            <p className='text-sm font-medium mb-1'>Start</p>
            <span className='text-xs font-medium text-gray-500'>{startDate ? format(startDate, 'M/d/y') : 'N/A'}</span>
          </div>
          <div className='flex flex-col px-2.5 py-2 w-2/12'>
            <p className='text-sm font-medium mb-1'>End</p>
            <span className='text-xs font-medium text-gray-500'>
              {expirationDate ? format(expirationDate, 'M/d/y') : 'N/A'}
            </span>
          </div>
          <div className='flex flex-col px-2.5 py-2'>
            <p className='text-sm font-medium mb-1'>Auto-applied</p>
            <Switch
              onChange={() =>
                setPricePromo({
                  coachOrgId,
                  planId: plan.id,
                  promoIdx,
                  promo: { ...promo, isAutoApplied: !promo.isAutoApplied },
                })
              }
              isChecked={Boolean(promo.isAutoApplied)}
              switchOuterClasses='!w-7 !h-[18px]'
              switchInnerClasses='!w-2.5 !h-2.5'
            />
          </div>
          <div className='flex flex-col items-center px-2.5 py-2'>
            <p className='text-sm font-medium mb-1'>Active</p>
            <span className='text-xs'>
              {isCouponActive ? (
                <CgCheckO className='w-4 h-4 text-tGreen' />
              ) : (
                <CgCloseO className='w-4 h-4 text-gray-500' />
              )}
            </span>
          </div>
        </div>
        {!isDragging && (
          <div css={actionContainerClasses} className='group-hover:visible'>
            <Tooltip content='Delete'>
              <CgTrash
                className='cursor-pointer w-[18px] h-[18px] text-gray-500 hover:text-tRed'
                onClick={() => handleDeletePromo({ stripeCouponId: promo?.stripeCouponId })}
              />
            </Tooltip>
            <Tooltip content='Drag to reorder' triggerClasses='flex ml-1.5'>
              <button {...attributes} {...listeners} tabIndex={-1}>
                <FiMove className='cursor-move w-3.5 h-3.5 my-[3px] text-gray-500 hover:text-tGreen' />
              </button>
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
)

export const actionContainerClasses = tw`
  invisible absolute 
  z-20 right-2 
  top-2 flex 
  items-center py-1 
  px-1.5 rounded-md 
  border-[1px] bg-white 
  border-gray-200 shadow-sm
`
