import React from 'react'

import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenAllOrgSlugsQuery } from '../pageBuilderApi'

import Loading from 'modules/App/components/Loading'
import { CoachSlugForm } from '../components/CoachSlugForm/CoachSlugForm'

export default function CoachSlugEdit() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: allOrgSlugsData } = useListenAllOrgSlugsQuery()
  const coachOrgSlugLoading = allOrgSlugsData === undefined || allOrgSlugsData?.isLoading
  const allOrgSlugs = allOrgSlugsData || {}

  if (coachOrgSlugLoading) {
    return <Loading showHeader={false} />
  }

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-16'>
      <div className='w-full max-w-2xl mx-auto mt-12 mb-20'>
        <h1 className='text-5xl font-bold mb-5 text-tBlack'>Page Handle</h1>
        <div className='flex flex-col p-8 bg-white w-full rounded-2xl'>
          <FormRefsControlProvider>
            <CoachSlugForm coachOrgId={coachOrgId} allOrgSlugs={allOrgSlugs} />
          </FormRefsControlProvider>
        </div>
      </div>
    </div>
  )
}
