export const inputIds = {
  highlightTitleId: 'highlightTitleId',
}

export const inputNames = {
  heroTitle: 'heroTitle',
  heroSubtitle: 'heroSubtitle',
  heroBtnText: 'heroBtnText',
  hlSectionTitle: 'hlSectionTitle',
  highlight: 'highlight',
  addHlBtn: 'addHlBtn',
  salesBannerText: 'salesBannerText',
  aboutTitle: 'aboutTitle',
  aboutDescription: 'aboutDescription',
  aboutBtnText: 'aboutBtnText',
  testimonial: 'testimonial',
  faq: 'faq',
  addFaqBtn: 'addFaqBtn',
}

export const inputRefsSortMethod = [
  inputNames.heroTitle,
  inputNames.heroSubtitle,
  inputNames.heroBtnText,
  inputNames.hlSectionTitle,
  inputNames.highlight,
  inputNames.addHlBtn,
  inputNames.salesBannerText,
  inputNames.aboutTitle,
  inputNames.aboutDescription,
  inputNames.aboutBtnText,
  inputNames.testimonial,
  inputNames.faq,
  inputNames.addFaqBtn,
]
