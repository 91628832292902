import tw from 'twin.macro'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'

export default function PreviewInput({
  inputClass,
  inputCss,
  viewClass,
  viewCss,
  value,
  maxLength,
  inputType = 'text',
  onChange,
  onClick,
  cssProp,
  style,
  inputRef,
  placeholder = 'Enter text',
  ...otherProps
}) {
  if (inputType === 'button') {
    return (
      <input
        type='text'
        value={value}
        maxLength={maxLength}
        onChange={(e) => {
          const singleSpacedValue = e.target.value.replace(/ +(?= )/g, '') // make sure value contains only single spaces
          onChange(e, singleSpacedValue)
        }}
        css={[baseBtnInputClasses, inputCss]}
        ref={inputRef}
        style={style}
        placeholder={placeholder}
        {...otherProps}
      />
    )
  } else {
    return (
      <TextareaAutosize
        value={value}
        maxLength={maxLength}
        className={inputClass}
        css={[baseRegularInputClasses, inputCss]}
        onChange={(e) => {
          const singleSpacedValue = e.target.value.replace(/ +(?= )/g, '') // make sure value contains only single spaces
          onChange(e, singleSpacedValue)
        }}
        ref={inputRef}
        style={style}
        placeholder={placeholder}
        {...otherProps}
      />
    )
  }
}

const baseBtnInputClasses = [
  buttonBase,
  buttonSizes.lg,
  buttonVariants.primary,
  tw`
    text-center text-xl 
    w-40 py-2.5  
    rounded-lg resize-none 
    border-none focus:outline-none 
    focus:border-none focus:ring-2 
    hover:ring-2 hover:ring-gray-100 
    focus:ring-gray-100
  `,
]

const baseRegularInputClasses = tw`
  text-base text-black
  overflow-hidden focus:ring-0 
  border-none w-full 
  placeholder:text-gray-400 bg-transparent 
  hover:bg-gray-100 hover:bg-opacity-70 
  focus:bg-gray-100 focus:bg-opacity-70 
  rounded p-2 resize-none
`
