export const SECTIONS_TYPES = {
  HERO: 'HERO',
  HIGHLIGHTS: 'HIGHLIGHTS',
  BANNER: 'BANNER',
  ABOUT: 'ABOUT',
}

export const SECTIONS = [
  {
    name: 'Hero Section',
    type: SECTIONS_TYPES.HERO,
  },
  {
    name: 'App Highlights',
    type: SECTIONS_TYPES.HIGHLIGHTS,
  },
  {
    name: 'Middle Banner',
    type: SECTIONS_TYPES.BANNER,
  },
  {
    name: 'About Me',
    type: SECTIONS_TYPES.ABOUT,
  },
]

export const SALES_FIELDS_MAXLENGTH = {
  hero: {
    header: 30,
    subheader: 60,
    heroCTA: 10,
  },
  programSummary: {
    header: 60,
    highlights: { header: 60, description: 120 },
  },
  banner: {
    text: 80,
  },
  about: {
    header: 60,
    body: 1550,
    aboutCTA: 10,
  },
}
