import 'twin.macro'
import React from 'react'

import { Button } from 'common/components/Button/Button'

export function EmptyStateContainer({
  text = 'No items',
  action,
  actionText,
  containerClasses,
  containerStyles,
  textCss,
  loading,
}) {
  return (
    <div
      className={`flex flex-col items-center justify-center h-96 bg-white px-6 py-4 rounded-xl ${
        containerClasses ? containerClasses : ''
      }`}
      style={containerStyles}
    >
      <h3 className='font-bold text-3xl text-tBlack' css={textCss}>
        {text}
      </h3>
      {action && actionText && (
        <div className='mt-4'>
          <Button size='md' onClick={() => action()} loading={loading}>
            {actionText}
          </Button>
        </div>
      )}
    </div>
  )
}
