import tw from 'twin.macro'
import React, { useEffect, useRef, useState } from 'react'

import { useListenStripeProductResponseQuery, useSetStripeRequestMutation } from 'modules/ProductInfo/productInfoApi'

import { Input } from 'common/components/Input/Input'
import { Button } from 'common/components/Button/Button'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'

import { createUID } from 'common/utils/createUID'
import { useAlert } from 'common/components/Alert/hooks/useAlert'

export function GenerateStripeProduct({ coachOrgId }) {
  const { createAlert } = useAlert()

  const [productName, setProductName] = useState('')
  const [requestId, setRequestId] = useState(null)

  const [setStripeRequest] = useSetStripeRequestMutation()

  const { data: stripeResponse } = useListenStripeProductResponseQuery({ coachOrgId })
  const stripeResponsePending = stripeResponse?.requestKey === requestId && stripeResponse?.status === 'pending'
  const stripeResponseSuccess = stripeResponse?.requestKey === requestId && stripeResponse?.status === 'success'
  const stripeResponseError = stripeResponse?.requestKey === requestId && stripeResponse?.status === 'error'

  const isUpdating = requestId || stripeResponsePending

  const handleCreate = async () => {
    const uid = createUID()
    setRequestId(uid)
    await setStripeRequest({
      requestId: uid,
      coachOrgId,
      type: 'CREATE_PRODUCT',
      payload: {
        name: productName,
      },
    })
  }

  useEffect(() => {
    if (stripeResponseError) {
      setRequestId(null)
      const errorMsg = stripeResponse?.errorMsg || 'Product creation failed!'
      createAlert({ text: errorMsg, type: 'danger' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeResponseError])

  useEffect(() => {
    if (stripeResponseSuccess) {
      setRequestId(null)
      createAlert({ text: 'Product created!', type: 'success' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeResponseSuccess])

  const { addInputRef, moveFocusOnKeyPress } = useFormRefsControl()
  const productNameRef = useRef()
  const createBtnRef = useRef()
  const inputRefsSortMethod = ['productName', 'createBtn']
  useEffect(() => {
    addInputRef({ ref: productNameRef, name: 'productName', sortMethod: inputRefsSortMethod })
    addInputRef({
      ref: createBtnRef,
      name: 'createBtn',
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', moveFocusOnKeyPress)

  return (
    <div>
      <h2 className='font-bold text-xl mb-2'>Create New Product</h2>
      <p className='text-gray-500 mb-4'>
        Before you begin creating pricing plans, we want you to add your product name. This name will be visible on your
        Stripe checkout page. You will be able to edit this name afterwards.
      </p>
      <Input
        inputRef={productNameRef}
        name='productName'
        placeholder='Enter your product name'
        css={tw`mb-6`}
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        autoFocus={true}
      />
      <Button
        ref={createBtnRef}
        onClick={handleCreate}
        disabled={productName.trim().length === 0}
        loading={isUpdating}
        css={tw`ml-auto`}
      >
        Create
      </Button>
    </div>
  )
}
