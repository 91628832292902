import { createUID } from 'common/utils/createUID'
import { getSampleExercises } from '../utils/sampleExerciseUtils'
import { getSampleProgram, getSampleProgramWkts } from '../utils/programUtils'

import sampleProgramData1 from './newOrgProgram1.json'
import sampleProgramData2 from './newOrgProgram2.json'
import sampleProgramWktsData1 from './newOrgWorkouts1.json'
import sampleProgramWktsData2 from './newOrgWorkouts2.json'

const convertToObjWithUIDKeys = (itemsArr) => {
  let itemsObj = {}

  itemsArr.forEach((item) => {
    itemsObj[item.id] = item
  })

  return itemsObj
}

/************
 user
************/

export const initCoach = (coachOrgId, profile) => ({
  metadata: {
    coachOrgId,
    sessionUX: 'gym_workout',
  },
  paidAccess: {
    coachOrgs: {
      [coachOrgId]: {
        accessEnd: 166146639200000,
      },
    },
  },
  profile: {
    ...profile,
    coachOrgId,
    createdAt: Date.now(),
  },
})

/************
 exercises
 ************/

const { sampleExercises: initExercises, newSampleExIdByOrigExId } = getSampleExercises()

/************
 progressions
 ************/

const sampleExIds = Object.keys(initExercises)
const initExProgressions = {
  'sample progression': [sampleExIds[0], sampleExIds[1]],
}

/************
 linkhub
************/

const initLinkHub = {
  description: 'Your message',
  resources: [
    {
      id: createUID(),
      title: 'Sample merch link',
      description: 'Purchase merch and other items',
      link: 'https://www.linkyourmerchstore.com',
    },
    {
      id: createUID(),
      title: 'Sample youtube link',
      description: 'Find my content',
      link: 'https://www.youtube.com',
    },
  ],
}

/************
 workouts
************/

const { sampleWorkouts: programWkts1, newWktIdsToDayIdcs: wktIdsToDayIdcsProgram1 } = getSampleProgramWkts(
  sampleProgramWktsData1,
  newSampleExIdByOrigExId
)
const { sampleWorkouts: programWkts2, newWktIdsToDayIdcs: wktIdsToDayIdcsProgram2 } = getSampleProgramWkts(
  sampleProgramWktsData2,
  newSampleExIdByOrigExId
)
export const initProgramWkts = { ...programWkts1, ...programWkts2 }

/************
 program
************/

export const sampleProgram1 = getSampleProgram(sampleProgramData1, wktIdsToDayIdcsProgram1, programWkts1)
export const sampleProgram2 = getSampleProgram(sampleProgramData2, wktIdsToDayIdcsProgram2, programWkts2)
const initCoachPrograms = {
  [sampleProgram1.id]: sampleProgram1.name,
  [sampleProgram2.id]: sampleProgram2.name,
}

/************
 programgroups
************/

const programGroups = [
  {
    color: '#57699b',
    description: 'Sample description',
    id: createUID(),
    name: 'Sample series',
    previewImg: 'https://d3ts90okmyjsrp.cloudfront.net/samples/thumbnails/JumpRope-NCXpLBgmYmMIzIKFcFd_img.0000000.jpg',
    programs: [sampleProgram1.id, sampleProgram2.id],
    subtitle: 'Sample subtitle',
    createdAt: Date.now(),
    updatedAt: Date.now(),
  },
]
const initProgramGroups = convertToObjWithUIDKeys(programGroups)

/************
 explore
************/

const generateExploreData = () => {
  const tabUID = createUID()

  const exploreData = {
    tabContent: {
      [tabUID]: [
        {
          id: createUID(),
          items: [
            {
              id: programGroups[0].id,
              type: 'programGroup',
            },
            {
              id: sampleProgram1.id,
              type: 'program',
            },
          ],
          name: 'Sample published row',
          visibilityLevel: 'anyone',
        },
      ],
    },
    tabs: {
      [tabUID]: {
        name: 'Explore',
        tabIdx: 0,
        visibilityLevel: 'anyone',
      },
    },
  }

  return exploreData
}

/************
 coachOrg
************/

export const getInitCoachOrg = (coachOrgId, exVidOrientation) => {
  let initCoachOrg = {
    coachOrgId,
    sessionUX: 'gym_workout',
    mobilePreMountSettings: {
      visibleLayout: {
        exerciseLibrary: false,
        linkHub: true,
        upgradePrompt: true,
        workoutsScrollMax: 6,
      },
      contentFilters: {
        difficulty: false,
        equipment: false,
        instructors: false,
        tags: false,
      },
      exVidOrientation,
    },
    exSubs: initExProgressions,
    exercises: initExercises,
    linkHub: initLinkHub,
    programGroups: initProgramGroups,
    explore: generateExploreData(),
    programs: initCoachPrograms,
    productInfo: {
      accessType: 'coach_org_all_access',
      stripeCustomerOwner: 'connected_account',
      trybeRevSharePercent: 20,
    },
    dashboardOnboarding: {
      exercises: {
        hasReadVidGuidelines: false,
      },
    },
  }

  if (exVidOrientation === 'landscape') {
    initCoachOrg.mobilePreMountSettings.enableLandscapeExAudio = true
  }

  return initCoachOrg
}

/************
 pagebuilder
************/

export const getInitSalesPage = ({ primaryColor }) => ({
  heroSection: {
    heroCTA: 'Join',
    mainText: 'Heading',
    secondaryText: 'Subheading',
  },
  programSummary: {
    header: 'Highlights',
    highlights: [
      {
        header: 'Highlight #1',
        description: 'Description',
      },
    ],
  },
  banner: {
    bannerText: 'Banner',
  },
  aboutMe: {
    header: 'About me',
    body: 'Text about me',
    body_2: '',
    aboutMeCTA: 'Join now',
  },
  images: {},
  primaryColor: primaryColor || '#000',
})

export const getInitCheckoutPage = ({ coachOrgName }) => ({
  benefits: ['Benefit #1'],
  heroSection: {
    mainText: coachOrgName || 'Heading',
  },
})
