export const SECTIONS_TYPES = {
  HERO: 'HERO',
  BENEFITS: 'BENEFITS',
}

export const SECTIONS = [
  {
    name: 'Hero Section',
    type: SECTIONS_TYPES.HERO,
  },
  {
    name: 'What You Get',
    type: SECTIONS_TYPES.BENEFITS,
  },
]

export const CHECKOUT_FIELDS_MAXLENGTH = {
  appTitle: 40,
  benefits: 50,
}
