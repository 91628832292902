import React from 'react'
import tw from 'twin.macro'

import { TestimonialList } from '../TestimonialList/TestimonialList'

export default function TestimonialPreview({ coachOrgId, testimonials, isMob }) {
  return (
    <div className='bg-white'>
      <div className='flex flex-col py-5 w-full max-w-[950px] mx-auto' css={[!isMob && tw`pb-20 pt-0`]}>
        <h3 css={[tw`text-4xl font-bold mb-6 text-center`, isMob && tw`text-2xl`]}>Testimonials</h3>
        <TestimonialList coachOrgId={coachOrgId} testimonials={testimonials || []} isMob={isMob} />
      </div>
    </div>
  )
}
