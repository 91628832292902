import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenCoachOrgSlugQuery,
  useListenDraftPrimaryColorQuery,
  useListenSalesImagesQuery,
  useListenSalesPageQuery,
  useListenSalesPageSeoQuery,
  usePublishSalesPageMutation,
} from '../pageBuilderApi'

import Loading from 'modules/App/components/Loading'
import TopBar from '../components/TopBar/TopBar'
import SalesPagePreview from '../components/PagePreview/SalesPagePreview'
import { useAlert } from 'common/components/Alert/hooks/useAlert'

export default function SalesPageBuilder() {
  const navigate = useNavigate()
  const { userId } = useAuth()
  const { createAlert } = useAlert()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [isPublishing, setIsPublishing] = useState(false)

  const { data: salesPage } = useListenSalesPageQuery({ coachOrgId })
  const pageIsLoading = salesPage === undefined || salesPage?.isLoading
  const { data: primaryColorData } = useListenDraftPrimaryColorQuery({ coachOrgId })
  const primaryColorLoading = primaryColorData === undefined || primaryColorData?.isLoading
  const { data: seoData } = useListenSalesPageSeoQuery({ coachOrgId })
  const seoLoading = seoData === undefined || seoData?.isLoading
  const { data: coachOrgSlug } = useListenCoachOrgSlugQuery({ coachOrgId })
  const coachOrgSlugLoading = coachOrgSlug === undefined || coachOrgSlug?.isLoading
  const { data: salesImageData } = useListenSalesImagesQuery({ coachOrgId })
  const imagesLoading = salesImageData === undefined || salesImageData?.isLoading

  const [publishPage] = usePublishSalesPageMutation()

  const handlePublishPage = async () => {
    setIsPublishing(true)
    await publishPage({ coachOrgId })
    setIsPublishing(false)
    createAlert({ text: 'Sales page is published!', type: 'success' })
  }

  useEffect(() => {
    if (!coachOrgSlugLoading && !coachOrgSlug) {
      navigate('/pagebuilder/handle')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachOrgSlugLoading, coachOrgSlug])

  if (pageIsLoading || primaryColorLoading || coachOrgSlugLoading || seoLoading || imagesLoading) {
    return <Loading showHeader={false} />
  }

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-16'>
      <div className='w-full max-w-7xl mx-auto mt-12 mb-20'>
        <TopBar handlePublishPage={handlePublishPage} pageTitle='Sales Page' isPublishing={isPublishing} />
        <div className='flex flex-1 flex-col items-center mt-20 relative'>
          <FormRefsControlProvider>
            <SalesPagePreview coachOrgId={coachOrgId} page={salesPage} seo={seoData} />
          </FormRefsControlProvider>
        </div>
      </div>
    </div>
  )
}
