import React, { useEffect, useRef } from 'react'
import { CgAdd, CgTrash } from 'react-icons/cg'
import { useForm, FormProvider } from 'react-hook-form'
import tw from 'twin.macro'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import {
  useListenTestimonialImageQuery,
  useUpdateSalesTestimonialSectionMutation,
} from 'modules/PageBuilder/pageBuilderApi'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { Button } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'
import PreviewInput from '../Inputs/PreviewInput'
import ImageUpload from '../Inputs/ImageUpload'
import { createUID } from 'common/utils/createUID'
import { inputNames, inputRefsSortMethod } from '../../constants/salesInputConstants'

const TESTIMONIAL_LIMIT = 10
const DRAFT_IMG_ID = 'testimonial-image'
const IMG_ASPECT_RATIO = '1/1'
const W_TO_H_RATIO = 1 / 1

export function TestimonialList({ coachOrgId, testimonials, isMob }) {
  const [updateFaqs] = useUpdateSalesTestimonialSectionMutation()

  const defaultValues = { testimonials }

  const methods = useForm({
    defaultValues,
  })

  const { watch, setValue, handleSubmit } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    await updateFaqs({ coachOrgId, testimonials: data.testimonials })
  }

  const handleDeleteTestimonial = (testimonialId) => {
    const updatedTestimonials = formState.testimonials.filter((testimonial) => testimonial.id !== testimonialId)
    setValue('testimonials', updatedTestimonials)
  }

  useEventListener('keydown', (e) => {
    if (e.code === 'Tab') e.preventDefault()
  })

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit))
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch])

  const addTestimonialBtnRef = useRef()
  const { addInputRef, removeInputRef } = useFormRefsControl()
  useEffect(() => {
    removeInputRef(addTestimonialBtnRef, inputNames.testimonial)

    addInputRef({
      ref: addTestimonialBtnRef,
      name: inputNames.testimonial,
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonials.length])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='testimonialForm'
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.code === 'Tab') {
            e.preventDefault() //Otherwise form autosubmits on each enter press
          }
        }}
      >
        <ScrollArea css={[tw`max-h-full`, isMob && tw`px-4`]}>
          <div className='flex mb-6'>
            {formState.testimonials.map((testimonial, index) => (
              <Testimonial
                index={index}
                isLast={testimonials.length - 1 === index}
                key={testimonial?.id || index}
                testimonial={testimonial}
                handleDelete={handleDeleteTestimonial}
                setValue={setValue}
                isMob={isMob}
                coachOrgId={coachOrgId}
              />
            ))}
          </div>
        </ScrollArea>
        {testimonials?.length < TESTIMONIAL_LIMIT && (
          <div css={[tw`w-full mt-3 px-4`]}>
            <Tooltip content='Add testimonial'>
              <Button
                ref={addTestimonialBtnRef}
                type='button'
                variant='secondary'
                size='md'
                css={tw`max-w-sm w-full mx-auto`}
                onClick={() =>
                  setValue(`testimonials.${testimonials.length}`, {
                    text: `Testimonial #${testimonials.length + 1}`,
                    author: 'Author',
                    id: createUID(),
                  })
                }
              >
                <CgAdd className='w-5 h-5 mr-2 max-w' /> Add testimonial
              </Button>
            </Tooltip>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

function Testimonial({ index, isLast, testimonial, handleDelete, setValue, isMob, coachOrgId }) {
  const { addInputRef, removeInputRef } = useFormRefsControl()
  const testimonialTextRef = useRef()
  const testimonialAuthorRef = useRef()

  const imgUploadId = `${DRAFT_IMG_ID}-${testimonial.id}`
  const { data: isImgUploading } = useListenExistingItemDraftsQuery({ coachOrgId, id: imgUploadId })
  const { data: testimonialImg } = useListenTestimonialImageQuery({ coachOrgId, id: testimonial.id })

  useEffect(() => {
    removeInputRef(testimonialTextRef, inputNames.testimonial)
    removeInputRef(testimonialAuthorRef, inputNames.testimonial)

    addInputRef({
      ref: testimonialTextRef,
      name: inputNames.testimonial,
      sortMethod: inputRefsSortMethod,
    })
    addInputRef({
      ref: testimonialAuthorRef,
      name: inputNames.testimonial,
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <div
      className='relative flex group rounded-xl mx-4 overflow-hidden border'
      css={[isMob && tw`flex-col mx-2`, index === 0 && tw`ml-0`, isLast && tw`mr-0`]}
    >
      <div className='absolute top-2 right-2 opacity-0 group-hover:opacity-100 delay-100 hover:opacity-100'>
        <Dialog>
          <Tooltip content='Remove'>
            <DialogTrigger
              className='flex items-center justify-center bg-offWhite hover:bg-offWhite-dark p-1.5 rounded-md'
              aria-label='Remove testimonial'
            >
              <CgTrash className='w-5 h-5' />
            </DialogTrigger>
          </Tooltip>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will delete testimonial #${index + 1}`}
              handleDelete={() => handleDelete(testimonial.id)}
            />
          </DialogContent>
        </Dialog>
      </div>
      <div className='group w-[360px] h-[360px]' css={[isMob && tw`w-[280px] h-[280px]`]}>
        <div
          className='relative w-full h-full rounded-none mx-auto bg-cover bg-no-repeat bg-gray-200'
          css={[testimonialImg && tw`bg-transparent`]}
          style={{
            backgroundImage: `url(${testimonialImg})`,
          }}
        >
          <div
            className='hidden group-hover:flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
            css={(isImgUploading || !testimonialImg) && tw`flex`}
          >
            <ImageUpload
              coachOrgId={coachOrgId}
              id={imgUploadId}
              uploadType='testimonial-image'
              aspectRatio={IMG_ASPECT_RATIO}
              wToHRatio={W_TO_H_RATIO}
            />
          </div>
        </div>
      </div>
      <div
        className='p-8 w-[360px] h-[360px] flex flex-col justify-between'
        css={[isMob && tw`w-[280px] h-[240px] p-2`]}
      >
        <PreviewInput
          inputRef={testimonialTextRef}
          name={inputNames.testimonial}
          inputCss={[isMob ? tw`text-sm` : tw`text-base`, tw`text-gray-500 mb-1 mt-3`]}
          value={testimonial?.text}
          onChange={(_, value) => setValue(`testimonials.${index}.text`, value)}
          maxLength={250}
        />
        <PreviewInput
          inputRef={testimonialAuthorRef}
          name={inputNames.testimonial}
          inputCss={tw`text-base font-bold capitalize`}
          value={testimonial?.author}
          onChange={(_, value) => setValue(`testimonials.${index}.author`, value)}
          maxLength={30}
        />
      </div>
    </div>
  )
}
