import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { sortBy } from 'lodash'

import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenCheckoutPageQuery,
  useListenCheckoutPageSeoQuery,
  useListenCoachOrgSlugQuery,
  usePublishCheckoutPageMutation,
} from '../pageBuilderApi'
import { useListenCouponsQuery, useListenProductPricingQuery } from 'modules/ProductInfo/productInfoApi'

import Loading from 'modules/App/components/Loading'
import CheckoutPagePreview from '../components/PagePreview/CheckoutPagePreview'
import TopBar from '../components/TopBar/TopBar'

export default function CheckoutPageBuilder() {
  const navigate = useNavigate()
  const { createAlert } = useAlert()
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const [isPublishing, setIsPublishing] = useState(false)

  const { data: checkoutPage } = useListenCheckoutPageQuery({ coachOrgId })
  const pageIsLoading = checkoutPage === undefined || checkoutPage?.isLoading
  const { data: plansData } = useListenProductPricingQuery({ coachOrgId })
  const productPlansLoading = plansData === undefined || plansData?.isLoading
  const productPlans = productPlansLoading || !plansData ? {} : plansData
  const sortedProductPlans = sortBy(productPlans, (plan) => plan.idx).filter(
    (plan) => Boolean(plan?.stripe_price_id) || Boolean(plan?.stripe_plan_id)
  )
  const { data: seoData } = useListenCheckoutPageSeoQuery({ coachOrgId })
  const seoLoading = seoData === undefined || seoData?.isLoading
  const { data: coachOrgSlug } = useListenCoachOrgSlugQuery({ coachOrgId })
  const coachOrgSlugLoading = coachOrgSlug === undefined || coachOrgSlug?.isLoading
  const { data: coupons } = useListenCouponsQuery({ coachOrgId })
  const couponsLoading = coupons === undefined || coupons?.isLoading

  const [publishPage] = usePublishCheckoutPageMutation()

  const handlePublishPage = async () => {
    setIsPublishing(true)
    await publishPage({ coachOrgId })
    setIsPublishing(false)
    createAlert({ text: 'Checkout page is published!', type: 'success' })
  }

  useEffect(() => {
    if (!coachOrgSlugLoading && !coachOrgSlug) {
      navigate('/pagebuilder/handle')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachOrgSlugLoading, coachOrgSlug])

  if (pageIsLoading || productPlansLoading || seoLoading || couponsLoading) {
    return <Loading showHeader={false} />
  }

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-16'>
      <div className='w-full max-w-7xl mx-auto mt-12 mb-20'>
        <TopBar handlePublishPage={handlePublishPage} pageTitle='Checkout Page' isPublishing={isPublishing} />
        <div className='flex flex-1 flex-col items-center mt-20 relative'>
          <FormRefsControlProvider>
            <CheckoutPagePreview
              coachOrgId={coachOrgId}
              page={checkoutPage}
              productPlans={sortedProductPlans}
              seo={seoData}
              coupons={coupons}
            />
          </FormRefsControlProvider>
        </div>
      </div>
    </div>
  )
}
