import React, { Fragment } from 'react'
import * as Portal from '@radix-ui/react-portal'
import { Combobox } from '@headlessui/react'
import { startCase } from 'lodash'

import {
  calcPriceWithDiscount,
  getCouponSuggestionDurationText,
  getIsValidPlanDiscount,
  showDiscountByType,
} from 'modules/ProductInfo/utils/utils'
import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import {
  MIN_LIFETIME_PLAN_DISCOUNTED_PRICE,
  MIN_REPEATING_COUPON_DISCOUNTED_PRICE,
} from '../../constants/priceConstants'

export default function CouponsSuggestions({ coupons, searchValue, plan, inputRef, popperContainer }) {
  const suggestions = Object.entries(coupons).filter(
    ([_, coupon]) =>
      coupon?.stripeStatus !== 'pending' &&
      coupon?.stripeStatus !== 'error' &&
      coupon.name.toLowerCase().includes(searchValue.toLowerCase()) &&
      !plan.promo?.find((promo) => promo.stripeCouponId === coupon.id) &&
      getIsValidPlanDiscount({ plan, coupon })
  )

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width

  return (
    <Portal.Root ref={popperContainer}>
      <Combobox.Options as={Fragment}>
        <SuggestionContainer containerStyle={{ width: inputWidth }} scrollAreaClasses='!max-h-52'>
          {!coupons || !suggestions.length ? (
            <li className='cursor-default select-none py-2 px-2'>No coupons found</li>
          ) : (
            suggestions.map(([couponKey, coupon], index) => (
              <Combobox.Option as={Fragment} key={couponKey} value={couponKey}>
                {({ active }) => (
                  <SuggestionItem active={active}>
                    <div className='flex'>
                      <div className='flex flex-col flex-1 px-2.5'>
                        <p className='text-sm font-bold mb-1'>{coupon.name || 'Untitled coupon'}</p>
                        <span className='text-xs font-medium text-gray-500'>
                          {`ID: ${coupon.id}` || '(couponid-autogenerated)'}
                        </span>
                      </div>
                      <div className='flex flex-col items-center px-2 min-w-[90px]'>
                        <p className='text-sm font-bold mb-1'>Type</p>
                        <span className='text-xs font-medium text-gray-500'>{startCase(coupon.type)}</span>
                      </div>
                      <div className='flex flex-col items-center px-2 min-w-[90px]'>
                        <p className='text-sm font-bold mb-1'>Duration</p>
                        <span className='text-xs font-medium text-gray-500'>
                          {getCouponSuggestionDurationText({ coupon })}
                        </span>
                      </div>
                      <div className='flex flex-col items-center px-2 min-w-[90px]'>
                        <p className='text-sm font-bold mb-1'>Discount</p>
                        <span className='text-xs font-medium text-gray-500'>
                          {showDiscountByType({ type: coupon.type, discount: coupon.discount })}
                        </span>
                      </div>
                      <div className='flex flex-col items-center px-2 min-w-[90px]'>
                        <p className='text-sm font-bold mb-1'>Result price</p>
                        <span className='bg-tGreen text-xs font-medium text-white rounded-md py-0.5 px-1'>
                          $
                          {(
                            calcPriceWithDiscount({ type: coupon.type, discount: coupon.discount, price: plan.price }) /
                            100
                          ).toFixed(2)}{' '}
                          USD
                        </span>
                      </div>
                    </div>
                  </SuggestionItem>
                )}
              </Combobox.Option>
            ))
          )}
          {/* extra space to account for absolutely positioned hint */}
          <div className='h-4' />
          <div className='absolute bottom-0 inset-x-0 px-2 py-0.5 bg-white text-xs text-gray-500'>
            {plan?.isLifetimePurchase
              ? `Coupons that are trial only or result in a price lower than ${(
                  MIN_LIFETIME_PLAN_DISCOUNTED_PRICE / 100
                ).toFixed(2)}$ are not
            allowed for lifetime plans.`
              : `Forever or repeating duration coupons that result in a price lower than ${(
                  MIN_REPEATING_COUPON_DISCOUNTED_PRICE / 100
                ).toFixed(2)}$ are not
            allowed.`}
          </div>
        </SuggestionContainer>
      </Combobox.Options>
    </Portal.Root>
  )
}
