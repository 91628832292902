import { Combobox } from '@headlessui/react'
import * as Portal from '@radix-ui/react-portal'
import { usePopper } from 'common/hooks/usePopper'
import { inputBase } from 'common/components/Input/Input'
import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import React, { Fragment, useRef } from 'react'
import { CgChevronDown } from 'react-icons/cg'
import tw from 'twin.macro'
import { InputError } from './PricingForm'

const options = [
  { title: 'Monthly', value: 'month-1' },
  { title: 'Every 6 months', value: 'month-6' },
  { title: 'Yearly', value: 'year-1' },
  { title: 'Lifetime', value: 'lifetime-1' },
]

export default function BillTypeInput({ inputRef, value, setValue, usedBillingTypes, error, setError }) {
  const comboboxBtnRef = useRef()

  const handlePickBillType = (newValue) => {
    if (newValue === options[0].value) {
      setValue('isLifetimePurchase', null)
      setValue('time_unit', 'month')
      setValue('time_val', 1)
    } else if (newValue === options[1].value) {
      setValue('isLifetimePurchase', null)
      setValue('time_unit', 'month')
      setValue('time_val', 6)
    } else if (newValue === options[2].value) {
      setValue('isLifetimePurchase', null)
      setValue('time_unit', 'year')
      setValue('time_val', 1)
    } else if (newValue === options[3].value) {
      setValue('isLifetimePurchase', true)
      setValue('time_unit', 'lifetime')
      setValue('time_val', 1)
    }

    const typeIsUsed = usedBillingTypes.includes(newValue)
    if (typeIsUsed) {
      setError('Plan with this billing period already exists. Please delete or edit conflicting plan.')
    } else {
      setError(null)
    }
  }

  const handleInputKeyDown = (e) => {
    const acceptedCodes = ['Enter', 'Tab', 'ArrowUp', 'ArrowDown']
    if (!acceptedCodes.includes(e.code)) {
      e.preventDefault()
    } else if (e.code === 'Enter' && !e.shiftKey) {
      comboboxBtnRef.current.click()
    }
  }

  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width

  return (
    <Combobox value={value} onChange={handlePickBillType}>
      <div ref={popperTrigger} className='w-full'>
        <Combobox.Button ref={comboboxBtnRef} as='div' className='relative w-full'>
          <Combobox.Input
            ref={(e) => {
              inputRef.current = e
            }}
            placeholder='Enter billing type'
            autoComplete='off'
            displayValue={(value) => options.find((opt) => opt.value === value)?.title || 'Choose billing period'}
            onKeyDown={handleInputKeyDown}
            className='text-ellipsis bg-inherit cursor-pointer'
            css={[inputBase, error && tw`ring-tRed focus:ring-tRed`, tw`caret-transparent`]}
          />
          <CgChevronDown className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 w-4 h-4' />
        </Combobox.Button>
      </div>
      {error && <InputError>{error}</InputError>}
      <Portal.Root>
        <Combobox.Options className='z-[999]' ref={popperContainer} modal={false}>
          <SuggestionContainer containerStyle={{ width: inputWidth }}>
            {options.map((opt) => (
              <Combobox.Option as={Fragment} key={opt.value} value={opt.value}>
                {({ selected, active }) => (
                  <SuggestionItem active={active}>
                    <div className='flex items-center'>
                      {opt.title}
                      {usedBillingTypes.includes(opt.value) && (
                        <span className='py-1 px-2 ml-4 text-xxs bg-gray-500 text-white rounded-md whitespace-nowrap'>
                          in use
                        </span>
                      )}
                    </div>
                  </SuggestionItem>
                )}
              </Combobox.Option>
            ))}
          </SuggestionContainer>
        </Combobox.Options>
      </Portal.Root>
    </Combobox>
  )
}
