import { getStandardizedName } from 'common/utils/stringUtils'
import * as yup from 'yup'

const trimString = (_, originalValue) => String(originalValue).trim()

export const checkCouponIdExists = (couponId, { options, createError }) => {
  const existingCouponIds = options.context.existingCouponIds || []
  const couponIdExists = existingCouponIds.find((id) => getStandardizedName(id) === getStandardizedName(couponId))

  if (couponIdExists) {
    return createError({
      message: 'Coupon id already exists',
    })
  }

  return true
}

export const editCouponFormSchema = yup
  .object({
    name: yup.string().required('Coupon name is required').transform(trimString),
    alwaysActive: yup.boolean(),
    startDate: yup.date().when('alwaysActive', {
      is: false,
      then: (schema) => schema.required('Start date is required when always active is disabled'),
      otherwise: (schema) => schema.nullable(),
    }),
    expiration: yup.date().when('alwaysActive', {
      is: false,
      then: (schema) => schema.required('End date is required when always active is disabled'),
      otherwise: (schema) => schema.nullable(),
    }),
  })
  .required()

export const addCouponFormSchema = yup
  .object({
    id: yup
      .string()
      .matches(
        /(^$)|(^[0-9a-zA-Z]+$)/, // allow only alphanumerical and empty string
        {
          message: 'Please use only numbers and letters',
        }
      )
      .test('id-exists', checkCouponIdExists)
      .transform(trimString),
    name: yup.string().required('Coupon name is required').transform(trimString),
    alwaysActive: yup.boolean(),
    startDate: yup.date().when('alwaysActive', {
      is: false,
      then: (schema) => schema.required('Start date is required when always active is disabled'),
      otherwise: (schema) => schema.nullable(),
    }),
    expiration: yup.date().when('alwaysActive', {
      is: false,
      then: (schema) => schema.required('End date is required when always active is disabled'),
      otherwise: (schema) => schema.nullable(),
    }),
  })
  .required()
