import tw from 'twin.macro'
import React, { Fragment, useRef } from 'react'
import { CgChevronDown } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'
import * as Portal from '@radix-ui/react-portal'

import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import { inputBase } from 'common/components/Input/Input'
import { usePopper } from 'common/hooks/usePopper'

const availableDurationValues = [
  {
    value: 'once',
    displayValue: 'Once',
  },
  { value: 'repeating', displayValue: 'Multiple months' },
  { value: 'forever', displayValue: 'Forever' },
]

export default function DurationInput({ inputRef, value, setValue }) {
  const comboboxBtnRef = useRef()

  const handlePickDuration = (newValue) => {
    if (newValue === 'once') {
      setValue('duration', 'once')
      setValue('durationInMonths', null)
    } else if (newValue === 'repeating') {
      setValue('duration', 'repeating')
      setValue('durationInMonths', 1)
    } else if (newValue === 'forever') {
      setValue('duration', 'forever')
      setValue('durationInMonths', null)
    }
  }

  const handleInputKeyDown = (e) => {
    const acceptedCodes = ['Enter', 'Tab', 'ArrowUp', 'ArrowDown']
    if (!acceptedCodes.includes(e.code)) {
      e.preventDefault()
    } else if (e.code === 'Enter' && !e.shiftKey) {
      comboboxBtnRef.current.click()
    }
  }

  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width

  return (
    <Combobox value={value} onChange={handlePickDuration}>
      <div ref={popperTrigger} className='w-full'>
        <Combobox.Button ref={comboboxBtnRef} as='div' className='relative w-full'>
          <Combobox.Input
            ref={(e) => {
              inputRef.current = e
            }}
            placeholder='Enter duration'
            autoComplete='off'
            displayValue={(value) =>
              availableDurationValues.find((duration) => duration.value === value)?.displayValue || 'Choose duration'
            }
            onKeyDown={handleInputKeyDown}
            className='text-ellipsis bg-inherit cursor-pointer'
            css={[inputBase, tw`caret-transparent`]}
          />
          <CgChevronDown className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 w-4 h-4' />
        </Combobox.Button>
      </div>
      <Portal.Root>
        <Combobox.Options className='z-[999]' ref={popperContainer} modal={false}>
          <SuggestionContainer containerStyle={{ width: inputWidth }}>
            {availableDurationValues.map((duration) => (
              <Combobox.Option as={Fragment} key={duration.value} value={duration.value}>
                {({ selected, active }) => (
                  <SuggestionItem active={active}>
                    <div className='flex items-center'>{duration.displayValue}</div>
                  </SuggestionItem>
                )}
              </Combobox.Option>
            ))}
          </SuggestionContainer>
        </Combobox.Options>
      </Portal.Root>
    </Combobox>
  )
}
