import { getFirebase, globalApi, listenFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'
import { getInitCheckoutPage, getInitSalesPage } from 'modules/NewCoachOrgQuestionnaire/templates/initDataTemplates'

export const pageBuilderApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenSalesPage: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        const path = `drafts/${coachOrgId}/salesPage`
        listenFirebase(path, api, async (page) => {
          if (!page && coachOrgId) {
            const { data: primaryColor } = await getFirebase(
              `coachOrgs/${coachOrgId}/styles/colorScheme/primaryUIColor`
            )
            const draftPage = getInitSalesPage({ primaryColor })
            await setFirebase(path, draftPage)
            api.updateCachedData(() => draftPage)
          } else {
            api.updateCachedData(() => page)
          }
        })
      },
    }),
    listenCheckoutPage: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        const path = `drafts/${coachOrgId}/siteCheckout`
        listenFirebase(path, api, async (page) => {
          if (!page && coachOrgId) {
            const { data: coachOrgName } = await getFirebase(`coachOrgs/${coachOrgId}/productInfo/productName`)
            const draftPage = getInitCheckoutPage({ coachOrgName })
            await setFirebase(path, draftPage)
            api.updateCachedData(() => draftPage)
          } else {
            api.updateCachedData(() => page)
          }
        })
      },
    }),
    listenDraftPrimaryColor: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/salesPage/primaryColor`, api)
      },
    }),
    listenAllOrgSlugs: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async (_, api) => {
        listenFirebase(`internal/coachOrgSlugs`, api)
      },
    }),
    listenCoachOrgSlug: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`internal/coachOrgSlugs/${coachOrgId}`, api)
      },
    }),
    setCoachOrgSlug: build.mutation({
      queryFn: async ({ coachOrgId, slug }) => {
        const { data, error } = await setFirebase(`internal/coachOrgSlugs/${coachOrgId}`, slug)
        return { data, error }
      },
    }),
    setDraftPrimaryColor: build.mutation({
      queryFn: async ({ coachOrgId, color }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/salesPage/primaryColor`, color)
        return { data, error }
      },
    }),
    publishSalesPage: build.mutation({
      queryFn: async ({ coachOrgId }) => {
        const draftPath = `drafts/${coachOrgId}/salesPage`
        const publishPath = `coachOrgs/${coachOrgId}/productInfo/salesPage`

        const { data: salesPageDraft, error: salesPageDraftError } = await getFirebase(draftPath)
        const { data, error: publishSalesPageError } = await updateFirebase(publishPath, {
          ...salesPageDraft,
          testimonials: salesPageDraft?.testimonials || null,
          faqs: salesPageDraft?.faqs || null,
        })
        return { data, error: { salesPageDraftError, publishSalesPageError } }
      },
    }),
    publishCheckoutPage: build.mutation({
      queryFn: async ({ coachOrgId }) => {
        const draftPath = `drafts/${coachOrgId}/siteCheckout`
        const publishPath = `coachOrgs/${coachOrgId}/productInfo/siteCheckout`

        const { data: checkoutPageDraft, error: fetchCheckoutPageDraftError } = await getFirebase(draftPath)
        const { data, publishCheckoutPageError } = await updateFirebase(publishPath, checkoutPageDraft)

        return { data, error: { fetchCheckoutPageDraftError, publishCheckoutPageError } }
      },
    }),
    listenSalesHeroImage: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/salesPage/images/hero`, api)
      },
    }),
    listenSalesImages: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/salesPage/images`, api)
      },
    }),
    listenCheckoutHeroImage: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/siteCheckout/heroImage`, api)
      },
    }),
    listenSalesHighlightsImage: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/salesPage/images/trifold`, api)
      },
    }),
    listenSalesAboutImage: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/salesPage/images/single`, api)
      },
    }),
    updateSalesHeroSection: build.mutation({
      queryFn: async ({ coachOrgId, section }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/salesPage/heroSection`, section)
        return { data, error }
      },
    }),
    updateSalesHeroImage: build.mutation({
      queryFn: async ({ coachOrgId, image }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/salesPage/images/hero`, image)
        return { data, error }
      },
    }),
    updateSalesHeroImgDeskPos: build.mutation({
      queryFn: async ({ coachOrgId, position }) => {
        const { data, error } = await setFirebase(
          `drafts/${coachOrgId}/salesPage/images/heroImgDesktopPosition`,
          position
        )
        return { data, error }
      },
    }),
    updateSalesHeroImgMobPos: build.mutation({
      queryFn: async ({ coachOrgId, position }) => {
        const { data, error } = await setFirebase(
          `drafts/${coachOrgId}/salesPage/images/heroImgMobilePosition`,
          position
        )
        return { data, error }
      },
    }),
    updateSalesHightlightsSection: build.mutation({
      queryFn: async ({ coachOrgId, section }) => {
        const { data, error } = await updateFirebase(`drafts/${coachOrgId}/salesPage/programSummary`, section)
        return { data, error }
      },
    }),
    updateSalesBannerSection: build.mutation({
      queryFn: async ({ coachOrgId, section }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/salesPage/banner`, section)
        return { data, error }
      },
    }),
    updateSalesAboutSection: build.mutation({
      queryFn: async ({ coachOrgId, section }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/salesPage/aboutMe`, section)
        return { data, error }
      },
    }),
    updateCheckoutHero: build.mutation({
      queryFn: async ({ coachOrgId, image }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/siteCheckout/heroImage`, image)
        return { data, error }
      },
    }),
    updateCheckoutBenefits: build.mutation({
      queryFn: async ({ coachOrgId, benefits }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/siteCheckout/benefits`, benefits)
        return { data, error }
      },
    }),
    updateCheckoutHeroMainText: build.mutation({
      queryFn: async ({ coachOrgId, text }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/siteCheckout/heroSection/mainText`, text)
        return { data, error }
      },
    }),
    updateSalesFaqSection: build.mutation({
      queryFn: async ({ coachOrgId, faqs }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/salesPage/faqs`, faqs)
        return { data, error }
      },
    }),
    listenTestimonialImage: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId, id }, api) => {
        listenFirebase(`drafts/${coachOrgId}/salesPage/images/testimonials/${id}`, api)
      },
    }),
    updateSalesTestimonialSection: build.mutation({
      queryFn: async ({ coachOrgId, testimonials }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/salesPage/testimonials`, testimonials)
        return { data, error }
      },
    }),
    listenSalesPageSeo: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/salesPage/seo`, api)
      },
    }),
    updateSalesPageSeo: build.mutation({
      queryFn: async ({ coachOrgId, seo }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/salesPage/seo`, seo)
        return { data, error }
      },
    }),
    listenCheckoutPageSeo: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`drafts/${coachOrgId}/siteCheckout/seo`, api)
      },
    }),
    updateCheckoutPageSeo: build.mutation({
      queryFn: async ({ coachOrgId, seo }) => {
        const { data, error } = await setFirebase(`drafts/${coachOrgId}/siteCheckout/seo`, seo)
        return { data, error }
      },
    }),
    listenSeoImg: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId, uploadLocation }, api) => {
        listenFirebase(`drafts/${coachOrgId}/${uploadLocation}/seo/previewImg`, api)
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenSalesPageQuery,
  useListenCheckoutPageQuery,
  useListenDraftPrimaryColorQuery,
  useListenCoachOrgSlugQuery,
  useListenAllOrgSlugsQuery,
  useSetCoachOrgSlugMutation,
  useSetDraftPrimaryColorMutation,
  usePublishSalesPageMutation,
  usePublishCheckoutPageMutation,
  useListenSalesHeroImageQuery,
  useListenSalesImagesQuery,
  useListenCheckoutHeroImageQuery,
  useListenSalesHighlightsImageQuery,
  useListenSalesAboutImageQuery,
  useUpdateSalesHeroSectionMutation,
  useUpdateSalesHeroImageMutation,
  useUpdateSalesHeroImgDeskPosMutation,
  useUpdateSalesHeroImgMobPosMutation,
  useUpdateSalesHightlightsSectionMutation,
  useUpdateSalesBannerSectionMutation,
  useUpdateSalesAboutSectionMutation,
  useUpdateCheckoutHeroMutation,
  useUpdateCheckoutBenefitsMutation,
  useUpdateCheckoutHeroMainTextMutation,
  useUpdateSalesFaqSectionMutation,
  useListenTestimonialImageQuery,
  useUpdateSalesTestimonialSectionMutation,
  useListenSalesPageSeoQuery,
  useUpdateSalesPageSeoMutation,
  useListenCheckoutPageSeoQuery,
  useUpdateCheckoutPageSeoMutation,
  useListenSeoImgQuery,
} = pageBuilderApi
