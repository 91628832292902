import React, { useEffect, useRef } from 'react'
import { CgAdd, CgTrash } from 'react-icons/cg'
import { useForm, FormProvider } from 'react-hook-form'
import tw from 'twin.macro'

import { useUpdateSalesHightlightsSectionMutation } from 'modules/PageBuilder/pageBuilderApi'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { SALES_FIELDS_MAXLENGTH } from 'modules/PageBuilder/constants/salesConstants'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { Button } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import PreviewInput from '../Inputs/PreviewInput'
import { inputIds, inputNames, inputRefsSortMethod } from '../../constants/salesInputConstants'

const HIGHLIGHTS_LIMIT = 6

export function HighlightsInputList({ coachOrgId, highlights, isMob }) {
  const [updateSection] = useUpdateSalesHightlightsSectionMutation()

  const defaultValues = { highlights }

  const methods = useForm({
    defaultValues,
  })

  const { watch, setValue, handleSubmit } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    await updateSection({ coachOrgId, section: data })
  }

  const handleDeleteHighlight = (hIdx) => {
    const newHighlights = formState.highlights.filter((hl, index) => index !== hIdx)
    setValue('highlights', newHighlights)
  }

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit))
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch])

  const addHlBtnRef = useRef()
  const { addInputRef, removeInputRef } = useFormRefsControl()
  useEffect(() => {
    removeInputRef(addHlBtnRef, inputNames.addHlBtn)

    addInputRef({
      ref: addHlBtnRef,
      name: inputNames.addHlBtn,
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlights.length])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id='highlightsForm'>
        {formState.highlights.map((highlight, index) => (
          <Highlight
            key={index}
            index={index}
            highlight={highlight}
            isMob={isMob}
            setValue={setValue}
            handleDeleteHighlight={handleDeleteHighlight}
          />
        ))}
        {highlights?.length < HIGHLIGHTS_LIMIT && (
          <div css={isMob ? tw`w-full mt-3 mx-4 w-[calc(100% - 32px)]` : tw`w-[calc(100% - 56px)] mt-3`}>
            <Tooltip content='Add highlight'>
              <Button
                ref={addHlBtnRef}
                type='button'
                variant='secondary'
                size='md'
                css={tw`w-full`}
                onClick={() =>
                  setValue(`highlights.${highlights.length}`, {
                    header: `Highlight #${highlights.length + 1}`,
                    description: 'Description',
                  })
                }
              >
                <CgAdd className='w-5 h-5 mr-2' /> Add highlight
              </Button>
            </Tooltip>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

function Highlight({ index, highlight, isMob, setValue, handleDeleteHighlight }) {
  const { addInputRef, removeInputRef } = useFormRefsControl()
  const hlHeaderRef = useRef()
  const hlDescriptionRef = useRef()

  useEffect(() => {
    removeInputRef(hlHeaderRef, inputNames.highlight)
    removeInputRef(hlDescriptionRef, inputNames.highlight)

    addInputRef({
      ref: hlHeaderRef,
      name: inputNames.highlight,
      sortMethod: inputRefsSortMethod,
    })
    addInputRef({
      ref: hlDescriptionRef,
      name: inputNames.highlight,
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <div className='relative flex flex-col group' css={[isMob && tw`px-2`]}>
      <div
        className='absolute top-0 bottom-1 opacity-0 group-hover:opacity-100 delay-100 hover:opacity-100'
        css={[isMob ? tw`-left-2` : tw`-left-8`]}
      >
        <Dialog>
          <Tooltip content='Remove'>
            <DialogTrigger
              className='flex items-center justify-center bg-offWhite h-full hover:bg-offWhite-dark'
              css={[isMob ? tw`w-4 rounded-md` : tw`w-8 rounded-lg`]}
              aria-label='Remove Highlight'
            >
              <CgTrash css={[isMob ? tw`w-3.5 h-3.5` : tw`w-5 h-5`]} />
            </DialogTrigger>
          </Tooltip>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will delete highlight #${index + 1}`}
              handleDelete={() => handleDeleteHighlight(index)}
            />
          </DialogContent>
        </Dialog>
      </div>
      <PreviewInput
        id={inputIds.highlightTitleId}
        inputRef={hlHeaderRef}
        name={inputNames.highlight}
        inputCss={[isMob ? tw`text-base` : tw`text-lg`, tw`font-bold`]}
        value={highlight.header}
        maxLength={SALES_FIELDS_MAXLENGTH.programSummary.highlights.header}
        onChange={(_, value) => setValue(`highlights.${index}.header`, value)}
      />
      <PreviewInput
        inputRef={hlDescriptionRef}
        name={inputNames.highlight}
        inputCss={[isMob ? tw`text-base` : tw`text-lg`, tw`text-gray-500 my-1`]}
        value={highlight.description}
        maxLength={SALES_FIELDS_MAXLENGTH.programSummary.highlights.description}
        onChange={(_, value) => setValue(`highlights.${index}.description`, value)}
      />
    </div>
  )
}
