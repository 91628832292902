import React, { useEffect, useRef } from 'react'
import { CgAdd, CgTrash } from 'react-icons/cg'
import { useForm, FormProvider } from 'react-hook-form'
import tw from 'twin.macro'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { useUpdateSalesFaqSectionMutation } from 'modules/PageBuilder/pageBuilderApi'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { Button } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import PreviewInput from '../Inputs/PreviewInput'
import { inputNames, inputRefsSortMethod } from '../../constants/salesInputConstants'

const FAQ_LIMIT = 6

export function FaqList({ coachOrgId, faqs, isMob }) {
  const [updateFaqs] = useUpdateSalesFaqSectionMutation()

  const defaultValues = { faqs }

  const methods = useForm({
    defaultValues,
  })

  const { watch, setValue, handleSubmit } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    await updateFaqs({ coachOrgId, faqs: data.faqs })
  }

  const handleDeleteFaq = (faqIdx) => {
    const newFaqs = formState.faqs.filter((_, index) => index !== faqIdx)
    setValue('faqs', newFaqs)
  }

  useEventListener('keydown', (e) => {
    if (e.code === 'Tab') e.preventDefault()
  })

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit))
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch])

  const addFaqBtnRef = useRef()
  const { addInputRef, removeInputRef } = useFormRefsControl()
  useEffect(() => {
    removeInputRef(addFaqBtnRef, inputNames.addFaqBtn)

    addInputRef({
      ref: addFaqBtnRef,
      name: inputNames.addFaqBtn,
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqs.length])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='faqsForm'
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.code === 'Tab') {
            e.preventDefault() //Otherwise form autosubmits on each enter press
          }
        }}
      >
        {formState.faqs.map((faq, index) => (
          <Faq key={index} index={index} faq={faq} handleDelete={handleDeleteFaq} setValue={setValue} isMob={isMob} />
        ))}
        {faqs?.length < FAQ_LIMIT && (
          <div css={[tw`w-full mt-3 px-4`]}>
            <Tooltip content='Add question'>
              <Button
                ref={addFaqBtnRef}
                type='button'
                variant='secondary'
                size='md'
                css={tw`max-w-sm w-full mx-auto`}
                onClick={() =>
                  setValue(`faqs.${faqs.length}`, {
                    q: `Question #${faqs.length + 1}`,
                    a: 'Answer',
                  })
                }
              >
                <CgAdd className='w-5 h-5 mr-2' /> Add question
              </Button>
            </Tooltip>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

function Faq({ index, faq, handleDelete, setValue, isMob }) {
  const { addInputRef, removeInputRef } = useFormRefsControl()
  const faqTitleRef = useRef()
  const faqDescriptionRef = useRef()

  useEffect(() => {
    removeInputRef(faqTitleRef, inputNames.faq)
    removeInputRef(faqDescriptionRef, inputNames.faq)

    addInputRef({
      ref: faqTitleRef,
      name: inputNames.faq,
      sortMethod: inputRefsSortMethod,
    })
    addInputRef({
      ref: faqDescriptionRef,
      name: inputNames.faq,
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <div className='relative flex flex-col group' css={[isMob && tw`px-2`]}>
      <div
        className='absolute top-0 bottom-1 opacity-0 group-hover:opacity-100 delay-100 hover:opacity-100'
        css={[isMob ? tw`-left-2` : tw`-left-8`]}
      >
        <Dialog>
          <Tooltip content='Remove'>
            <DialogTrigger
              className='flex items-center justify-center bg-offWhite h-full hover:bg-offWhite-dark'
              css={[isMob ? tw`w-4 rounded-md` : tw`w-8 rounded-lg`]}
              aria-label='Remove faq'
            >
              <CgTrash css={[isMob ? tw`w-3.5 h-3.5` : tw`w-5 h-5`]} />
            </DialogTrigger>
          </Tooltip>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will delete question #${index + 1}`}
              handleDelete={() => handleDelete(index)}
            />
          </DialogContent>
        </Dialog>
      </div>
      <PreviewInput
        inputRef={faqTitleRef}
        name={inputNames.faq}
        inputCss={[isMob ? tw`text-base` : tw`text-lg`, tw`font-bold`]}
        value={faq.q}
        onChange={(_, value) => setValue(`faqs.${index}.q`, value)}
      />
      <PreviewInput
        inputRef={faqDescriptionRef}
        name={inputNames.faq}
        inputCss={[isMob ? tw`text-base` : tw`text-lg`, tw`text-gray-500 my-1`]}
        value={faq.a}
        onChange={(_, value) => setValue(`faqs.${index}.a`, value)}
      />
    </div>
  )
}
