import tw from 'twin.macro'
import React, { Fragment, useRef } from 'react'
import { CgChevronDown } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'
import * as Portal from '@radix-ui/react-portal'

import { exLibDisplayValues } from 'common/constants/exLibConstants'
import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import { usePopper } from 'common/hooks/usePopper'
import {
  useListenExLibVisibilityQuery,
  useSetExLibVisibilityMutation,
} from 'modules/ExerciseLibrary/exerciseLibraryApi'

export const availableDisplayTypes = [
  { value: exLibDisplayValues.hidden, displayValue: 'Fully hidden' },
  { value: exLibDisplayValues.subsOnly, displayValue: 'Subscribers only' },
  { value: exLibDisplayValues.subsAndOneTimesOnly, displayValue: 'Subscribers & one-time product buyers' },
  { value: exLibDisplayValues.everyone, displayValue: 'All users' },
]

const SELECT_INPUT_WIDTH = 300

export default function ExLibDisplaySelect({ coachOrgId }) {
  const comboboxBtnRef = useRef()

  const { data: exLibVisibilityData } = useListenExLibVisibilityQuery({ coachOrgId })
  const exLibVisibility = getExLibVisibilityValue(exLibVisibilityData)

  const [setExLibEnabled] = useSetExLibVisibilityMutation()

  const handleInputKeyDown = (e) => {
    const acceptedCodes = ['Enter', 'Tab', 'ArrowUp', 'ArrowDown']
    if (!acceptedCodes.includes(e.code)) {
      e.preventDefault()
    } else if (e.code === 'Enter' && !e.shiftKey) {
      comboboxBtnRef.current.click()
    }
  }

  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  return (
    <Combobox
      value={exLibVisibility}
      onChange={(newValue) => {
        setExLibEnabled({ coachOrgId, visibility: newValue })
      }}
    >
      <div ref={popperTrigger} style={{ width: SELECT_INPUT_WIDTH }}>
        <Combobox.Button ref={comboboxBtnRef} as='div' className='relative'>
          <Combobox.Input
            placeholder='Select display type'
            autoComplete='off'
            displayValue={(val) => availableDisplayTypes.find(({ value }) => value === val).displayValue}
            onKeyDown={handleInputKeyDown}
            className='text-ellipsis bg-inherit cursor-pointer'
            css={inputClasses}
            style={{ width: SELECT_INPUT_WIDTH }}
          />
          <CgChevronDown className='absolute right-1 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 w-4 h-4' />
        </Combobox.Button>
      </div>
      <Portal.Root>
        <Combobox.Options className='z-[999]' ref={popperContainer} modal={false}>
          <SuggestionContainer containerStyle={{ width: SELECT_INPUT_WIDTH }}>
            {availableDisplayTypes.map(({ value, displayValue }) => (
              <Combobox.Option as={Fragment} key={value} value={value}>
                {({ active }) => (
                  <SuggestionItem active={active}>
                    <div className='flex items-center text-sm'>{displayValue}</div>
                  </SuggestionItem>
                )}
              </Combobox.Option>
            ))}
          </SuggestionContainer>
        </Combobox.Options>
      </Portal.Root>
    </Combobox>
  )
}

const inputClasses = tw`
  flex mb-2 text-sm 
  rounded-md bg-white 
  ring-1 border-0 
  ring-gray-300 bg-gray-100  
  text-tBlack placeholder:text-tBlack 
  placeholder:text-opacity-50 
  focus:border-0 hover:ring-tGreen 
  focus:ring-tGreen focus-visible:outline-none 
  p-2 caret-transparent
`

function getExLibVisibilityValue(exLibVisibility) {
  if (!exLibVisibility) {
    return exLibDisplayValues.hidden
  } else if (exLibVisibility === true) {
    return exLibDisplayValues.subsOnly
  }
  return exLibVisibility
}
