import { useAuth } from 'modules/Auth/hooks/useAuth'
import React from 'react'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenExLibVisibilityQuery,
  useListenShowExLibLevelsQuery,
  useSetShowExLibLevelsMutation,
} from 'modules/ExerciseLibrary/exerciseLibraryApi'
import { useListenLinkHubEnabledQuery, useSetLinkHubEnabledMutation } from 'modules/LinkHub/linkHubApi'
import {
  useListenContentFiltersEnabledQuery,
  useListenShowProgressionFilterQuery,
  useSetContentFilterEnabledMutation,
  useSetShowProgressionFilterMutation,
} from 'modules/ContentTags/contentTagsApi'
import { useListenMetronomeEnabledQuery, useSetMetronomeEnabledMutation } from './accountApi'

import { Spinner } from 'common/components/Spinner/Spinner'
import { Switch } from 'common/components/Switch/Switch'
import { exLibDisplayValues } from 'common/constants/exLibConstants'
import AccountLayout from './AccountLayout'
import ExLibDisplaySelect from './components/ExLibDisplaySelect/ExLibDisplaySelect'

export default function Advanced() {
  const { userId } = useAuth()

  const [setShowExLibLevels] = useSetShowExLibLevelsMutation()
  const [setLinkHubEnabled] = useSetLinkHubEnabledMutation()
  const [setContentFilterEnabled] = useSetContentFilterEnabledMutation()
  const [setShowProgressionFilter] = useSetShowProgressionFilterMutation()
  const [setMetronomeEnabled] = useSetMetronomeEnabledMutation()

  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const { data: exLibVisibility } = useListenExLibVisibilityQuery({ coachOrgId })
  const { data: showExLibLevels } = useListenShowExLibLevelsQuery({ coachOrgId })
  const { data: linkHubEnabled } = useListenLinkHubEnabledQuery({ coachOrgId })
  const { data: contentFiltersEnabled } = useListenContentFiltersEnabledQuery({ coachOrgId })
  const { data: showProgressionFilter } = useListenShowProgressionFilterQuery({ coachOrgId })
  const { data: metronomeEnabled } = useListenMetronomeEnabledQuery({ coachOrgId })

  const dataLoading =
    profile === undefined ||
    profile?.isLoading ||
    exLibVisibility === undefined ||
    exLibVisibility?.isLoading ||
    showExLibLevels === undefined ||
    showExLibLevels?.isLoading ||
    linkHubEnabled === undefined ||
    linkHubEnabled?.isLoading ||
    contentFiltersEnabled === undefined ||
    contentFiltersEnabled?.isLoading ||
    showProgressionFilter === undefined ||
    showProgressionFilter?.isLoading ||
    metronomeEnabled === undefined ||
    metronomeEnabled?.isLoading

  if (dataLoading) {
    return (
      <AccountLayout>
        <Spinner className='w-10 h-10 text-gray-100 m-auto' />
      </AccountLayout>
    )
  }

  return (
    <AccountLayout>
      <div className='mb-12'>
        <h1 className='font-bold text-xl mb-4'>Workout Features</h1>
        <Switch
          labelClasses='justify-between w-full'
          labelContainerClasses='text-sm'
          onChange={() => setMetronomeEnabled({ coachOrgId, enabled: !metronomeEnabled })}
          label='Enable Metronome for Users'
          isChecked={metronomeEnabled === true}
        />
      </div>
      <div className='mb-12'>
        <h1 className='font-bold text-xl mb-4'>Additional Resources</h1>
        <Switch
          labelClasses='justify-between w-full'
          labelContainerClasses='text-sm'
          onChange={() => setLinkHubEnabled({ coachOrgId, enabled: !linkHubEnabled })}
          label='Display Additional Resources to Users'
          isChecked={linkHubEnabled === true}
        />
      </div>
      <div className='mb-12'>
        <h1 className='font-bold text-xl mb-4'>Exercise Library</h1>
        <div className='flex flex-col lg:flex-row justify-between items-start lg:items-center'>
          <h3 className='mb-2 mr-5 text-gray-700 font-semibold flex text-sm'>Exercise Library Visibilty to Users</h3>
          <ExLibDisplaySelect coachOrgId={coachOrgId} />
        </div>
        <p className='text-sm text-gray-500 mt-2'>
          Shows a dedicated Exercise Library to users in the app. Exercises will only be shown if they are properly
          tagged with a “Tag” and “Level”.
        </p>
        {Boolean(exLibVisibility) && exLibVisibility !== exLibDisplayValues.hidden && (
          <div className='mt-8'>
            <Switch
              labelClasses='justify-between w-full'
              labelContainerClasses='text-sm'
              onChange={() => setShowExLibLevels({ coachOrgId, show: !showExLibLevels })}
              label='Display “Level” to Users'
              isChecked={showExLibLevels === true}
            />
            <p className='text-sm text-gray-500 mt-2'>
              Level is used to order the Exercises in the library in ascending order. The Level value can be optionally
              displayed to users.
            </p>
          </div>
        )}
      </div>
      <div className='mb-12'>
        <h1 className='font-bold text-xl mb-4'>Content Filters</h1>
        <div className='space-y-4'>
          <Switch
            labelClasses='justify-between w-full'
            labelContainerClasses='text-sm'
            onChange={() =>
              setContentFilterEnabled({ coachOrgId, enabled: !contentFiltersEnabled?.difficulty, name: 'difficulty' })
            }
            label='Display Difficulty Filters to Users'
            isChecked={contentFiltersEnabled?.difficulty === true}
          />
          <Switch
            labelClasses='justify-between w-full'
            labelContainerClasses='text-sm'
            onChange={() =>
              setContentFilterEnabled({ coachOrgId, enabled: !contentFiltersEnabled?.equipment, name: 'equipment' })
            }
            label='Display Equipment Filters to Users'
            isChecked={contentFiltersEnabled?.equipment === true}
          />
          <Switch
            labelClasses='justify-between w-full'
            labelContainerClasses='text-sm'
            onChange={() =>
              setContentFilterEnabled({ coachOrgId, enabled: !contentFiltersEnabled?.instructors, name: 'instructors' })
            }
            label='Display Instructor Filters to Users'
            isChecked={contentFiltersEnabled?.instructors === true}
          />
          <Switch
            labelClasses='justify-between w-full'
            labelContainerClasses='text-sm'
            onChange={() =>
              setContentFilterEnabled({ coachOrgId, enabled: !contentFiltersEnabled?.tags, name: 'tags' })
            }
            label='Display Tag Filters to Users'
            isChecked={contentFiltersEnabled?.tags === true}
          />
        </div>
      </div>
      <div>
        <h1 className='font-bold text-xl mb-4'>Exercise Progressions</h1>
        <Switch
          labelClasses='justify-between w-full'
          labelContainerClasses='text-sm'
          onChange={() => setShowProgressionFilter({ coachOrgId, show: !showProgressionFilter })}
          label='Display Exercise Progression Filters to Users'
          isChecked={showProgressionFilter === true}
        />
      </div>
    </AccountLayout>
  )
}
