import tw from 'twin.macro'
import { AiOutlineDesktop, AiOutlineMobile } from 'react-icons/ai'

export const DisplayModeSwitch = ({ displayMode, setDisplayMode }) => (
  <div className='flex items-center p-1.5 bg-white rounded-lg mb-2'>
    <button
      css={[
        tw`p-2 rounded-lg cursor-pointer`,
        displayMode === 'desktop' ? tw`bg-offWhite` : tw`hover:bg-offWhite-dark`,
      ]}
      onClick={() => setDisplayMode('desktop')}
    >
      <AiOutlineDesktop className='w-5 h-5' />
    </button>
    <button
      css={[
        tw`ml-1.5 p-2 rounded-lg cursor-pointer`,
        displayMode === 'mobile' ? tw`bg-offWhite` : tw`hover:bg-offWhite-dark`,
      ]}
      onClick={() => setDisplayMode('mobile')}
    >
      <AiOutlineMobile className='w-5 h-5' />
    </button>
  </div>
)
