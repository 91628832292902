import tw from 'twin.macro'
import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'
import { CgImage } from 'react-icons/cg'

import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { Input } from 'common/components/Input/Input'
import { Textarea } from 'common/components/Textarea/Textarea'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { schema } from './schema'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { useListenSeoImgQuery } from 'modules/PageBuilder/pageBuilderApi'

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack mb-1`
const InputError = tw.p`flex text-xs mt-1 text-tRed`

export function SeoForm({ coachOrgId, seo, updateSeo, uploadLocation }) {
  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const [loading, setLoading] = useState(false)

  const { data: seoPreviewImg } = useListenSeoImgQuery({ coachOrgId, uploadLocation })

  const defaultValues = {
    title: seo?.title || '',
    description: seo?.description || '',
    previewImg: seo?.previewImg || '',
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {
    watch,
    setValue,
    setError,
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = methods

  const formState = watch()
  const isFormDisabled = isEqual(formState, defaultValues)

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await updateSeo({ coachOrgId, seo: data })
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    createAlert({ text: 'SEO updated!', type: 'success' })
    setDialogOpen(false)
  }

  const { addManyInputRefs, moveFocusOnKeyPress } = useFormRefsControl()
  const titleRef = useRef()
  const descriptionRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: titleRef, name: 'title' },
      { ref: descriptionRef, name: 'description' },
      { ref: submitRef, name: 'submit' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))

  return (
    <>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='seoForm'>
          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <div className='mb-2'>
              <Label htmlFor='title'>SEO title</Label>
              <Input
                name='title'
                placeholder='Enter SEO title'
                register={register}
                inputRef={titleRef}
                error={errors?.title?.message}
                autoFocus={true}
                maxLength={60}
              />
              {errors?.title && <p className='flex text-xs mt-1 text-tRed'>{errors.title.message}</p>}
            </div>
          </div>
          <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
            <div className='mb-2'>
              <Label htmlFor='description'>SEO description</Label>
              <Textarea
                name='description'
                type='textarea'
                placeholder='Enter SEO description'
                register={register}
                inputRef={descriptionRef}
                error={errors?.description?.message}
                maxLength={160}
              />
              {errors.description && <p className='flex text-xs mt-1 text-tRed'>{errors.description.message}</p>}
            </div>
          </div>
          <div className='flex flex-col px-10 py-4'>
            <UploadInput
              name='previewImg'
              label='SEO image'
              register={register}
              setValue={setValue}
              coachOrgId={coachOrgId}
              id={uploadLocation}
              uploadType='seo-previewImg'
              liveUrl={seoPreviewImg || seo?.previewImg}
              setError={setError}
              previewIcon={<CgImage className='!w-5 !h-5' />}
              clearErrors={clearErrors}
              fileType='image'
            />
            {errors?.previewImg ? <InputError>{errors.previewImg.message}</InputError> : null}
          </div>
        </form>
      </div>
      <UpdateActions
        handleSubmit={handleSubmit}
        loading={loading}
        hideDelete={true}
        disabled={isFormDisabled}
        actionText='Save'
        form='seoForm'
        ref={submitRef}
      />
    </>
  )
}
