/**
 * Context simplifies the management of refs used for auto-focusing.
 * Allows us to add refs for new inputs on the fly, then pass in an array of strings that represent the sorted order
 * The context will manage how to move from one input to the next and also account for when one input can be a dynamic number of refs (e.g., cues) by running sorting & filtering operations
 * posIdx is the absolute index of the ref, i.e., 1 is the second ref including all possible inputs
 * while exIdx or cueIdx represent the relative index within that specific index. So for example, an exIdx of 0 means the first exercise, but could be posIdx 2 of the entire form
 */
import { each, findIndex, sortBy } from 'lodash'
import { createContext, useEffect, useState } from 'react'
import { arrayMove } from '@dnd-kit/sortable'

const FORWARDS = 'forwards'
const BACKWARDS = 'backwards'

export const FormRefsControlContext = createContext()
FormRefsControlContext.displayName = 'FormRefsControlContext'

export function FormRefsControlProvider({ children }) {
  const [inputRefs, setInputRefs] = useState([])
  const [lastKnownSortMethod, setLastKnownSortMethod] = useState([])

  useEffect(() => {
    setInputRefs((inputRefs) => {
      return [...reorderByPosIdx({ inputs: inputRefs })]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastKnownSortMethod])

  const addInputRef = ({ ref, posIdx, name, sortMethod, ...restArgs }) => {
    setInputRefs((state) => {
      const combinedRefs = [...state, { posIdx, name, ...restArgs, ref }]
      let cleanedRefs = combinedRefs.filter((data) => data?.ref?.current !== null)
      if (sortMethod) {
        setLastKnownSortMethod(sortMethod)
        return reorderByPosIdx({ inputs: cleanedRefs, sortMethod })
      } else {
        return cleanedRefs
      }
    })
  }

  const addManyInputRefs = (refs, offset = 0) => {
    const newRefs = refs.map((refInput, idx) => ({
      posIdx: idx + offset,
      name: refInput.name,
      ref: refInput.ref,
    }))
    setInputRefs((state) => {
      const combinedRefs = [...state, ...newRefs]
      let cleanedRefs = combinedRefs.filter((data) => data?.ref?.current !== null)
      return cleanedRefs
    })
  }

  const clearControlledRefs = () => {
    setInputRefs([])
  }

  const removeInputRef = (targetRef, refName) => {
    setInputRefs((state) => {
      let filteredRefs = state.filter((data) => data.ref !== targetRef) //Remove specified ref
      const namedRefs = filteredRefs.filter((data) => data.name === refName) //Filter to only the specifically named ref
      if (!namedRefs[namedRefs.length - 1]?.ref?.current?.value) {
        filteredRefs = filteredRefs.filter((data) => data?.posIdx !== namedRefs[namedRefs.length - 1]?.posIdx) //then exclude the ref that matches the last ref for cues
      }
      const removedInput = reorderByPosIdx({ inputs: filteredRefs })
      return removedInput
    })
  }

  const removeInputRefsByNames = (refNames) => {
    //AL note: Does not seem to be used anywhere?
    setInputRefs((state) => {
      const namedRefs = state.filter((data) => !refNames.includes(data.name)) //Filter to only the specifically named ref
      const removedInput = reorderByPosIdx({ inputs: namedRefs })
      return removedInput
    })
  }

  const reorderByPosIdx = ({ inputs, sortMethod }) => {
    let sortedInputs = []
    const usedSortMethod = sortMethod || lastKnownSortMethod

    if (usedSortMethod && usedSortMethod.length > 0) {
      //First sort by high-level categories, i.e., if "name" is first, and "submit" is last it will do those sortings first before consiering posIdx
      each(usedSortMethod, (method) => {
        const filteredInputs = inputs.filter((input) => input.name === method).filter((inp) => inp.ref.current)

        if (filteredInputs?.length > 1) {
          const key = `${method}Idx`
          const sortedFilteredInputs = sortBy(filteredInputs, (input) => input[key])
          const indexesTagged = calcAndSetIndex({ sortedInputs: sortedFilteredInputs, subIndexBy: method })
          sortedInputs.push(...indexesTagged)
        } else {
          sortedInputs.push(...filteredInputs)
        }
      })
    } else {
      sortedInputs = sortBy(inputs, (input) => input.posIdx)
    }

    return calcAndSetIndex({ sortedInputs })
  }

  /**
   * Takes a sorted array of inputs and retags the posIdx based on sorted array order
   * If there is a subIndexBy, then also retags those values
   */
  const calcAndSetIndex = ({ sortedInputs, subIndexBy }) => {
    let subIndex = 0
    const indexedInputRefs = sortedInputs.map((val, idx) => {
      const newVal = { ...val, posIdx: idx }
      if (newVal.name === subIndexBy) {
        const key = `${subIndexBy}Idx`
        newVal[key] = subIndex
        subIndex++
      }
      return newVal
    })
    return indexedInputRefs
  }

  /**
   *  Called when Drag and Drop is used to move inputs (and therefore their refs need to be moved as well)
   *  Use arrayMove to reorder the inputRefs array
   *  Run calcAndSetIndex to clean up indexes
   */
  const moveInputRefs = (subIndexBy, oldIdx, newIdx) => {
    const key = `${subIndexBy}Idx` //e.g., exerciseIdx, cueIdx, programIdx
    const sortedInpuRefs = reorderByPosIdx({ inputs: inputRefs }) //Sort refs by pos

    const oldPosIdx = findIndex(sortedInpuRefs, (inp) => inp[key] === oldIdx)
    const newPosIdx = findIndex(sortedInpuRefs, (inp) => inp[key] === newIdx)

    let newInputRefs = arrayMove(sortedInpuRefs, oldPosIdx, newPosIdx)
    const refsOfSubOrderType = newInputRefs.filter((data) => data.name === subIndexBy)
    if (!refsOfSubOrderType[refsOfSubOrderType.length - 1].ref?.current?.value) {
      //If the last ref doesnt have a value...
      //then adjust new InputRefs so that we filter out that last ref
      newInputRefs = newInputRefs.filter(
        (data) => data.posIdx !== refsOfSubOrderType[refsOfSubOrderType.length - 1].posIdx
      )
    }
    setInputRefs(calcAndSetIndex({ sortedInputs: newInputRefs, subIndexBy }))
  }

  const moveFocusOnKeyPress = (e, handleSubmit) => {
    if ((e.code === 'Enter' && e.target.type !== 'textarea') || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()

      const activeIndex = findIndex(inputRefs, (input) => input.ref.current === document.activeElement)
      const submitRef = inputRefs.find((ref) => ref.name === 'submit')

      if (!e.shiftKey) {
        if (activeIndex === -1 && document?.activeElement?.tagName === 'INPUT') {
          document.activeElement.blur()
        } else if (document.activeElement.tagName === 'BUTTON') {
          if (e.code === 'Enter') {
            if (document.activeElement === submitRef?.ref?.current) {
              handleSubmit()
            } else {
              document.activeElement.click()
            }
          } else {
            moveFocusedInputBy(1)
          }
        } else {
          moveFocusedInputBy(1)
        }
      } else if (e.shiftKey) {
        moveFocusedInputBy(-1)
      }
    }
  }

  const focusNonDisabledInput = (jumpToIdx, jumpDirection) => {
    if (jumpDirection === FORWARDS) {
      const nextNonDisabledInput = inputRefs.find(
        (ref) => ref?.ref?.current?.disabled === false && jumpToIdx <= ref.posIdx
      )?.ref?.current

      if (nextNonDisabledInput) {
        nextNonDisabledInput.focus()
        if (nextNonDisabledInput?.type === 'textarea') {
          // place cursor at the end of textarea text
          const inputTextLength = nextNonDisabledInput?.value?.length
          nextNonDisabledInput.setSelectionRange(inputTextLength, inputTextLength)
        }
      } else {
        // Cannot find next non disabled input, try to find first non disabled input from the top of the list
        const firstPrevNonDisabledInput = inputRefs.find((ref) => ref?.ref?.current?.disabled === false)?.ref?.current
        firstPrevNonDisabledInput.focus()
        if (firstPrevNonDisabledInput?.type === 'textarea') {
          // place cursor at the end of textarea text
          const inputTextLength = firstPrevNonDisabledInput?.value?.length
          firstPrevNonDisabledInput.setSelectionRange(inputTextLength, inputTextLength)
        }
      }
    }

    if (jumpDirection === BACKWARDS) {
      const prevNonDisabledInput = inputRefs
        .toReversed()
        .find((ref) => ref?.ref?.current?.disabled === false && jumpToIdx >= ref.posIdx)?.ref?.current

      if (prevNonDisabledInput) {
        prevNonDisabledInput.focus()
        if (prevNonDisabledInput?.type === 'textarea') {
          // place cursor at the end of textarea text
          const inputTextLength = prevNonDisabledInput?.value?.length
          prevNonDisabledInput.setSelectionRange(inputTextLength, inputTextLength)
        }
      } else {
        // Cannot find previous non disabled input, try to find non disabled input from the bottom of the list
        const nextNonDisabledInput = inputRefs.toReversed().find((ref) => ref?.ref?.current?.disabled === false)
          ?.ref?.current
        nextNonDisabledInput.focus()
        if (nextNonDisabledInput?.type === 'textarea') {
          // place cursor at the end of textarea text
          const inputTextLength = nextNonDisabledInput?.value?.length
          nextNonDisabledInput.setSelectionRange(inputTextLength, inputTextLength)
        }
      }
    }
  }

  const moveFocusedInputBy = (jumpBy, jumpFromIdx) => {
    const activeIndex = jumpFromIdx
      ? jumpFromIdx
      : findIndex(inputRefs, (input) => input.ref.current === document.activeElement)
    const jumpToIdx = activeIndex + jumpBy
    const jumpingForwards = jumpBy > 0
    const jumpDirection = jumpingForwards ? FORWARDS : BACKWARDS

    focusNonDisabledInput(jumpToIdx, jumpDirection)
  }

  const moveFocusedInputTo = (jumpToIdx) => {
    const activeIndex = findIndex(inputRefs, (input) => input.ref.current === document.activeElement)
    const jumpingForwards = jumpToIdx > activeIndex
    const jumpDirection = jumpingForwards ? FORWARDS : BACKWARDS

    focusNonDisabledInput(jumpToIdx, jumpDirection)
  }

  const contextValue = {
    inputRefs: reorderByPosIdx({ inputs: inputRefs }),
    addInputRef,
    addManyInputRefs,
    removeInputRef,
    removeInputRefsByNames,
    moveInputRefs,
    clearControlledRefs,
    moveFocusOnKeyPress,
    moveFocusedInputBy,
    moveFocusedInputTo,
    setLastKnownSortMethod,
  }

  return <FormRefsControlContext.Provider value={contextValue}>{children}</FormRefsControlContext.Provider>
}
