import React from 'react'

import AccountNavbar from './components/navbar/AccountNavbar'

export default function AccountLayout({ children, contentContainerClasses }) {
  return (
    <div className='flex justify-center bg-offWhite py-6 md:py-12 px-4 md:px-16 min-h-screen'>
      <div className='flex flex-col w-full max-w-[1050px]'>
        <h1 className='text-3xl md:text-5xl font-bold mb-5 text-tBlack'>Settings</h1>
        <div className='flex flex-col md:flex-row'>
          <AccountNavbar />
          <div
            className={`flex flex-col p-4 md:p-8 bg-white w-full rounded-2xl ${
              contentContainerClasses ? contentContainerClasses : ''
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
