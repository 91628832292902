import React, { useRef, useState } from 'react'
import { CgAdd, CgTrash } from 'react-icons/cg'
import { FiMove } from 'react-icons/fi'
import tw from 'twin.macro'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useEventListener } from 'common/hooks/useEventListener'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import {
  useListenStripeResponseQuery,
  useSetProductPricesMutation,
  useSetStripeRequestMutation,
} from 'modules/ProductInfo/productInfoApi'

import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { Sortable } from 'common/components/Sortable/Sortable'
import { dropAnimation } from 'common/utils/dndUtils'

import {
  getDefaultNextPlan,
  getPlanPromoBottomText,
  getPlanPromoPrice,
  getPlanPromoIntervalText,
  getPlanIntervalText,
  getIsCouponActive,
} from 'modules/ProductInfo/utils/utils'
import { Spinner } from 'common/components/Spinner/Spinner'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { createUID } from 'common/utils/createUID'
import { PricingForm } from '../PricingForm/PricingForm'

export default function PricingList({ coachOrgId, coupons, plans, loading }) {
  const [editingPrice, setEditingPrice] = useState(null)
  const [addPlanDialogOpen, setAddPlanDialogOpen] = useState(false)

  const [setPrices] = useSetProductPricesMutation()

  const deselectOnEsc = (e) => {
    if (e.code === 'Escape') {
      e.preventDefault()
      e.stopPropagation()
      setEditingPrice(null)
    }
  }

  useEventListener('keydown', deselectOnEsc)

  // Drag and drop
  const [activeDragItem, setActiveDragItem] = useState(null)

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    setEditingPrice(null)
    const activePlan = plans.find((plan) => plan.id === active.id)
    setActiveDragItem({ plan: activePlan })
  }

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = plans?.findIndex((plan) => plan.id === active.id)
      const newIndex = plans?.findIndex((plan) => plan.id === over.id)

      const movedPlans = arrayMove(plans, oldIndex, newIndex).map((plan, newIdx) => ({ ...plan, idx: newIdx }))
      let updatedPlans = {}
      movedPlans.forEach((plan) => {
        updatedPlans[plan.id] = plan
      })
      await setPrices({ coachOrgId, plans: updatedPlans })
      setActiveDragItem(null)
    }
  }

  const planIds = plans?.map((plan) => plan?.id) || []

  if (loading) {
    return <Spinner className='w-10 h-10 text-gray-100 m-auto' />
  }

  const defaultNextPlan = getDefaultNextPlan(plans)
  if (plans?.length === 0) {
    return (
      <>
        <EmptyStateContainer
          text='No pricing plans'
          action={() => setAddPlanDialogOpen(true)}
          actionText='Add new plan'
          containerClasses='!h-auto'
          textCss={tw`text-2xl`}
        />
        <Dialog open={addPlanDialogOpen} setOpen={setAddPlanDialogOpen}>
          <DialogContent header='Add plan' overlayProps={{ 'data-no-dnd': true }}>
            <FormRefsControlProvider>
              <PricingForm
                coachOrgId={coachOrgId}
                plan={defaultNextPlan}
                plans={plans}
                closeForm={() => setAddPlanDialogOpen(false)}
                isCreateForm={true}
              />
            </FormRefsControlProvider>
          </DialogContent>
        </Dialog>
      </>
    )
  }

  return (
    <div className='flex flex-col border-2 border-tGreen rounded-xl overflow-hidden'>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={planIds} strategy={verticalListSortingStrategy}>
          {plans.map((plan, idx) => {
            const appliedPromo = plan.promo?.find(
              (prm) => getIsCouponActive({ promo: prm, coupon: coupons?.[prm?.stripeCouponId] }) && prm.isAutoApplied
            )
            const appliedCoupon = coupons?.[appliedPromo?.stripeCouponId]
            return (
              <Sortable
                key={plan.id}
                id={plan.id}
                withHandle={true}
                css={[
                  tw`flex flex-col border-b-[1px] border-b-tGray-light last:border-b-0`,
                  plan.idx !== 0 && plan.idx === editingPrice && tw`border-t-2 border-tGreen`,
                  plan.idx !== plans.length - 1 && plan.idx === editingPrice && tw`border-b-2 border-tGreen`,
                  plan.idx === plans.length - 1 && tw`border-b-0`,
                ]}
                draggingClasses='ring-2 ring-tGreen opacity-50'
              >
                <PricingListItem
                  coachOrgId={coachOrgId}
                  plans={plans}
                  plan={plan}
                  editingPrice={editingPrice}
                  setEditingPrice={setEditingPrice}
                  appliedCoupon={appliedCoupon}
                />
              </Sortable>
            )
          })}
        </SortableContext>
        <DragOverlay
          zIndex={10}
          className='cursor-move'
          dropAnimation={dropAnimation}
          modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
        >
          {activeDragItem && (
            <PricingListItem
              coachOrgId={coachOrgId}
              plans={plans}
              plan={activeDragItem.plan}
              editingPrice={editingPrice}
              setEditingPrice={setEditingPrice}
              isDragging={true}
            />
          )}
        </DragOverlay>
      </DndContext>
      {plans.length < 4 && (
        <Dialog open={addPlanDialogOpen} setOpen={setAddPlanDialogOpen}>
          <DialogTrigger
            className='flex items-center justify-center px-4 h-[56px] hover:bg-gray-100 cursor-pointer font-bold border-t-[1px] border-t-tGray-light'
            onOpenCb={(e) => e.stopPropagation()}
          >
            <CgAdd className='w-5 h-5 mr-2' /> Add plan
          </DialogTrigger>
          <DialogContent header='Add plan' overlayProps={{ 'data-no-dnd': true }}>
            <FormRefsControlProvider>
              <PricingForm
                coachOrgId={coachOrgId}
                plan={defaultNextPlan}
                plans={plans}
                closeForm={() => {
                  setAddPlanDialogOpen(false)
                  setEditingPrice(null)
                }}
                isCreateForm={true}
              />
            </FormRefsControlProvider>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

const PricingListItem = React.memo(
  ({ coachOrgId, plans, plan, editingPrice, setEditingPrice, appliedCoupon, isDragging, attributes, listeners }) => {
    const { createAlert } = useAlert()

    const actionContainerRef = useRef(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const [setStripeRequest] = useSetStripeRequestMutation()
    const [setPrices] = useSetProductPricesMutation()

    const { data: stripeResponse } = useListenStripeResponseQuery({ coachOrgId, responseId: plan?.id })
    const stripeResponsePending = stripeResponse?.status === 'pending'
    const errorMessage = stripeResponse?.error

    const handleDelete = async () => {
      const payload = {
        trybePlanId: plan?.id,
        stripePriceId: plan?.stripe_plan_id,
      }
      const requestId = createUID()
      await setStripeRequest({
        requestId,
        coachOrgId,
        type: 'DELETE_PRICE',
        payload,
      })
      createAlert({ text: 'Plan deleted!', type: 'success' })
    }

    const handleErrRetry = async () => {
      const payload = stripeResponse?.data?.payload
      const type = stripeResponse?.type
      const requestId = createUID()
      await setStripeRequest({
        requestId,
        coachOrgId,
        type,
        payload,
      })
      createAlert({ text: 'Retrying...', type: 'success' })
    }

    const handleErrDelete = async () => {
      const filteredPlans = plans.filter((pl) => pl.id !== plan.id)
      let updatedPlans = {}
      filteredPlans.forEach((pl, index) => {
        updatedPlans[pl.id] = { ...pl, idx: index }
      })
      await setPrices({ coachOrgId, plans: updatedPlans })
      createAlert({ text: 'Plan deleted!', type: 'success' })
    }

    return (
      <div>
        <div
          className='group relative flex flex-col justify-center px-4 min-h-[56px] cursor-pointer hover:bg-gray-100'
          css={[plan.idx === editingPrice && tw`border-b-[1px] border-b-tGray-light`, isDragging && tw`bg-gray-100`]}
          onClick={(e) => {
            if (Boolean(errorMessage)) {
              return
            }

            const occuredOutsideModal = e.currentTarget.contains(e.target)

            // Check that event did not happen in action row (edit/delete/more)
            const occuredOutsideActionRow = !actionContainerRef.current.contains(e.target)
            if (occuredOutsideModal && occuredOutsideActionRow) {
              setEditingPrice(plan.idx === editingPrice ? null : plan.idx)
            }
          }}
        >
          <div className='flex items-center'>
            <div className='relative flex items-center'>
              <span className='text-xl font-bold'>
                ${(plan.price / 100).toFixed(2)} {(!appliedCoupon || appliedCoupon?.type === 'trialOnly') && 'USD'}
              </span>
              {appliedCoupon && appliedCoupon?.type !== 'trialOnly' && <DiscountLineThrough />}
            </div>
            {appliedCoupon && appliedCoupon?.type !== 'trialOnly' && (
              <span className='text-xl font-bold ml-1.5'>
                ${(getPlanPromoPrice({ coupon: appliedCoupon, plan }) / 100).toFixed(2)} USD
              </span>
            )}
            <span className='ml-1.5'>
              {appliedCoupon && appliedCoupon?.type !== 'trialOnly'
                ? getPlanPromoIntervalText(plan)
                : getPlanIntervalText(plan)}
            </span>
          </div>
          <span className='text-xs font-medium'>
            {appliedCoupon ? getPlanPromoBottomText({ coupon: appliedCoupon, plan }) : plan.promoText}
          </span>
          {!errorMessage && (
            <div ref={actionContainerRef} className='absolute right-4 top-1/2 -translate-y-1/2 flex items-center'>
              {stripeResponsePending ? (
                <Spinner />
              ) : (
                <>
                  <Dialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen}>
                    <DialogTrigger
                      className='hidden group-hover:inline-block hover:text-tRed transition-all disabled:cursor-not-allowed'
                      onOpenCb={(e) => e.stopPropagation()}
                    >
                      <CgTrash className='w-5 h-5' />
                    </DialogTrigger>
                    <DialogContent>
                      <DeleteDialogBanner
                        text='This will delete the pricing plan'
                        handleDelete={() => handleDelete()}
                      />
                    </DialogContent>
                  </Dialog>
                  {plans.length > 1 && (
                    <button
                      {...attributes}
                      {...listeners}
                      tabIndex={-1}
                      className='hidden group-hover:inline-block ml-2 cursor-move hover:text-tGreen'
                    >
                      <FiMove className='w-[18px] h-[18px]' />
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {editingPrice === plan.idx && (
          <FormRefsControlProvider>
            <PricingForm
              coachOrgId={coachOrgId}
              plan={plan}
              plans={plans}
              closeForm={() => setEditingPrice(null)}
              handleDelete={() => handleDelete()}
            />
          </FormRefsControlProvider>
        )}
        {Boolean(errorMessage) && (
          <div className='text-xs mt-1 max-w-[90%] px-4 py-1'>
            <span className='text-tRed'>{errorMessage}</span>
            <button
              type='button'
              className='ml-2 underline hover:text-tGreen transition-colors'
              onClick={(e) => {
                e.stopPropagation()
                handleErrRetry()
              }}
            >
              Retry
            </button>
            <span className='mx-2'>or</span>
            <button
              type='button'
              className='underline hover:text-tRed transition-colors'
              onClick={(e) => {
                e.stopPropagation()
                handleErrDelete()
              }}
            >
              Delete plan
            </button>
          </div>
        )}
      </div>
    )
  }
)

const DiscountLineThrough = tw.div`absolute w-full h-[2px] bg-tRed`
