import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { FiMove } from 'react-icons/fi'
import { CgCloseO, CgCheckO } from 'react-icons/cg'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import {
  useListenDraftPrimaryColorQuery,
  useListenSalesHeroImageQuery,
  useUpdateSalesHeroImageMutation,
  useUpdateSalesHeroSectionMutation,
} from 'modules/PageBuilder/pageBuilderApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { Button } from 'common/components/Button/Button'
import { SALES_FIELDS_MAXLENGTH } from 'modules/PageBuilder/constants/salesConstants'
import PreviewInput from '../Inputs/PreviewInput'
import ImageUpload, { btnCSS } from '../Inputs/ImageUpload'
import { useImgReposition } from '../../hooks/useImgReposition'
import { inputRefsSortMethod, inputNames } from '../../constants/salesInputConstants'
import { getLegacyHeroText } from '../../utils/textFormatUtils'

const HERO_IMG_DRAFT_ID = 'sales-hero-img'

export default function SalesHeroPreview({ coachOrgId, section, isMob }) {
  const [heroHeading, setHeroHeading] = useState(section.heroHeading)
  const [secondaryText, setSecondaryText] = useState(section.secondaryText)
  const [heroCTA, setHeroCTA] = useState(section.heroCTA)

  const { data: heroImg } = useListenSalesHeroImageQuery({ coachOrgId })
  const { data: isImgUploading } = useListenExistingItemDraftsQuery({ coachOrgId, id: HERO_IMG_DRAFT_ID })
  const { data: primaryColorData } = useListenDraftPrimaryColorQuery({ coachOrgId })
  const primaryColor = primaryColorData || '#000'

  const [updateSection] = useUpdateSalesHeroSectionMutation()
  const [updateHero] = useUpdateSalesHeroImageMutation()

  useEffect(() => {
    // If heroHeading was never edited in the dashboard, populate it with legacy mainText and mainTextLine2
    const legacyHeroText = getLegacyHeroText(section)
    if (typeof section?.heroHeading !== 'string' && legacyHeroText.length !== 0) {
      updateSection({ coachOrgId, section: { ...section, heroHeading: legacyHeroText } })
    }
    // eslint-disable-next-line
  }, [section])

  const debouncedHeroHeading = useDebounce(heroHeading, 500)
  useDebouncedTextMutation({
    stateText: heroHeading,
    dbText: section.heroHeading,
    mutation: updateSection,
    debouncedStateText: debouncedHeroHeading,
    mutationArgs: {
      coachOrgId,
      section: { ...section, heroHeading: debouncedHeroHeading },
    },
  })

  const debouncedSecondaryText = useDebounce(secondaryText, 500)
  useDebouncedTextMutation({
    stateText: secondaryText,
    dbText: section.secondaryText,
    mutation: updateSection,
    debouncedStateText: debouncedSecondaryText,
    mutationArgs: {
      coachOrgId,
      section: { ...section, secondaryText: debouncedSecondaryText },
    },
  })

  const debouncedHeroCTA = useDebounce(heroCTA, 500)
  useDebouncedTextMutation({
    stateText: heroCTA,
    dbText: section.heroCTA,
    mutation: updateSection,
    debouncedStateText: debouncedHeroCTA,
    mutationArgs: {
      coachOrgId,
      section: { ...section, heroCTA: debouncedHeroCTA },
    },
  })

  useEffect(() => {
    setHeroHeading(section.heroHeading)
    setSecondaryText(section.secondaryText)
    setHeroCTA(section.heroCTA)
  }, [section])

  const { addInputRef } = useFormRefsControl()
  const heroTitleRef = useRef()
  const heroSubtitileRef = useRef()
  const heroBtnTextRef = useRef()
  useEffect(() => {
    addInputRef({ ref: heroTitleRef, name: inputNames.heroTitle, sortMethod: inputRefsSortMethod })
    addInputRef({ ref: heroSubtitileRef, name: inputNames.heroSubtitle, sortMethod: inputRefsSortMethod })
    addInputRef({ ref: heroBtnTextRef, name: inputNames.heroBtnText, sortMethod: inputRefsSortMethod })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearHeroImage = async () => {
    await updateHero({ coachOrgId, image: null })
  }

  const {
    heroImgContainerRef,
    isImgPosEditActive,
    backgroundPosition,
    draggingImg,
    startRepositioning,
    savePosition,
    cancelRepositioning,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  } = useImgReposition({ coachOrgId, isMob })

  return (
    <div
      ref={heroImgContainerRef}
      className='group'
      css={[heroBgClasses, !isImgPosEditActive && heroBgOverlayClasses]}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      style={{
        backgroundImage: heroImg ? `url(${heroImg})` : 'none',
        backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`,
      }}
    >
      {isImgPosEditActive && (
        <div onMouseDown={handleMouseDown} className='w-full h-full cursor-move'>
          {!draggingImg && (
            <div className='flex items-center justify-center w-full h-full bg-black bg-opacity-20'>
              <div
                className='flex items-center justify-center border-dashed border-2 border-white'
                css={[isMob ? tw`w-4/5 h-4/5 text-base` : tw`w-3/5 h-3/5 text-lg`]}
              >
                <h2 className='text-white font-bold text-center' style={{ textShadow: '2px 2px rgba(0,0,0,.3)' }}>
                  Hold click and drag to reposition the image
                </h2>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        css={[tw`invisible absolute top-2 right-2 z-20 flex flex-col items-end`, isImgUploading && tw`visible`]}
        className='group-hover:visible'
      >
        {!isImgPosEditActive && (
          <ImageUpload
            coachOrgId={coachOrgId}
            id={HERO_IMG_DRAFT_ID}
            uploadType='sales-hero-image'
            clearImage={clearHeroImage}
          />
        )}
        {Boolean(heroImg) &&
          (!isImgPosEditActive ? (
            <Button
              size='md'
              variant='secondary'
              type='button'
              onClick={() => startRepositioning()}
              tabIndex={-1}
              css={[btnCSS, tw`mt-2`]}
            >
              <FiMove className='mr-2 w-3.5 h-3.5' />
              Adjust image position
            </Button>
          ) : (
            <>
              <Button
                size='md'
                variant='primary'
                type='button'
                onClick={() => {
                  savePosition()
                }}
                tabIndex={-1}
                css={tw`rounded-md px-4 py-2 text-xs font-medium w-[190px]`}
              >
                <CgCheckO className='mr-2 w-4 h-4' />
                Save position
              </Button>
              <Button
                size='md'
                variant='secondary'
                type='button'
                onClick={() => {
                  cancelRepositioning()
                }}
                tabIndex={-1}
                css={[btnCSS, tw`mt-2`]}
              >
                <CgCloseO className='mr-2 w-4 h-4' />
                Cancel
              </Button>
            </>
          ))}
      </div>
      {!isImgPosEditActive && (
        <div css={[tw`flex flex-col px-3 z-10 w-full`, isMob ? tw`max-w-full pt-8 pb-5` : tw`max-w-5xl`]}>
          <PreviewInput
            inputRef={heroTitleRef}
            name={inputNames.heroTitle}
            inputCss={[
              isMob ? tw`text-2xl` : tw`text-5xl leading-tight`,
              tw`font-bold tracking-wider min-h-[60px] text-white placeholder:text-gray-200`,
            ]}
            style={{ textShadow: '2px 2px rgba(0,0,0,.3)' }}
            value={heroHeading}
            maxLength={SALES_FIELDS_MAXLENGTH.hero.header}
            onChange={(_, value) => {
              const lines = value.split('\n')
              let adjustedValue = value
              if (lines.length > 2) {
                adjustedValue = lines.slice(0, 2).join('\n')
              }
              setHeroHeading(adjustedValue)
            }}
          />
          <PreviewInput
            inputRef={heroSubtitileRef}
            name={inputNames.heroSubtitle}
            inputCss={[
              isMob ? tw`text-lg max-w-full` : tw`text-2xl max-w-[60%]`,
              tw`mt-4 text-white placeholder:text-gray-200`,
            ]}
            style={{ textShadow: '2px 2px rgba(0,0,0,.3)' }}
            value={secondaryText}
            maxLength={SALES_FIELDS_MAXLENGTH.hero.subheader}
            onChange={(_, value) => setSecondaryText(value)}
          />
          <PreviewInput
            inputRef={heroBtnTextRef}
            name={inputNames.heroBtnText}
            inputType='button'
            inputCss={[isMob && tw`mt-5`, tw`ml-2 mt-16`, tw`placeholder:text-gray-200`]}
            value={heroCTA}
            maxLength={SALES_FIELDS_MAXLENGTH.hero.heroCTA}
            onChange={(_, value) => setHeroCTA(value)}
            style={{ backgroundColor: primaryColor }}
          />
        </div>
      )}
    </div>
  )
}

const heroBgOverlayClasses = tw`
  before:absolute 
  before:top-0 before:left-0 
  before:w-full before:h-full 
  before:bg-black before:opacity-20
`

const heroBgClasses = tw`
  relative flex 
  flex-col items-center 
  justify-center h-[68vh] 
  bg-cover overflow-hidden
`
