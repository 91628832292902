import React from 'react'
import tw from 'twin.macro'

import { FaqList } from '../FaqList/FaqList'

export default function FaqPreview({ coachOrgId, faqs, isMob }) {
  return (
    <div className='flex items-center justify-center py-5 bg-white w-full' css={[!isMob && tw`pb-20 pt-0`]}>
      <div css={tw`flex flex-col items-center justify-center w-full max-w-5xl mx-auto`}>
        <div css={tw`flex flex-col w-full`}>
          <h3 css={[tw`text-4xl font-bold mb-2 text-center`, isMob && tw`text-2xl`]}>FAQs</h3>
          <FaqList coachOrgId={coachOrgId} faqs={faqs || []} isMob={isMob} />
        </div>
      </div>
    </div>
  )
}
