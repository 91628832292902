import 'twin.macro'
import React, { useRef, useState } from 'react'
import { CgAdd, CgTrash, CgPen } from 'react-icons/cg'
import { isEmpty, startCase } from 'lodash'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import {
  useListenStripeResponseQuery,
  useSetCouponMutation,
  useSetStripeRequestMutation,
} from 'modules/ProductInfo/productInfoApi'

import { createUID } from 'common/utils/createUID'
import { getCouponDurationText, showDiscountByType } from 'modules/ProductInfo/utils/utils'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { Button } from 'common/components/Button/Button'
import { Spinner } from 'common/components/Spinner/Spinner'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { AddCouponForm } from '../CouponForm/AddCouponForm'
import { EditCouponForm } from '../CouponForm/EditCouponForm'

export default function CouponsList({ coachOrgId, coupons, loading }) {
  const [editDialogId, setEditDialogId] = useState(false)
  const [addCouponDialogOpen, setAddCouponDialogOpen] = useState(false)

  if (loading) {
    return <Spinner className='w-10 h-10 text-gray-100 m-auto' />
  }

  if (isEmpty(coupons)) {
    return (
      <div className='flex flex-col items-center justify-center h-auto bg-white px-6 py-4 rounded-xl'>
        <h3 className='font-bold text-2xl text-tBlack'>No coupons</h3>
        <div className='mt-4'>
          <Button size='md' onClick={() => setAddCouponDialogOpen(true)}>
            Add new coupon
          </Button>
          <Dialog open={addCouponDialogOpen} setOpen={setAddCouponDialogOpen}>
            <DialogContent header='Add coupon' overlayProps={{ 'data-no-dnd': true }}>
              <FormRefsControlProvider>
                <AddCouponForm
                  coachOrgId={coachOrgId}
                  closeForm={() => setAddCouponDialogOpen(false)}
                  coupons={coupons}
                />
              </FormRefsControlProvider>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col border-2 border-tGreen rounded-xl overflow-hidden'>
      {Object.values(coupons).map((coupon) => (
        <CouponListItem
          key={coupon.id}
          coachOrgId={coachOrgId}
          coupon={coupon}
          editDialogId={editDialogId}
          setEditDialogId={setEditDialogId}
        />
      ))}
      <Dialog open={addCouponDialogOpen} setOpen={setAddCouponDialogOpen}>
        <DialogTrigger
          className='flex items-center justify-center px-4 h-[56px] hover:bg-gray-100 cursor-pointer font-bold'
          onOpenCb={(e) => e.stopPropagation()}
        >
          <CgAdd className='w-5 h-5 mr-2' /> Add coupon
        </DialogTrigger>
        <DialogContent header='Add coupon' overlayProps={{ 'data-no-dnd': true }}>
          <FormRefsControlProvider>
            <AddCouponForm coachOrgId={coachOrgId} closeForm={() => setAddCouponDialogOpen(false)} coupons={coupons} />
          </FormRefsControlProvider>
        </DialogContent>
      </Dialog>
    </div>
  )
}

function CouponListItem({ coachOrgId, coupon, editDialogId, setEditDialogId }) {
  const { createAlert } = useAlert()

  const actionContainerRef = useRef(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const [setCoupon] = useSetCouponMutation()
  const [setStripeRequest] = useSetStripeRequestMutation()

  const { data: stripeResponse } = useListenStripeResponseQuery({ coachOrgId, responseId: coupon?.id })
  const stripeResponsePending = stripeResponse?.status === 'pending'
  const errorMessage = stripeResponse?.error

  const handleDeleteCoupon = async () => {
    const payload = {
      couponId: coupon?.id,
      stripeCouponId: coupon?.id,
    }

    if (coupon?.type === 'trialOnly') {
      await setCoupon({ coachOrgId, couponKey: coupon?.id, coupon: null })
    } else {
      const requestId = createUID()
      setStripeRequest({
        requestId,
        coachOrgId,
        type: 'DELETE_COUPON',
        payload,
      })
    }

    createAlert({ text: `Coupon deleted!`, type: 'success' })
  }

  const handleEditCoupon = async ({ formData, coupon }) => {
    const payload = {
      stripeCouponId: coupon.id,
      name: formData.name,
    }
    // If name did not change or trialOnly coupon, only firebase update is enough
    if (coupon.type === 'trialOnly' || coupon.name === formData.name) {
      await setCoupon({ coachOrgId, couponKey: coupon.id, coupon: { ...coupon, ...formData } })
    } else {
      await setCoupon({ coachOrgId, couponKey: coupon.id, coupon: { ...coupon, ...formData, stripeStatus: 'pending' } })
      const requestId = createUID()
      setStripeRequest({
        requestId,
        coachOrgId,
        type: 'UPDATE_COUPON',
        payload,
      })
    }
    setEditDialogId(false)
    createAlert({ text: `Coupon updated!`, type: 'success' })
  }

  const handleErrRetry = async () => {
    const payload = stripeResponse?.data?.payload
    const type = stripeResponse?.type
    const requestId = createUID()
    await setStripeRequest({
      requestId,
      coachOrgId,
      type,
      payload,
    })
    createAlert({ text: 'Retrying...', type: 'success' })
  }

  const handleErrDelete = async () => {
    await setCoupon({ coachOrgId, couponKey: coupon.id, coupon: null })
    createAlert({ text: 'Coupon deleted!', type: 'success' })
  }

  return (
    <div className='flex flex-col border-b-[1px] border-b-tGray-light last:border-b-0'>
      <div
        className='group relative flex items-start py-2 min-h-[56px] cursor-pointer hover:bg-gray-100'
        onClick={(e) => {
          if (Boolean(errorMessage)) {
            return
          }

          const occuredOutsideModal = e.currentTarget.contains(e.target)

          // Check that event did not happen in action row (edit/delete)
          const occuredOutsideActionRow = !actionContainerRef.current.contains(e.target)
          if (occuredOutsideModal && occuredOutsideActionRow) {
            setEditDialogId(coupon.id)
          }
        }}
      >
        <div className='flex flex-col w-5/12 px-2.5'>
          <p className='text-sm font-medium mb-1'>{coupon.name || 'Untitled coupon'}</p>
          <span className='text-xs font-medium text-gray-500'>{`ID: ${coupon.id}` || '(couponid-autogenerated)'}</span>
        </div>
        <div className='flex flex-col px-2.5 w-2/12'>
          <p className='text-sm font-medium mb-1'>Type</p>
          <span className='text-xs font-medium text-gray-500'>{startCase(coupon.type)}</span>
        </div>
        <div className='flex flex-col px-2.5 w-2/12'>
          <p className='text-sm font-medium mb-1'>Discount</p>
          <span className='text-xs font-medium text-gray-500'>
            {showDiscountByType({ type: coupon.type, discount: coupon.discount })}
          </span>
        </div>
        <div className='flex flex-col px-2.5 w-2/12'>
          <p className='text-sm font-medium mb-1'>Duration</p>
          <span className='text-xs font-medium text-gray-500'>{getCouponDurationText({ coupon })}</span>
        </div>
        {!errorMessage && (
          <div ref={actionContainerRef} className='absolute right-4 top-1/2 -translate-y-1/2 flex items-center'>
            {stripeResponsePending ? (
              <Spinner />
            ) : (
              <>
                <button
                  onClick={() => setEditDialogId(coupon.id)}
                  className='hidden group-hover:inline-block hover:text-tGreen transition-all disabled:cursor-not-allowed mr-2'
                >
                  <CgPen className='w-[18px] h-[18px]' />
                </button>
                <Dialog open={editDialogId === coupon.id} setOpen={setEditDialogId}>
                  <DialogContent header='Edit coupon'>
                    <FormRefsControlProvider>
                      <EditCouponForm coupon={coupon} handleEditCoupon={handleEditCoupon} />
                    </FormRefsControlProvider>
                  </DialogContent>
                </Dialog>
                <Dialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen}>
                  <DialogTrigger
                    className='hidden group-hover:inline-block hover:text-tRed transition-all disabled:cursor-not-allowed'
                    onOpenCb={(e) => e.stopPropagation()}
                  >
                    <CgTrash className='w-5 h-5' />
                  </DialogTrigger>
                  <DialogContent>
                    <DeleteDialogBanner
                      text={`This will delete the ${coupon.name || 'Untitled'} coupon`}
                      handleDelete={handleDeleteCoupon}
                    />
                  </DialogContent>
                </Dialog>
              </>
            )}
          </div>
        )}
      </div>
      {Boolean(errorMessage) && (
        <div className='text-xs mt-1 max-w-[90%] px-2.5 py-1'>
          <span className='text-tRed'>{errorMessage}</span>
          <button
            type='button'
            className='ml-2 underline hover:text-tGreen transition-colors'
            onClick={() => handleErrRetry()}
          >
            Retry
          </button>
          <span className='mx-2'>or</span>
          <button
            type='button'
            className='underline hover:text-tRed transition-colors'
            onClick={() => handleErrDelete()}
          >
            Delete coupon
          </button>
        </div>
      )}
    </div>
  )
}
