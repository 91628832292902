import tw from 'twin.macro'
import React, { useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'
import { CgInfo, CgCalendar, CgArrowRight } from 'react-icons/cg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Switch } from 'common/components/Switch/Switch'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { Input } from 'common/components/Input/Input'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { editCouponFormSchema } from './couponSchema'
import { dateInputClasses } from './AddCouponForm'

export function EditCouponForm({ handleEditCoupon, coupon, isPendingRequest }) {
  const defaultValues = {
    name: coupon.name,
    alwaysActive: Boolean(coupon?.alwaysActive),
    startDate: coupon?.startDate ? new Date(coupon.startDate) : new Date(Date.now()),
    expiration: coupon?.expiration ? new Date(coupon.expiration) : null,
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(editCouponFormSchema),
  })
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
  } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    handleEditCoupon({
      formData: {
        ...data,
        startDate: data.startDate?.getTime() || null,
        expiration: data.expiration?.getTime() || null,
      },
      coupon,
    })
  }

  // ref control
  const { addManyInputRefs, moveFocusOnKeyPress } = useFormRefsControl()
  const nameRef = useRef()
  const coupStartDateInputRef = useRef()
  const coupExpDateInputRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: nameRef, name: 'name' },
      { ref: { current: coupStartDateInputRef?.current?.input }, name: 'startDate' },
      { ref: { current: coupExpDateInputRef?.current?.input }, name: 'expiration' },
      { ref: submitRef, name: 'submit' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='couponForm'>
          <div className='flex flex-col px-10 py-4'>
            <div className='mb-2'>
              <div className='flex items-center mb-1'>
                <label htmlFor='id' className='inline-flex cursor-pointer font-semibold text-tBlack mr-1'>
                  Coupon name
                </label>
                <Tooltip
                  content='This name will be displayed on the Stripe checkout page'
                  triggerClasses='cursor-pointer text-gray-500 hover:text-tGreen'
                >
                  <CgInfo className='w-4 h-4' />
                </Tooltip>
              </div>
              <Input
                name='name'
                type='text'
                placeholder='Enter coupon name'
                register={register}
                inputRef={nameRef}
                error={errors?.name?.message}
                autoFocus={true}
              />
              {errors.name && <p className='flex text-xs mt-1 text-tRed'>{errors.name.message}</p>}
            </div>
          </div>
          <div className='flex flex-col px-10 py-6 border-t border-gray-200'>
            <div className='flex items-center mb-3'>
              <Switch
                onChange={() => {
                  clearErrors(['startDate', 'expiration'])
                  setValue('alwaysActive', !formState.alwaysActive)
                }}
                label='Always active'
                isChecked={formState.alwaysActive}
                labelClasses='!text-sm'
              />
            </div>
            <div className='flex items-end'>
              <div className='flex flex-col'>
                <span className='text-sm font-semibold mb-1.5'>Start</span>
                <DatePicker
                  name='startDate'
                  ref={coupStartDateInputRef}
                  selected={formState.startDate}
                  onChange={(date) => {
                    if (date) {
                      clearErrors('startDate')
                      setValue('startDate', date)
                    }
                  }}
                  maxDate={formState.expiration}
                  placeholderText='mm/dd/yyyy'
                  disabled={formState.alwaysActive}
                  css={[dateInputClasses, errors.startDate && tw`ring-red-500`]}
                  showIcon={true}
                  icon={
                    <CgCalendar
                      className='!w-5 !h-5 top-1/2 -translate-y-1/2'
                      onClick={() => coupStartDateInputRef?.current?.setOpen(true)}
                    />
                  }
                  popperProps={{
                    placement: 'bottom-start',
                    strategy: 'fixed',
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Tab') {
                      e.preventDefault()
                      e.stopPropagation()
                      coupStartDateInputRef?.current?.setOpen(false)
                      moveFocusOnKeyPress(e)
                    }
                  }}
                  onCalendarOpen={() => {
                    coupExpDateInputRef?.current?.setOpen(false)
                  }}
                />
              </div>
              <CgArrowRight className='w-6 h-6 text-tBlack mx-4 mb-2' />
              <div className='flex flex-col'>
                <span className='text-sm font-semibold mb-1.5'>End</span>
                <DatePicker
                  name='expiration'
                  ref={coupExpDateInputRef}
                  selected={formState.expiration}
                  onChange={(date) => {
                    if (date) {
                      clearErrors('expiration')
                      setValue('expiration', date)
                    }
                  }}
                  minDate={formState.startDate}
                  placeholderText='mm/dd/yyyy'
                  disabled={formState.alwaysActive}
                  css={[dateInputClasses, errors.expiration && tw`ring-red-500`]}
                  showIcon={true}
                  icon={
                    <CgCalendar
                      className='!w-5 !h-5 top-1/2 -translate-y-1/2'
                      onClick={() => coupExpDateInputRef?.current?.setOpen(true)}
                    />
                  }
                  popperProps={{
                    placement: 'bottom-start',
                    strategy: 'fixed',
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Tab') {
                      e.preventDefault()
                      e.stopPropagation()
                      coupExpDateInputRef?.current?.setOpen(false)
                      moveFocusOnKeyPress(e)
                    }
                  }}
                  onCalendarOpen={() => {
                    coupStartDateInputRef?.current?.setOpen(false)
                  }}
                />
              </div>
            </div>
            {errors.startDate && <p className='flex text-xs mt-1 text-tRed'>{errors.startDate.message}</p>}
            {errors.expiration && <p className='flex text-xs mt-1 text-tRed'>{errors.expiration.message}</p>}
          </div>
        </form>
      </div>
      <UpdateActions
        loading={isPendingRequest}
        handleSubmit={handleSubmit}
        disabled={isEqual(formState, defaultValues) || formState.name.trim() === ''}
        actionText='Edit'
        form='couponForm'
        ref={submitRef}
        hideDelete={true}
      />
    </FormProvider>
  )
}
