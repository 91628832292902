import { inputBase } from 'common/components/Input/Input'
import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'

export default function PriceInput({ inputRef, name, placeholder, value, onChange, className, error, autoFocus }) {
  const [rawVal, setRawVal] = useState(`${value}`.length < 3 ? `${value}`.padStart(3, '0') : `${value}`)
  const [inputVal, setInputVal] = useState(value)

  const handlePress = (e) => {
    if (e.code === 'Backspace' || e.code === 'Delete') {
      e.preventDefault()
      const newVal = rawVal.slice(0, -1)
      setRawVal(newVal.length < 3 ? newVal.padStart(3, '0') : newVal)
    } else {
      if (e.keyCode >= 48 && e.keyCode <= 57) {
        if (rawVal.split('')[0] === '0') {
          setRawVal(`${rawVal.slice(1)}${e.key}`)
        } else {
          setRawVal(`${rawVal}${e.key}`)
        }
      }
    }
  }

  useEffect(() => {
    const integer = rawVal.slice(0, -2)
    const decimal = rawVal.slice(-2)
    const intWithSeparators = addSeparationSignByThousands(integer)
    setInputVal(`${intWithSeparators}.${decimal}`)
    onChange(rawVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawVal])

  return (
    <div className='relative'>
      <span className='text-lg absolute left-4 top-1/2 -translate-y-1/2'>$</span>
      <StyledInput
        id={name}
        ref={inputRef}
        value={inputVal}
        placeholder={placeholder}
        className={className}
        onChange={() => {}} // to avoid console error of uncontrollable input
        error={error}
        onKeyDown={handlePress}
        autoFocus={autoFocus}
      />
      <div className='flex items-center justify-center absolute right-0 top-1/2 -translate-y-1/2 w-18 h-full py-1.5 px-4 border-l-[1px] border-gray-300 bg-white rounded-tr-xl rounded-br-xl'>
        <span className='text-base font-medium'>USD</span>
      </div>
    </div>
  )
}

const StyledInput = styled.input(({ error, hideRing }) => [
  inputBase,
  tw`pl-8 pr-20`,
  hideRing && tw`ring-0 focus:ring-0`,
  error && tw`ring-tRed focus:ring-tRed`,
])

const addSeparationSignByThousands = (rawValue) => {
  if (rawValue.length > 3) {
    let withSeparation = rawValue.split('').reverse().join('').replace(/.{3}/g, '$&,')
    if (withSeparation.split('').at(-1) === ',') {
      withSeparation = withSeparation.slice(0, -1)
    }
    return withSeparation.split('').reverse().join('')
  }

  return rawValue
}
