import * as yup from 'yup'
import { emptyStringToNull, trimString } from 'common/utils/yupSchemaUtils'

export const schema = yup
  .object({
    title: yup.string().max(60, 'SEO title should be fewer than 60 characters').transform(trimString),
    description: yup.string().max(160, 'SEO description should be fewer than 160 characters').transform(trimString),
    previewImg: yup.string().nullable().transform(emptyStringToNull),
  })
  .required()
