import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CgArrowRight, CgPen } from 'react-icons/cg'

import tw, { css } from 'twin.macro'

import { useEventListener } from 'common/hooks/useEventListener'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { CHECKOUT_FIELDS_MAXLENGTH } from 'modules/PageBuilder/constants/checkoutConstants'
import { buttonBase } from 'common/components/Button/Button'
import { BenefitsInputList } from '../BenefitsList/BenefitsList'
import PreviewInput from '../Inputs/PreviewInput'
import ImageUpload from '../Inputs/ImageUpload'
import { DisplayModeSwitch } from '../DisplayModeSwitch/DisplayModeSwitch'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'

import logo from 'assets/images/TrybeLogoWhite.svg'
import appStore from 'assets/images/download-app-store.svg'
import googlePlay from 'assets/images/download-google-play.svg'
import {
  useUpdateCheckoutHeroMainTextMutation,
  useUpdateCheckoutHeroMutation,
  useUpdateCheckoutPageSeoMutation,
} from 'modules/PageBuilder/pageBuilderApi'
import { SignupPreview } from './SignupPreview'
import {
  getPlanPromoBottomText,
  getPlanPromoPrice,
  getPlanPromoIntervalText,
  getPlanIntervalText,
  getIsCouponActive,
} from 'modules/ProductInfo/utils/utils'
import { SeoForm } from '../SeoForm/SeoForm'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'

export const inputRefsSortMethod = ['heroTitle', 'benefit', 'addBenefitBtn']

export default function CheckoutPreview({ coachOrgId, page, productPlans, seo, coupons }) {
  const navigate = useNavigate()
  const [heroMainText, setHeroMainText] = useState(page?.heroSection?.mainText || '')
  const [displayMode, setDisplayMode] = useState('desktop')
  const isMob = displayMode === 'mobile'

  const [updateHero] = useUpdateCheckoutHeroMutation()
  const [updateSeo] = useUpdateCheckoutPageSeoMutation()

  const { data: isImgUploading } = useListenExistingItemDraftsQuery({ coachOrgId, id: 'checkout-hero-image' })

  const [updateCheckoutHeroMainText] = useUpdateCheckoutHeroMainTextMutation()
  const debouncedHeroMainText = useDebounce(heroMainText, 500)
  useDebouncedTextMutation({
    stateText: heroMainText,
    dbText: page?.heroSection?.mainText || '',
    mutation: updateCheckoutHeroMainText,
    debouncedStateText: debouncedHeroMainText,
    mutationArgs: {
      coachOrgId,
      text: debouncedHeroMainText,
    },
  })

  const clearHeroImage = async () => {
    await updateHero({ coachOrgId, image: null })
  }

  const { addInputRef, moveFocusOnKeyPress } = useFormRefsControl()
  const heroTitleRef = useRef()
  useEffect(() => {
    addInputRef({ ref: heroTitleRef, name: 'heroTitle', sortMethod: inputRefsSortMethod })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', moveFocusOnKeyPress)

  return (
    <>
      <div css={tw`flex justify-between items-end mb-2 w-full`}>
        <div className='w-1/3 h-[76px]' />
        <div className='flex justify-center w-1/3'>
          <DisplayModeSwitch displayMode={displayMode} setDisplayMode={setDisplayMode} />
        </div>
        <div className='flex flex-col justify-center mb-3 items-end w-1/3'>
          <Dialog>
            <DialogTrigger css={[buttonBase]} className='text-tGray-med hover:text-tGreen mb-3'>
              <CgPen className='w-4 h-4 mr-1' />
              Edit SEO metadata
            </DialogTrigger>
            <DialogContent header='Checkout page SEO'>
              <FormRefsControlProvider>
                <SeoForm coachOrgId={coachOrgId} seo={seo} updateSeo={updateSeo} uploadLocation='siteCheckout' />
              </FormRefsControlProvider>
            </DialogContent>
          </Dialog>
          <div className='flex items-center'>
            <button
              css={[buttonBase]}
              className='text-tGray-med hover:text-tGreen'
              onClick={() => navigate('/pagebuilder/sales')}
            >
              Sales page
            </button>
            <CgArrowRight className='w-5 h-5 mx-2 text-tGray-med' />
            <button
              css={[buttonBase]}
              className='underline underline-offset-8 decoration-2 decoration-tGreen text-tGray-dark'
            >
              Checkout page
            </button>
          </div>
        </div>
      </div>
      <div css={[tw`flex flex-col w-full`, isMob ? tw`max-w-sm` : tw`h-[850px]`]}>
        <div className='flex items-center px-2 py-4 bg-black'>
          <img src={logo} alt='logo' className='w-8 h-8' />
          <span css={[tw`text-3xl text-white uppercase ml-2 leading-none tracking-widest`, isMob && tw`text-xl`]}>
            TRYBE
          </span>
        </div>
        <div className='flex flex-1 h-full' css={[isMob && tw`flex-col`]}>
          <div
            css={[
              heroBgClasses,
              page.heroImage &&
                css`
                  background-image: url(${page.heroImage});
                `,
              isMob && tw`hidden`,
            ]}
            className='group'
          >
            <div
              css={[tw`invisible absolute top-5 right-5 z-20`, isImgUploading && tw`visible`]}
              className='group-hover:visible'
            >
              <ImageUpload
                coachOrgId={coachOrgId}
                id='checkout-hero-image'
                uploadType='checkout-hero-image'
                clearImage={clearHeroImage}
              />
            </div>
            <div className='flex flex-col items-center z-10 w-full'>
              <PreviewInput
                inputRef={heroTitleRef}
                name='heroTitle'
                inputCss={[
                  tw`min-h-[60px] text-center text-white text-5xl font-bold mb-12 placeholder:text-gray-200`,
                  isMob && tw`text-2xl`,
                ]}
                style={{ textShadow: '2px 2px rgba(0,0,0,.3)' }}
                value={heroMainText}
                maxLength={CHECKOUT_FIELDS_MAXLENGTH.appTitle}
                onChange={(_, value) => {
                  const lines = value.split('\n')
                  let adjustedValue = value
                  if (lines.length > 2) {
                    adjustedValue = lines.slice(0, 2).join('\n')
                  }
                  setHeroMainText(adjustedValue)
                }}
              />
              <div className='flex items-center'>
                <img src={appStore} alt='app store download' />
                <img src={googlePlay} alt='google play download' className='ml-2.5' />
              </div>
            </div>
          </div>
          <div
            className='flex flex-col shrink-0 bg-white'
            css={[isMob ? tw`w-full py-4` : tw`w-[475px] py-6 overflow-y-scroll`]}
          >
            <div className='flex flex-col mb-10'>
              <h3
                className='text-tGreen font-bold mb-3 cursor-not-allowed'
                css={[isMob ? tw`mx-6 text-xl` : tw`mx-8 text-2xl`]}
              >
                1. Select your plan
              </h3>
              <div className='mb-5' css={[isMob ? tw`mx-6` : tw`mx-8`]}>
                <div className='flex flex-col border-2 border-tGreen rounded-xl overflow-hidden min-h-[56px]'>
                  {productPlans.length < 1 ? (
                    <div className='px-4 font-bold flex flex-1 items-center'>No pricing plans</div>
                  ) : (
                    productPlans.map((plan) => <Plan key={plan.id} plan={plan} coupons={coupons} />)
                  )}
                </div>
                <button
                  type='button'
                  onClick={() => navigate('/pricing')}
                  className='italic text-xs text-gray-400 underline underline-offset-2 hover:text-tGreen'
                >
                  Click here to manage your plans.
                </button>
              </div>
              <div className='flex flex-col'>
                <span className='text-lg font-bold mb-1 cursor-not-allowed' css={[isMob ? tw`px-6` : tw`px-8`]}>
                  What you get
                </span>
                <BenefitsInputList coachOrgId={coachOrgId} benefits={page.benefits || []} isMob={isMob} />
              </div>
            </div>
            <div className='flex flex-col mb-10' css={[isMob ? tw`px-6` : tw`px-8`]}>
              <h3 className='text-black font-bold mb-3 cursor-not-allowed' css={[isMob ? tw`text-xl` : tw`text-2xl`]}>
                2. Create a Trybe account
              </h3>
              <SignupPreview />
            </div>
            <div className='flex flex-col' css={[isMob ? tw`px-6` : tw`px-8`]}>
              <h3 className='text-black font-bold mb-3 cursor-not-allowed' css={[isMob ? tw`text-xl` : tw`text-2xl`]}>
                3. Enter billing info
              </h3>
              <div className='flex items-center justify-center text-white w-full rounded-lg bg-black py-4 font-bold cursor-not-allowed'>
                Pay
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Plan({ plan, coupons }) {
  const autoAppliedPromo = plan.promo?.find(
    (prm) => getIsCouponActive({ promo: prm, coupon: coupons?.[prm?.stripeCouponId] }) && prm.isAutoApplied
  )
  const autoAppliedCoupon = coupons?.[autoAppliedPromo?.stripeCouponId]
  const planText =
    autoAppliedCoupon && autoAppliedCoupon?.type !== 'trialOnly'
      ? getPlanPromoIntervalText(plan)
      : getPlanIntervalText(plan)
  return (
    <div
      className='group'
      css={[planContainerClasses, autoAppliedCoupon && autoAppliedCoupon?.type !== 'trialOnly' && tw`pt-6 pb-3`]}
    >
      <div className='relative flex items-center'>
        {autoAppliedCoupon && autoAppliedCoupon?.type !== 'trialOnly' && (
          <span className='text-xl font-bold'>
            ${(getPlanPromoPrice({ coupon: autoAppliedCoupon, plan }) / 100).toFixed(2)} USD
          </span>
        )}
        <span
          css={[
            tw`text-xl font-bold`,
            autoAppliedCoupon &&
              autoAppliedCoupon?.type !== 'trialOnly' &&
              tw`flex justify-end absolute left-0 -top-3 text-xs line-through`,
          ]}
          style={{
            textDecorationColor: autoAppliedCoupon ? '#ef4444' : undefined,
          }}
        >
          ${(plan.price / 100).toFixed(2)} USD
        </span>
        <span className='ml-1.5'>{planText}</span>
      </div>
      <span className='text-xs font-medium'>
        {autoAppliedCoupon ? getPlanPromoBottomText({ coupon: autoAppliedCoupon, plan }) : plan.promoText}
      </span>
    </div>
  )
}

const planContainerClasses = tw`
  flex flex-col 
  justify-center p-4 
  border-b border-b-gray-300 
  last:border-b-0 min-h-[56px] 
  hover:text-tGreen transition-all
`

const heroBgClasses = tw`
  relative flex p-10 flex-1 
  flex-col items-center 
  justify-center overflow-hidden 
  bg-cover bg-center before:absolute 
  before:top-0 before:left-0 
  before:w-full before:h-full 
  before:bg-black before:opacity-20
`
