import tw from 'twin.macro'
import React, { useEffect, useRef, useState } from 'react'
import { CgInfo } from 'react-icons/cg'

import { useListenStripeProductResponseQuery, useSetStripeRequestMutation } from 'modules/ProductInfo/productInfoApi'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { Button } from 'common/components/Button/Button'
import { Input } from 'common/components/Input/Input'
import { Tooltip } from 'common/components/Tooltip/Tooltip'

import { createUID } from 'common/utils/createUID'

export function ProductInfoForm({ coachOrgId, stripeProductId, stripeProductName }) {
  const { createAlert } = useAlert()
  const [productName, setProductName] = useState(stripeProductName)
  const [requestId, setRequestId] = useState(null)

  const [setStripeRequest] = useSetStripeRequestMutation()

  const { data: stripeResponse } = useListenStripeProductResponseQuery({ coachOrgId })
  const stripeResponsePending = stripeResponse?.requestKey === requestId && stripeResponse?.status === 'pending'
  const stripeResponseSuccess = stripeResponse?.requestKey === requestId && stripeResponse?.status === 'success'
  const stripeResponseError = stripeResponse?.requestKey === requestId && stripeResponse?.status === 'error'

  const isUpdateDisabled = !productName || productName === stripeProductName || productName?.trim().length === 0
  const isUpdating = requestId || stripeResponsePending

  const handleCreate = async () => {
    const uid = createUID()
    setRequestId(uid)
    await setStripeRequest({
      requestId: uid,
      coachOrgId,
      type: 'UPDATE_PRODUCT',
      payload: {
        stripeProductId,
        name: productName,
      },
    })
  }

  useEffect(() => {
    setProductName(stripeProductName)
  }, [stripeProductName])

  useEffect(() => {
    if (stripeResponseError) {
      setRequestId(null)
      const errorMsg = stripeResponse?.errorMsg || 'Info update failed!'
      createAlert({ text: errorMsg, type: 'danger' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeResponseError])

  useEffect(() => {
    if (stripeResponseSuccess) {
      setRequestId(null)
      createAlert({ text: 'Info updated!', type: 'success' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeResponseSuccess])

  const { addInputRef, moveFocusOnKeyPress } = useFormRefsControl()
  const productNameRef = useRef()
  const updateBtnRef = useRef()
  const inputRefsSortMethod = ['productName', 'updateBtn']
  useEffect(() => {
    addInputRef({ ref: productNameRef, name: 'productName', sortMethod: inputRefsSortMethod })
    addInputRef({
      ref: updateBtnRef,
      name: 'updateBtn',
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', moveFocusOnKeyPress)

  return (
    <>
      <div className='self-start mb-4 bg-gray-100 rounded-md p-2 text-xs'>
        <span className='font-semibold text-tBlack'>Stripe product id:</span>
        <span className='ml-1'>{stripeProductId}</span>
      </div>
      <div className='mb-6'>
        <div className='flex items-center mb-1'>
          <label htmlFor='productName' className='font-semibold text-tBlack cursor-pointer mr-1'>
            Stripe product name
          </label>
          <Tooltip
            content='This name will be displayed in your Stripe checkout page'
            triggerClasses='cursor-pointer text-gray-500 hover:text-tGreen'
          >
            <CgInfo className='w-4 h-4' />
          </Tooltip>
        </div>
        <Input
          inputRef={productNameRef}
          name='productName'
          placeholder='Enter your Stripe product name'
          value={productName}
          onChange={(e) => {
            setProductName(e.target.value)
          }}
          disabled={isUpdating}
          autoFocus={true}
        />
      </div>
      <Button
        ref={updateBtnRef}
        onClick={handleCreate}
        disabled={isUpdateDisabled}
        loading={isUpdating}
        css={tw`ml-auto`}
      >
        Update
      </Button>
    </>
  )
}
