import tw from 'twin.macro'
import React, { useEffect, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { CgInfo, CgPen, CgUndo, CgCalendar, CgArrowRight } from 'react-icons/cg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { useSetCouponMutation, useSetStripeRequestMutation } from 'modules/ProductInfo/productInfoApi'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'

import { Switch } from 'common/components/Switch/Switch'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { Input } from 'common/components/Input/Input'
import { createUID } from 'common/utils/createUID'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import DurationInput from './DurationInput'
import { addCouponFormSchema } from './couponSchema'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import PriceInput from '../PricingForm/PriceInput'
import { MAX_TRIAL_DAYS } from '../../constants/trialConstants'

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack`
const InputError = tw.p`flex text-xs mt-1 text-tRed`

function generateCouponId() {
  var baseId = createUID()
  //Remove hyphens and underscore
  baseId = baseId.replace(/[-_]/g, '')
  //Get last 8 characters
  return baseId.slice(-8)
}

export function AddCouponForm({ coachOrgId, coupons, closeForm }) {
  const { createAlert } = useAlert()

  const couponValues = Object.values(coupons)
  const existingCouponIds = couponValues.map((coupon) => coupon.id)
  const [isEditingTrialDays, setIsEditingTrialDays] = useState(false)
  const [discountErr, setDiscountErr] = useState(null)
  const [durInMonthsErr, setDurInMonthsErr] = useState(null)
  const [trialPeriodErr, setTrialPeriodErr] = useState(null)

  const [setCoupon] = useSetCouponMutation()
  const [setStripeRequest] = useSetStripeRequestMutation()

  const defaultValues = {
    id: '',
    name: 'Coupon',
    type: 'percentage',
    discount: 5,
    duration: 'once',
    durationInMonths: null,
    trialPeriodDays: null,
    alwaysActive: false,
    startDate: new Date(Date.now()),
    expiration: null,
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(addCouponFormSchema),
    context: {
      existingCouponIds,
    },
  })

  const {
    watch,
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = methods

  const formState = watch()

  const handleAddCoupon = async ({ formData }) => {
    const couponId = formData?.id || generateCouponId()
    const payload = {
      stripeCouponId: couponId,
      name: formData.name,
      percentOff: formData.type === 'percentage' ? formData.discount : null,
      amountOff: formData.type === 'fixed' ? formData.discount : null,
      duration: formData.duration,
      durationInMonths: formData.durationInMonths || null,
    }

    await setCoupon({
      coachOrgId,
      couponKey: couponId,
      coupon: { ...formData, stripeStatus: formData?.type !== 'trialOnly' ? 'pending' : 'success', id: couponId },
    })
    if (formData?.type !== 'trialOnly') {
      const requestId = generateCouponId()
      setStripeRequest({
        requestId,
        coachOrgId,
        type: 'CREATE_COUPON',
        payload,
      })
    }
    createAlert({ text: `Coupon created!`, type: 'success' })
    closeForm()
  }

  const onSubmit = async (data) => {
    if (discountErr || durInMonthsErr || trialPeriodErr) {
      return
    }

    if (
      formState.type === 'percentage' &&
      formState.discount === 100 &&
      (formState.duration === 'forever' || formState.duration === 'repeating')
    ) {
      setDiscountErr('Discount must be less than 100 percent for forever or multiple month duration coupons')
      return
    }

    if (formState.type === 'trialOnly' && !Number(formState?.trialPeriodDays)) {
      setTrialPeriodErr('Trial period must be at least 1 day')
      return
    }

    if (Number(formState?.trialPeriodDays) && Number(formState?.trialPeriodDays) > MAX_TRIAL_DAYS) {
      setTrialPeriodErr(`Trial period must be less than ${MAX_TRIAL_DAYS} days`)
      return
    }

    handleAddCoupon({
      formData:
        data?.type === 'trialOnly'
          ? {
              ...data,
              discount: null,
              duration: null,
              durationInMonths: null,
              startDate: data.startDate?.getTime() || null,
              expiration: data.expiration?.getTime() || null,
            }
          : { ...data, startDate: data.startDate?.getTime() || null, expiration: data.expiration?.getTime() || null },
    })
  }

  const handleChangeTypes = (e) => {
    setTrialPeriodErr(null)
    setDiscountErr(null)
    setValue('trialPeriodDays', null)
    if (e.target.value === 'fixed') {
      setIsEditingTrialDays(false)
      setValue('type', 'fixed')
      setValue('discount', 500)
    } else if (e.target.value === 'percentage') {
      setIsEditingTrialDays(false)
      setValue('type', 'percentage')
      setValue('discount', 5)
    } else {
      setIsEditingTrialDays(true)
      setValue('discount', null)
      setValue('durationInMonths', null)
      setValue('type', 'trialOnly')
      setValue('trialPeriodDays', 7)
    }
  }

  const handleDiscountChange = (e) => {
    const currTypedChar = e.nativeEvent.data
    const isCurrCharNumber = /[0-9]/g.test(currTypedChar)
    const isCurrCharBackspace = currTypedChar === null
    if (!isCurrCharNumber && !isCurrCharBackspace) {
      return
    }

    const percVal = Number(e.target.value) > 100 ? 100 : Number(e.target.value)

    if (formState.type === 'percentage' && percVal === 0) {
      setDiscountErr('Discount must be more than 0 percent')
    } else if (
      formState.type === 'percentage' &&
      percVal === 100 &&
      (formState.duration === 'forever' || formState.duration === 'repeating')
    ) {
      setDiscountErr('Discount must be less than 100 percent for forever or multiple month duration coupons')
    } else {
      setDiscountErr(null)
    }

    setValue('discount', percVal)
  }

  // refs control
  const inputRefsSortMethod = ['id', 'name', 'discount', 'duration', 'durationInMonths', 'trialPeriodDays', 'submit']
  const { addManyInputRefs, addInputRef, removeInputRef, moveFocusOnKeyPress } = useFormRefsControl()
  const idRef = useRef()
  const nameRef = useRef()
  const coupStartDateInputRef = useRef()
  const coupExpDateInputRef = useRef()
  const discountRef = useRef()
  const durationRef = useRef()
  const durationInMonthsRef = useRef()
  const trialPeriodDaysRef = useRef()
  const submitRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: idRef, name: 'id', sortMethod: inputRefsSortMethod },
      { ref: nameRef, name: 'name', sortMethod: inputRefsSortMethod },
      { ref: { current: coupStartDateInputRef?.current?.input }, name: 'startDate' },
      { ref: { current: coupExpDateInputRef?.current?.input }, name: 'expiration' },
      { ref: discountRef, name: 'discount', sortMethod: inputRefsSortMethod },
      { ref: durationRef, name: 'duration', sortMethod: inputRefsSortMethod },
      { ref: trialPeriodDaysRef, name: 'trialPeriodDays', sortMethod: inputRefsSortMethod },
      { ref: submitRef, name: 'submit', sortMethod: inputRefsSortMethod },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      formState.type === 'percentage' &&
      formState.discount === 100 &&
      (formState.duration === 'forever' || formState.duration === 'repeating')
    ) {
      setDiscountErr('Discount must be less than 100 percent for forever or multiple month duration coupons')
    } else {
      setDiscountErr(null)
    }

    if (formState.duration === 'repeating') {
      addInputRef({ ref: durationInMonthsRef, name: 'durationInMonths', sortMethod: inputRefsSortMethod })
    } else {
      removeInputRef(durationInMonthsRef, 'durationInMonths')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.duration])

  useEventListener('keydown', (e) => moveFocusOnKeyPress(e, handleSubmit(onSubmit)))
  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form onSubmit={handleSubmit(onSubmit)} id='couponForm' name='Coupon form'>
          <div className='flex flex-col px-10 py-4'>
            <div className='mb-2'>
              <div className='flex items-center mb-1'>
                <Label htmlFor='id' tw='mr-1'>
                  Coupon id
                </Label>
                <Tooltip
                  content='This is the coupon code users will enter in your checkout page'
                  triggerClasses='cursor-pointer text-gray-500 hover:text-tGreen'
                >
                  <CgInfo className='w-4 h-4' />
                </Tooltip>
              </div>
              <Input
                name='id'
                placeholder='Enter coupon id (optional)'
                inputRef={idRef}
                error={errors.id?.message}
                value={formState.id}
                register={register}
                registerOptions={{
                  onChange: (e) => {
                    const cleanedCouponId = e.target.value.replace(/\s/g, '') // remove all spaces
                    setValue('id', cleanedCouponId)
                  },
                }}
                autoFocus={true}
              />
              {errors && errors.id && <InputError>{errors.id.message}</InputError>}
            </div>
          </div>
          <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
            <div className='mb-2'>
              <div className='flex items-center mb-1'>
                <Label htmlFor='name' tw='mr-1'>
                  Name
                </Label>
                <Tooltip
                  content='This name will be displayed on the Stripe checkout page'
                  triggerClasses='cursor-pointer text-gray-500 hover:text-tGreen'
                >
                  <CgInfo className='w-4 h-4' />
                </Tooltip>
              </div>
              <Input
                name='name'
                placeholder='Enter coupon name'
                register={register}
                inputRef={nameRef}
                error={errors.name?.message}
              />
              {errors && errors.name && <InputError>{errors.name.message}</InputError>}
            </div>
          </div>
          <div className='flex flex-col px-10 py-6 border-t border-gray-200'>
            <div className='flex items-center mb-3'>
              <Switch
                onChange={() => {
                  clearErrors(['startDate', 'expiration'])
                  setValue('alwaysActive', !formState.alwaysActive)
                }}
                label='Always active'
                isChecked={formState.alwaysActive}
                labelClasses='!text-sm'
              />
            </div>
            <div className='flex items-end'>
              <div className='flex flex-col'>
                <span className='text-sm font-semibold mb-1.5'>Start</span>
                <DatePicker
                  name='startDate'
                  ref={coupStartDateInputRef}
                  selected={formState.startDate}
                  onChange={(date) => {
                    if (date) {
                      clearErrors('startDate')
                      setValue('startDate', date)
                    }
                  }}
                  maxDate={formState.expiration}
                  placeholderText='mm/dd/yyyy'
                  disabled={formState.alwaysActive}
                  css={[dateInputClasses, errors.startDate && tw`ring-red-500`]}
                  showIcon={true}
                  icon={
                    <CgCalendar
                      className='!w-5 !h-5 top-1/2 -translate-y-1/2'
                      onClick={() => coupStartDateInputRef?.current?.setOpen(true)}
                    />
                  }
                  popperProps={{
                    placement: 'bottom-start',
                    strategy: 'fixed',
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Tab') {
                      e.preventDefault()
                      e.stopPropagation()
                      coupStartDateInputRef?.current?.setOpen(false)
                      moveFocusOnKeyPress(e)
                    }
                  }}
                  onCalendarOpen={() => {
                    coupExpDateInputRef?.current?.setOpen(false)
                  }}
                />
              </div>
              <CgArrowRight className='w-6 h-6 text-tBlack mx-4 mb-2' />
              <div className='flex flex-col'>
                <span className='text-sm font-semibold mb-1.5'>End</span>
                <DatePicker
                  name='expiration'
                  ref={coupExpDateInputRef}
                  selected={formState.expiration}
                  onChange={(date) => {
                    if (date) {
                      clearErrors('expiration')
                      setValue('expiration', date)
                    }
                  }}
                  minDate={formState.startDate}
                  placeholderText='mm/dd/yyyy'
                  disabled={formState.alwaysActive}
                  css={[dateInputClasses, errors.expiration && tw`ring-red-500`]}
                  showIcon={true}
                  icon={
                    <CgCalendar
                      className='!w-5 !h-5 top-1/2 -translate-y-1/2'
                      onClick={() => coupExpDateInputRef?.current?.setOpen(true)}
                    />
                  }
                  popperProps={{
                    placement: 'bottom-start',
                    strategy: 'fixed',
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Tab') {
                      e.preventDefault()
                      e.stopPropagation()
                      coupExpDateInputRef?.current?.setOpen(false)
                      moveFocusOnKeyPress(e)
                    }
                  }}
                  onCalendarOpen={() => {
                    coupStartDateInputRef?.current?.setOpen(false)
                  }}
                />
              </div>
            </div>
            {errors.startDate && <p className='flex text-xs mt-1 text-tRed'>{errors.startDate.message}</p>}
            {errors.expiration && <p className='flex text-xs mt-1 text-tRed'>{errors.expiration.message}</p>}
          </div>
          <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
            <div className='mb-2'>
              <Label htmlFor='type' tw='mb-1'>
                Type
              </Label>
              <div className='flex flex-col'>
                <div className='flex items-center mb-1'>
                  <input
                    {...register('type')}
                    type='radio'
                    id='percentage'
                    value='percentage'
                    className='checked:bg-tGreen checked:hover:bg-tGreen checked:focus:bg-tGreen focus:ring-tGreen'
                    onChange={handleChangeTypes}
                  />
                  <label htmlFor='percentage' className='ml-3 cursor-pointer'>
                    Percentage discount
                  </label>
                </div>
                <div className='flex items-center mb-1'>
                  <input
                    {...register('type')}
                    type='radio'
                    id='fixed'
                    value='fixed'
                    className='checked:bg-tGreen checked:hover:bg-tGreen checked:focus:bg-tGreen focus:ring-tGreen'
                    onChange={handleChangeTypes}
                  />
                  <label htmlFor='fixed' className='ml-3 cursor-pointer'>
                    Fixed amount discount
                  </label>
                </div>{' '}
                <div className='flex items-center'>
                  <input
                    {...register('type')}
                    type='radio'
                    id='trialOnly'
                    value='trialOnly'
                    className='checked:bg-tGreen checked:hover:bg-tGreen checked:focus:bg-tGreen focus:ring-tGreen'
                    onChange={handleChangeTypes}
                  />
                  <label htmlFor='trialOnly' className='ml-3 cursor-pointer'>
                    Trial period only
                  </label>
                </div>
              </div>
              {errors && errors.type && <InputError>{errors.type.message}</InputError>}
            </div>
          </div>
          {formState.type !== 'trialOnly' && (
            <>
              <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
                <div className='mb-2'>
                  <Label htmlFor='discount' tw='mb-1'>
                    Discount
                  </Label>
                  {formState.type === 'fixed' && (
                    <PriceInput
                      name='discount'
                      placeholder='Enter discount value'
                      value={formState.discount}
                      onChange={(price) => {
                        if (Number(price) === 0) {
                          setDiscountErr('Discount should be at least $0.01')
                        } else {
                          setDiscountErr(null)
                          setValue('discount', Number(price))
                        }
                      }}
                    />
                  )}
                  {formState.type === 'percentage' && (
                    <div className='relative flex items-center'>
                      <Input
                        name='discount'
                        placeholder='Enter discount value'
                        inputRef={discountRef}
                        error={discountErr}
                        value={formState.discount}
                        onChange={handleDiscountChange}
                      />
                      <div className='flex items-center justify-center absolute right-0 top-1/2 -translate-y-1/2 w-16 h-full py-1.5 px-4 border-l-[1px] border-gray-300 bg-white rounded-tr-xl rounded-br-xl'>
                        <span className='text-base font-medium'>%</span>
                      </div>
                    </div>
                  )}
                  {discountErr && <InputError>{discountErr}</InputError>}
                </div>
              </div>
              <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
                <div className='mb-2'>
                  <Label htmlFor='duration' tw='mb-1'>
                    Duration
                  </Label>
                  <DurationInput inputRef={durationRef} value={formState.duration} setValue={setValue} />
                  {errors && errors.duration && <InputError>{errors.duration.message}</InputError>}
                </div>
              </div>
              {formState.duration === 'repeating' && (
                <div className='flex flex-col px-10 pb-4'>
                  <div className='mb-2'>
                    <Label htmlFor='durationInMonths' tw='mb-1'>
                      Number of months
                    </Label>
                    <Input
                      inputRef={durationInMonthsRef}
                      autoFocus={true}
                      name='durationInMonths'
                      placeholder='Enter duration in months'
                      error={durInMonthsErr}
                      register={register}
                      registerOptions={{
                        onChange: (e) => {
                          const value = parseInt(e.target.value) || ''
                          if (value < 1) {
                            setDurInMonthsErr('Number of months must be more than 0')
                          } else {
                            setDurInMonthsErr(null)
                          }
                          setValue('durationInMonths', value)
                        },
                      }}
                      type='number'
                      min={1}
                    />
                    {durInMonthsErr && <InputError>{durInMonthsErr}</InputError>}
                  </div>
                </div>
              )}
            </>
          )}
          <div className='flex flex-col px-10 py-4 border-t border-gray-200'>
            <div className='mb-2'>
              <Label htmlFor='trialPeriodDays' tw='mb-1'>
                Trial period in days
              </Label>
              <div className='relative'>
                <Input
                  inputRef={trialPeriodDaysRef}
                  disabled={!isEditingTrialDays}
                  type='number'
                  placeholder={!isEditingTrialDays ? 'Use plan trial period' : ''}
                  name='trialPeriodDays'
                  register={register}
                  min={1}
                  registerOptions={{
                    onChange: (e) => {
                      const value = parseInt(e.target.value) || ''
                      if (value < 1) {
                        setTrialPeriodErr('Trial period must be at least 1 day')
                      } else if (Boolean(value) && value > MAX_TRIAL_DAYS) {
                        setTrialPeriodErr(`Trial period must be less than ${MAX_TRIAL_DAYS} days`)
                      } else {
                        setTrialPeriodErr(null)
                      }
                      setValue('trialPeriodDays', value)
                    },
                  }}
                  error={trialPeriodErr}
                  className='number-input-no-arrows placeholder:!text-opacity-100'
                />
                {formState.type !== 'trialOnly' && (
                  <div
                    className='absolute z-50 flex items-center justify-end w-8 h-14 right-0 rounded-xl top-1/2 -translate-y-1/2'
                    css={[isEditingTrialDays ? tw`bg-white` : tw`bg-transparent`]}
                  >
                    {isEditingTrialDays ? (
                      <Tooltip
                        content='Set back to default'
                        triggerClasses='cursor-pointer text-gray-500 hover:text-tGreen mr-4'
                      >
                        <CgUndo
                          onClick={() => {
                            setValue('trialPeriodDays', null)
                            setIsEditingTrialDays(false)
                            setTrialPeriodErr(null)
                          }}
                          className='w-5 h-5'
                        />
                      </Tooltip>
                    ) : (
                      <CgPen
                        onClick={() => {
                          setIsEditingTrialDays(true)
                          setValue('trialPeriodDays', 7)
                          setTimeout(() => {
                            trialPeriodDaysRef?.current?.focus()
                          }, 50)
                        }}
                        className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 hover:text-tGreen w-4 h-4'
                      />
                    )}
                  </div>
                )}
              </div>
              {trialPeriodErr && <InputError>{trialPeriodErr}</InputError>}
            </div>
          </div>
        </form>
      </div>
      <UpdateActions actionText='Add coupon' hideDelete={true} form='couponForm' ref={submitRef} />
    </FormProvider>
  )
}

export const dateInputClasses = tw`h-10 w-36 rounded-lg bg-white border-0 ring-1 ring-gray-300 text-tBlack py-1.5 !pl-9 
placeholder:text-tBlack placeholder:text-opacity-50 focus:border-0 focus:ring-tGreen focus-visible:outline-none 
disabled:bg-gray-100 disabled:cursor-not-allowed`
