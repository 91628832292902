import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenCouponsQuery,
  useListenLegacyProductPricingQuery,
  useListenProductPricingQuery,
  useListenStripeProductIdQuery,
  useSetProductPricesMutation,
} from '../productInfoApi'

import { createUID } from 'common/utils/createUID'

import ProductInfoLayout from '../ProductInfoLayout'
import CouponsList from '../components/CouponsList/CouponsList'

export default function ProductInfoCoupons() {
  const navigate = useNavigate()
  const { userId } = useAuth()

  const [setProductPrices, { isLoading: isUpdatingProductPrices }] = useSetProductPricesMutation()

  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: couponsData } = useListenCouponsQuery({ coachOrgId })
  const couponsLoading = couponsData === undefined || couponsData?.isLoading
  const coupons = couponsData || {}

  const { data: legacyPlansData } = useListenLegacyProductPricingQuery({ coachOrgId })
  const legacyProductPlansLoading = legacyPlansData === undefined || legacyPlansData?.isLoading
  const legacyProductPlans = legacyProductPlansLoading || !legacyPlansData ? [] : legacyPlansData

  const { data: plansData } = useListenProductPricingQuery({ coachOrgId })
  const productPlansLoading = plansData === undefined || plansData?.isLoading
  const productPlans = productPlansLoading || !plansData ? {} : plansData

  const { data: stripeProductId } = useListenStripeProductIdQuery({ coachOrgId })
  const stripeProductIdLoading = stripeProductId === undefined || stripeProductId?.isLoading

  const loading =
    couponsLoading ||
    legacyProductPlansLoading ||
    productPlansLoading ||
    stripeProductIdLoading ||
    isUpdatingProductPrices

  useEffect(() => {
    // Migrate legacy array based plans to new object based plans
    if (!productPlansLoading && !legacyProductPlansLoading && isEmpty(productPlans) && !isEmpty(legacyProductPlans)) {
      let updatedPlans = {}
      legacyProductPlans.forEach((plan, index) => {
        const planId = createUID()
        updatedPlans[planId] = {
          ...plan,
          idx: plan?.idx || index,
          id: planId,
          stripe_plan_id: plan?.stripe_price_id || plan?.stripe_plan_id,
        }
      })
      setProductPrices({ coachOrgId, plans: updatedPlans })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPlansLoading, legacyProductPlansLoading])

  useEffect(() => {
    if (!loading && !stripeProductId) {
      navigate('/productinfo')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, stripeProductId])

  return (
    <ProductInfoLayout hasStripeProductId={Boolean(stripeProductId)}>
      <CouponsList coachOrgId={coachOrgId} coupons={coupons} loading={loading} />
    </ProductInfoLayout>
  )
}
