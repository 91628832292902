import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import tw from 'twin.macro'

import { AlertProvider } from 'common/components/Alert/context'

const productNav = [
  { id: 0, title: 'Info', link: '/productinfo' },
  { id: 1, title: 'Pricing', link: '/pricing' },
  { id: 2, title: 'Coupons', link: '/coupons' },
]

const generateStripeNav = [{ id: 0, title: 'Create New Product', link: '/productinfo' }]

export default function ProductInfoLayout({ children, hasStripeProductId }) {
  const { pathname } = useLocation()

  const navList = hasStripeProductId ? productNav : generateStripeNav

  return (
    <AlertProvider>
      <div className='flex justify-center bg-offWhite pt-12 px-16 min-h-screen'>
        <div className='flex flex-col w-full max-w-[1050px] mb-24'>
          <h1 className='text-5xl font-bold mb-5 text-tBlack'>Product Info</h1>
          <div className='flex'>
            <ul className='w-full max-w-[293px] mr-7' aria-label='account-navbar'>
              {navList.map((item) => (
                <li key={item.id} css={[NavItem]}>
                  <Link
                    to={item.link}
                    className='flex w-full py-5 px-5 hover:text-tGreen hover:transition-all transition-all'
                    css={[pathname === item.link ? tw`text-tGreen` : tw`text-tBlack`]}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <div className='flex flex-col p-8 bg-white w-full rounded-2xl'>{children}</div>
          </div>
        </div>
      </div>
    </AlertProvider>
  )
}
const NavItem = tw`flex items-center bg-white rounded-2xl mb-2 font-bold text-base`
