import React from 'react'

import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenStripeProductIdQuery, useListenStripeProductNameQuery } from '../productInfoApi'

import { Spinner } from 'common/components/Spinner/Spinner'
import ProductInfoLayout from '../ProductInfoLayout'
import { GenerateStripeProduct } from '../components/GenerateStripeProduct/GenerateStripeProduct'
import { ProductInfoForm } from '../components/ProductInfoForm/ProductInfoForm'

export default function ProductInfo() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: stripeProductId } = useListenStripeProductIdQuery({ coachOrgId })
  const stripeProductIdLoading = stripeProductId === undefined || stripeProductId?.isLoading

  const { data: stripeProductName } = useListenStripeProductNameQuery({ coachOrgId })
  const stripeProductNameLoading = stripeProductName === undefined || stripeProductName?.isLoading

  const isLoading = stripeProductNameLoading || stripeProductIdLoading

  if (isLoading) {
    return (
      <ProductInfoLayout hasStripeProductId={Boolean(stripeProductId)}>
        <Spinner className='w-10 h-10 text-gray-100 m-auto' />
      </ProductInfoLayout>
    )
  }

  if (!stripeProductId) {
    return (
      <ProductInfoLayout hasStripeProductId={Boolean(stripeProductId)}>
        <FormRefsControlProvider>
          <GenerateStripeProduct coachOrgId={coachOrgId} />
        </FormRefsControlProvider>
      </ProductInfoLayout>
    )
  }

  return (
    <ProductInfoLayout hasStripeProductId={Boolean(stripeProductId)}>
      <FormRefsControlProvider>
        <ProductInfoForm
          coachOrgId={coachOrgId}
          stripeProductId={stripeProductId}
          stripeProductName={stripeProductName}
        />
      </FormRefsControlProvider>
    </ProductInfoLayout>
  )
}
