import tw from 'twin.macro'

import { useEffect, useRef, useState } from 'react'
import { CgInfo } from 'react-icons/cg'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useSetCoachOrgSlugMutation } from 'modules/PageBuilder/pageBuilderApi'
import { getStandardizedName } from 'common/utils/stringUtils'

import { Input } from 'common/components/Input/Input'
import { Button } from 'common/components/Button/Button'
import { Tooltip } from 'common/components/Tooltip/Tooltip'

const reserverRoutes = {
  'delete-account': true,
  delete_account: true,
  faqs: true,
  'forgot-password': true,
  forgotpassword: true,
  redeem: true,
  terms: true,
  'upgrade-membership-error': true,
  upgrade_membership_error: true,
  userfaqs: true,
  'verify-email': true,
  verify_email: true,
  waiver: true,
}

export function CoachSlugForm({ coachOrgId, allOrgSlugs }) {
  const { createAlert } = useAlert()

  const slugsTaken = Object.values(allOrgSlugs)
  const currOrgSlug = allOrgSlugs?.[coachOrgId] || ''
  const [coachSlug, setCoachSlug] = useState(currOrgSlug)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [setCoachOrgSlug] = useSetCoachOrgSlugMutation()

  const { addInputRef, moveFocusOnKeyPress } = useFormRefsControl()
  const coachSlugRef = useRef()
  const submitBtnRef = useRef()
  const inputRefsSortMethod = ['coachSlug', 'submitBtn']
  useEffect(() => {
    addInputRef({ ref: coachSlugRef, name: 'coachSlug', sortMethod: inputRefsSortMethod })
    addInputRef({
      ref: submitBtnRef,
      name: 'submitBtn',
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSlug = async () => {
    setIsLoading(true)
    const stdCoachSlug = getStandardizedName(coachSlug)
    const isSlugTaken = slugsTaken.find((slug) => getStandardizedName(slug) === getStandardizedName(stdCoachSlug))
    if (reserverRoutes[stdCoachSlug] || isSlugTaken) {
      setError('This handle is taken, please choose a different handle.')
    } else {
      await setCoachOrgSlug({ coachOrgId, slug: stdCoachSlug })
      createAlert({
        text: currOrgSlug ? 'Handle updated!' : 'Handle created! You can now edit your pages!',
        type: 'success',
      })
    }
    setIsLoading(false)
  }

  useEventListener('keydown', moveFocusOnKeyPress)

  const nonAlphaNumericalRegex = /[\W_]+/g
  if (currOrgSlug) {
    return (
      <div className='flex flex-col'>
        <div className='self-start mb-4 bg-gray-100 rounded-md p-2 text-xs'>
          <span className='font-semibold text-tBlack'>URL example:</span>
          <span className='ml-1'>
            https://trybe.do/<span className='font-bold'>{coachSlug || 'your-page-handle'}</span>
          </span>
        </div>
        <div className='flex items-center mb-1'>
          <label htmlFor='productName' className='font-semibold text-tBlack cursor-pointer mr-1'>
            Page handle
          </label>
          <Tooltip
            content='Page handle is a root location of where all of your pages live'
            triggerClasses='cursor-pointer text-gray-500 hover:text-tGreen'
          >
            <CgInfo className='w-4 h-4' />
          </Tooltip>
        </div>
        <Input
          inputRef={coachSlugRef}
          name='coachSlug'
          placeholder='Enter your handle'
          value={coachSlug}
          onChange={(e) => {
            if (error) {
              setError(null)
            }
            setCoachSlug(e.target.value.replace(nonAlphaNumericalRegex, '-').toLowerCase())
          }}
          autoFocus={true}
          error={error}
          maxLength={40}
        />
        {error && <div className='text-xs mt-1 text-tRed'>{error}</div>}
        <Button
          ref={submitBtnRef}
          onClick={updateSlug}
          disabled={coachSlug.trim().length === 0 || currOrgSlug === coachSlug}
          loading={isLoading}
          css={tw`ml-auto mt-6`}
        >
          Edit
        </Button>
      </div>
    )
  }

  return (
    <div className='flex flex-col'>
      <h2 className='font-bold text-xl mb-2'>Create Your Page Handle</h2>
      <p className='text-gray-500 mb-4'>
        Before you begin building your pages, we want you to create your page handle. Page handle is a root location of
        where all of your pages live. You will be able to edit your page handle afterwards, but try to avoid doing that
        too often because it can negatively affect your page SEO.
      </p>
      <div className='self-start mb-4 bg-gray-100 rounded-md p-2 text-xs'>
        <span className='font-semibold text-tBlack'>URL example:</span>
        <span className='ml-1'>
          https://trybe.do/<span className='font-bold'>{coachSlug || 'your-page-handle'}</span>
        </span>
      </div>
      <Input
        inputRef={coachSlugRef}
        name='coachSlug'
        placeholder='Enter your handle'
        value={coachSlug}
        onChange={(e) => {
          if (error) {
            setError(null)
          }
          setCoachSlug(e.target.value.replace(nonAlphaNumericalRegex, '-').toLowerCase())
        }}
        autoFocus={true}
        error={error}
        maxLength={40}
      />
      {error && <div className='text-xs mt-1 text-tRed'>{error}</div>}
      <Button
        ref={submitBtnRef}
        onClick={updateSlug}
        disabled={coachSlug.trim().length === 0 || currOrgSlug === coachSlug}
        loading={isLoading}
        css={tw`ml-auto mt-6`}
      >
        Edit
      </Button>
    </div>
  )
}
