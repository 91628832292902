import tw from 'twin.macro'
import React from 'react'
import { Button } from 'common/components/Button/Button'

export function DeleteConfirmationBanner({
  text = 'Do you want to delete this item?',
  handleDelete,
  handleGoBack,
  loading = false,
  textCss,
  buttonVariant = 'danger',
  deleteText = 'Delete',
  containerClasses = '',
}) {
  return (
    <div className={`flex items-center justify-end px-4 md:px-10 py-6 bg-white shadow-t ${containerClasses}`}>
      <span className='flex-1 mr-4' css={textCss}>
        {text}
      </span>
      <Button variant='secondary' size='md' css={tw`mr-2`} onClick={() => handleGoBack()}>
        Go back
      </Button>
      <Button variant={buttonVariant} size='md' loading={loading} onClick={handleDelete}>
        {deleteText}
      </Button>
    </div>
  )
}
