import React from 'react'

export function SignupPreview() {
  return (
    <div className='flex flex-col border-2 p-4 border-gray-300 rounded-xl cursor-not-allowed'>
      <div className='flex items-center justify-center mb-3'>
        <p>Already have an account?</p>
        <button className='text-tGreen underline ml-2 hover:opacity-80 cursor-not-allowed' disabled>
          Log in
        </button>
      </div>
      <div>
        <div className='mb-3'>
          <input className={inputClasses} placeholder='First name*' disabled />
        </div>
        <div className='mb-3'>
          <input className={inputClasses} placeholder='Last name*' disabled />
        </div>
        <div className='mb-3'>
          <input className={inputClasses} placeholder='Email address*' disabled />
        </div>
        <div className='mb-3'>
          <input className={inputClasses} placeholder='Password (8+ characters)*' type='password' disabled />
        </div>
        <div className='my-2'>
          <div className='flex items-center'>
            <input
              id='termsChecked'
              type='checkbox'
              className='cursor-not-allowed rounded-sm border-black border-opacity-20 text-tGreen focus:ring-0'
              disabled
            />
            <label htmlFor='termsChecked' className='cursor-not-allowed text-black ml-2 text-xs'>
              I agree to the <span className='underline'>privacy policy</span>, <span className='underline'>terms</span>
              , and <span className='underline'>waiver</span>.
            </label>
          </div>
        </div>
        <div className='flex items-center justify-center text-white w-full rounded-lg bg-tGreen py-2.5 font-bold cursor-not-allowed'>
          Log in
        </div>
      </div>
    </div>
  )
}

const inputClasses = `
  ring-1 ring-gray-200 focus:ring-tGreen
  p-2 w-full border-none overflow-hidden 
  placeholder:text-gray-400 rounded
  cursor-not-allowed disabled:bg-transparent
`
