import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CgArrowRight, CgPen } from 'react-icons/cg'
import tw, { css } from 'twin.macro'
import { debounce } from 'lodash'

import {
  useListenDraftPrimaryColorQuery,
  useSetDraftPrimaryColorMutation,
  useUpdateSalesPageSeoMutation,
} from 'modules/PageBuilder/pageBuilderApi'

import SalesHeroPreview from './SalesHeroPreview'
import SalesHighlightsPreview from './SalesHighlightsPreview'
import SalesBannerPreview from './SalesBannerPreview'
import SalesAboutPreview from './SalesAboutPreview'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { ColorInput } from 'common/components/Color/ColorInput'
import { inputBase } from 'common/components/Input/Input'
import { buttonBase } from 'common/components/Button/Button'
import { DisplayModeSwitch } from '../DisplayModeSwitch/DisplayModeSwitch'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'

import logo from 'assets/images/TrybeLogoWhite.svg'
import appStore from 'assets/images/download-app-store.svg'
import googlePlay from 'assets/images/download-google-play.svg'
import FaqPreview from './FaqPreview'
import { SeoForm } from '../SeoForm/SeoForm'
import TestimonialPreview from './TestimonialPreview'
import { inputIds, inputNames } from '../../constants/salesInputConstants'

export default function SalesPagePreview({ coachOrgId, page, seo }) {
  const navigate = useNavigate()

  const [displayMode, setDisplayMode] = useState('desktop')
  const isMob = displayMode === 'mobile'

  const { data: primaryColorData } = useListenDraftPrimaryColorQuery({ coachOrgId })
  const primaryColor = primaryColorData || '#000000'

  const [updateSeo] = useUpdateSalesPageSeoMutation()
  const [setPrimaryColor] = useSetDraftPrimaryColorMutation()
  const debouncedSetColor = debounce((color) => setPrimaryColor({ coachOrgId, color }), 1000)

  const { moveFocusOnKeyPress } = useFormRefsControl()
  useEventListener('keydown', (e) => {
    if (
      e.code === 'Enter' &&
      (e.target.name === inputNames.heroBtnText ||
        e.target.name === inputNames.aboutBtnText ||
        e.target.name === inputNames.hlSectionTitle ||
        (e.target.name === inputNames.highlight && e.target.id === inputIds.highlightTitleId) ||
        e.target.name === inputNames.aboutTitle)
    ) {
      e.preventDefault()
    } else {
      moveFocusOnKeyPress(e)
    }
  })

  return (
    <>
      <div css={tw`flex justify-between items-end mb-2 w-full`}>
        <div className='w-1/3'>
          <ColorInput
            label='Primary Color'
            watchColor={primaryColor}
            onChange={(color) => debouncedSetColor(color)}
            inputCSS={[inputBase, tw`h-8 p-2 w-20 rounded ml-2`]}
          />
        </div>
        <div className='flex justify-center w-1/3'>
          <DisplayModeSwitch displayMode={displayMode} setDisplayMode={setDisplayMode} />
        </div>
        <div className='flex flex-col justify-center mb-3 items-end w-1/3'>
          <Dialog>
            <DialogTrigger css={[buttonBase]} className='text-tGray-med hover:text-tGreen mb-3'>
              <CgPen className='w-4 h-4 mr-1' />
              Edit SEO metadata
            </DialogTrigger>
            <DialogContent header='Sales page SEO'>
              <FormRefsControlProvider>
                <SeoForm coachOrgId={coachOrgId} seo={seo} updateSeo={updateSeo} uploadLocation='salesPage' />
              </FormRefsControlProvider>
            </DialogContent>
          </Dialog>
          <div className='flex items-center'>
            <button
              css={[buttonBase]}
              className='underline underline-offset-8 decoration-2 decoration-tGreen text-tGray-dark'
            >
              Sales page
            </button>
            <CgArrowRight className='w-5 h-5 mx-2 text-tGray-med' />
            <button
              css={[buttonBase]}
              className='text-tGray-med hover:text-tGreen'
              onClick={() => navigate('/pagebuilder/checkout')}
            >
              Checkout page
            </button>
          </div>
        </div>
      </div>
      <div css={[tw`flex flex-col w-full`, isMob && tw`max-w-sm`]}>
        <div className='flex items-center px-2 py-4 bg-black'>
          <img src={logo} alt='logo' className='w-8 h-8' />
          <span css={[tw`text-3xl text-white uppercase ml-2 leading-none tracking-widest`, isMob && tw`text-xl`]}>
            TRYBE
          </span>
        </div>
        <SalesHeroPreview coachOrgId={coachOrgId} section={page.heroSection} isMob={isMob} />
        <SalesHighlightsPreview coachOrgId={coachOrgId} section={page.programSummary} isMob={isMob} />
        <SalesBannerPreview coachOrgId={coachOrgId} section={page.banner} isMob={isMob} />
        <SalesAboutPreview coachOrgId={coachOrgId} section={page.aboutMe} isMob={isMob} />
        <TestimonialPreview coachOrgId={coachOrgId} testimonials={page.testimonials} isMob={isMob} />
        <FaqPreview coachOrgId={coachOrgId} faqs={page.faqs} isMob={isMob} />
        <div
          css={[
            isMob ? tw`text-lg` : tw`text-2xl`,
            tw`flex flex-col items-center justify-center text-white font-bold py-5 px-4`,
            css`
              background-color: ${primaryColor};
            `,
          ]}
        >
          <p className='mb-5 text-center'>Already signed up? Download the app here</p>
          <div className='flex items-center'>
            <img src={appStore} alt='app store download' />
            <img src={googlePlay} alt='google play download' className='ml-2.5' />
          </div>
        </div>
      </div>
    </>
  )
}
