import React, { useEffect, useRef, useState } from 'react'
import tw, { css } from 'twin.macro'

import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import {
  useListenDraftPrimaryColorQuery,
  useUpdateSalesBannerSectionMutation,
} from 'modules/PageBuilder/pageBuilderApi'

import { SALES_FIELDS_MAXLENGTH } from 'modules/PageBuilder/constants/salesConstants'
import PreviewInput from '../Inputs/PreviewInput'
import { inputNames, inputRefsSortMethod } from '../../constants/salesInputConstants'

export default function SalesBannerPreview({ coachOrgId, section, isMob }) {
  const [bannerText, setBannerText] = useState(section.bannerText)

  const { data: primaryColorData } = useListenDraftPrimaryColorQuery({ coachOrgId })
  const primaryColor = primaryColorData || '#000'

  const [updateSection] = useUpdateSalesBannerSectionMutation()

  const debouncedBannerText = useDebounce(bannerText, 500)
  useDebouncedTextMutation({
    stateText: bannerText,
    dbText: section.bannerText,
    mutation: updateSection,
    debouncedStateText: debouncedBannerText,
    mutationArgs: {
      coachOrgId,
      section: { ...section, bannerText: debouncedBannerText },
    },
  })

  useEffect(() => {
    setBannerText(section.bannerText)
  }, [section])

  const salesBannerTextRef = useRef()
  const { addInputRef } = useFormRefsControl()
  useEffect(() => {
    addInputRef({
      ref: salesBannerTextRef,
      name: inputNames.salesBannerText,
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className='flex items-center justify-center'
      css={[
        css`
          background-color: ${primaryColor};
        `,
      ]}
    >
      <div className='flex flex-col items-center justify-center py-4 px-5 w-full max-w-6xl'>
        <PreviewInput
          inputRef={salesBannerTextRef}
          name={inputNames.salesBannerText}
          inputCss={[
            isMob ? tw`text-lg` : tw`text-2xl`,
            tw`text-white text-center font-bold tracking-wide placeholder:text-gray-200`,
          ]}
          value={bannerText}
          maxLength={SALES_FIELDS_MAXLENGTH.banner.text}
          onChange={(_, value) => setBannerText(value)}
        />
      </div>
    </div>
  )
}
