import tw from 'twin.macro'
import React, { useRef, useState } from 'react'
import { CgSoftwareUpload } from 'react-icons/cg'
import {
  acceptedFileTypes,
  validateFile,
  IMG_FILESIZE_LIMIT,
  errorCodes,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  formatBytes,
} from 'common/utils/fileUploading/validators'

import { useListenExistingItemDraftsQuery, useUpdateExistingItemDraftsMutation } from 'modules/Uploads/uploadApi'

import { Button } from 'common/components/Button/Button'
import { Spinner } from 'common/components/Spinner/Spinner'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { AWSupload } from 'common/utils/fileUploading/uploadUtils'

const fileType = 'image'
const allowedFileTypes = acceptedFileTypes[fileType]
const customFileSizeLimit = IMG_FILESIZE_LIMIT

export default function ImageUpload({ coachOrgId, id, clearImage, uploadType, aspectRatio, wToHRatio }) {
  const { createAlert } = useAlert()
  const hiddenInputRef = useRef()
  const [progress, setProgress] = useState(0)
  const { data: isImgUploading } = useListenExistingItemDraftsQuery({ coachOrgId, id })

  const [updateDraft] = useUpdateExistingItemDraftsMutation()

  const uploadFile = async (e) => {
    const files = hiddenInputRef.current?.files
    const file = files[0]
    const { errorCode, valid } = await validateFile({
      file,
      fileType,
      customFileSizeLimit,
      customWtoHRatio: wToHRatio,
    })
    if (valid) {
      setProgress(0)
      console.log('file is valid', valid)
      await updateDraft({ coachOrgId, id, drafts: { previewImg: { compressing: true } } })
      const result = await AWSupload({
        file,
        uniqueId: id, //Typically a UID, but in this case it matches uploadType because there will only be one sales-hero-image, one checkout-hero-image, etc. per coachOrg
        coachOrgId,
        uploadType, //Guides the AWS cloud function on image settings
        assetType: 'image',
        setProgressCb: setProgress,
      })
      if (result) {
        // //Reset progress on finished upload
        if (clearImage) {
          await clearImage()
        }
      }
      console.log('result', result)
    } else if (errorCode) {
      console.log('errorCode', errorCode)
      const errorMessage = getErrorMessage(errorCode, allowedFileTypes, customFileSizeLimit, aspectRatio)
      createAlert({ text: errorMessage, type: 'danger' })
    }
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      {isImgUploading ? (
        <Button
          size='md'
          variant='secondary'
          type='button'
          tabIndex={-1}
          css={[btnCSS, tw`disabled:opacity-100`]}
          disabled={true}
        >
          <Spinner className='mr-2 w-4 h-4 text-gray-100' />
          {`Uploading ${progress}%`}
        </Button>
      ) : (
        <Button
          size='md'
          variant='secondary'
          type='button'
          onClick={() => {
            hiddenInputRef.current.value = null
            hiddenInputRef.current.click()
          }}
          tabIndex={-1}
          css={btnCSS}
        >
          <CgSoftwareUpload className='mr-2 w-4 h-4' />
          Upload image
        </Button>
      )}

      <div className='hidden'>
        <input
          type='file'
          id='hero-image-file-input-hidden'
          className='hidden'
          ref={hiddenInputRef}
          accept={allowedFileTypes}
          onChange={uploadFile}
        />
      </div>
    </div>
  )
}

function getErrorMessage(errorCode, allowedFileTypes, usedSizeLimit, aspectRatio) {
  if (!errorCode) return ''
  switch (errorCode) {
    case errorCodes.UPLOAD_ERROR:
      return 'An error occured. Please try again or contact support@trybe.do.'
    case errorCodes.INVALID_TYPE:
      return `Invalid file type. Allowed file types are: ${allowedFileTypes}.`
    case errorCodes.TOO_LARGE:
      const sizeText = formatBytes(usedSizeLimit)
      const message = `The file size exceeds ${sizeText}. Please compress the file before uploading.`
      return message
    case errorCodes.INVALID_IMG_DIM:
      return `Please use image with ${aspectRatio} aspect ratio.`
    case errorCodes.IMG_TOO_NARROW:
      return `Image too narrow. For optimal experience, please use 16:9 aspect ratio or closer to it.`
    case errorCodes.IMG_TOO_WIDE:
      return `Image too wide. For optimal experience, please use 16:9 aspect ratio or closer to it.`
    case errorCodes.INVALID_NAME:
      return 'File name includes disallowed special characters.'
    case errorCodes.LONG_NAME:
      return `File name exceeds ${NAME_MAX_LENGTH} characters.`
    case errorCodes.SHORT_NAME:
      return `File name should be at least ${NAME_MIN_LENGTH} characters long.`
    default:
      return 'An error occured. Please try again or contact support@trybe.do.'
  }
}

export const btnCSS = tw`
  bg-gray-100
  hover:bg-gray-100
  text-gray-600
  hover:ring-2
  hover:ring-tGray-ml
  rounded-md
  px-4
  py-2
  text-xs
  font-medium
  w-[190px]
`
