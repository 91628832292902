import 'twin.macro'

import React from 'react'
import { buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { CreateDialogBanner } from 'common/components/CreateDialogBanner/CreateDialogBanner'

export default function TopBar({ handlePublishPage, isPublishing, pageTitle }) {
  return (
    <div className='flex items-center justify-between'>
      <div className='w-1/2'>
        <h1 className='font-bold text-5xl text-black'>{pageTitle}</h1>
      </div>
      <div className='flex justify-end ml-6 w-1/2'>
        <Dialog>
          <DialogTrigger css={[buttonBase, buttonSizes.lg, buttonVariants.primary]} aria-label='Publish'>
            Publish page
          </DialogTrigger>
          <DialogContent>
            <CreateDialogBanner
              text={`This will publish the changes of ${pageTitle}`}
              handleCreate={handlePublishPage}
              createBtnText='Publish'
              loading={isPublishing}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
