import React, { useEffect, useRef } from 'react'
import { CgAdd, CgTrash, CgCheck } from 'react-icons/cg'
import { useForm, FormProvider } from 'react-hook-form'
import tw from 'twin.macro'

import { useUpdateCheckoutBenefitsMutation } from 'modules/PageBuilder/pageBuilderApi'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { Button } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import PreviewInput from '../Inputs/PreviewInput'
import { CHECKOUT_FIELDS_MAXLENGTH } from 'modules/PageBuilder/constants/checkoutConstants'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { inputRefsSortMethod } from '../PagePreview/CheckoutPagePreview'

const BENEFITS_LIMIT = 6

export function BenefitsInputList({ coachOrgId, benefits, isMob }) {
  const [updateBenefits] = useUpdateCheckoutBenefitsMutation()

  const defaultValues = { benefits }

  const methods = useForm({
    defaultValues,
    context: { benefits },
  })

  const { watch, setValue, handleSubmit } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    await updateBenefits({ coachOrgId, benefits: data.benefits })
  }

  const handleDeleteBenefit = (bIdx) => {
    const newBenefits = formState.benefits.filter((benefit, index) => index !== bIdx)
    setValue('benefits', newBenefits)
  }

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit))
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch])

  const { addInputRef } = useFormRefsControl()
  const addBenefitBtnRef = useRef()
  useEffect(() => {
    addInputRef({ ref: addBenefitBtnRef, name: 'addBenefitBtn', sortMethod: inputRefsSortMethod })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='benefitsForm'
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.code === 'Tab') {
            e.preventDefault() //Otherwise form autosubmits on each enter press
          }
        }}
      >
        {formState.benefits.map((benefit, index) => (
          <Benefit
            key={index}
            index={index}
            benefit={benefit}
            handleDelete={handleDeleteBenefit}
            setValue={setValue}
            isMob={isMob}
          />
        ))}
        {benefits.length < BENEFITS_LIMIT ? (
          <Tooltip content='Add benefit'>
            <Button
              ref={addBenefitBtnRef}
              type='button'
              variant='secondary'
              size='md'
              css={[tw`w-full mt-2`, isMob ? tw`mx-6 w-[calc(100% - 48px)]` : tw`mx-8 w-[calc(100% - 64px)]`]}
              onClick={() => setValue(`benefits.${benefits.length}`, 'New benefit')}
            >
              <CgAdd className='w-5 h-5 mr-2' /> Add benefit
            </Button>
          </Tooltip>
        ) : null}
      </form>
    </FormProvider>
  )
}

function Benefit({ benefit, index, handleDelete, setValue, isMob }) {
  const { addInputRef, removeInputRef } = useFormRefsControl()
  const benefitRef = useRef()

  useEffect(() => {
    removeInputRef(benefitRef, 'benefit')
    addInputRef({
      ref: benefitRef,
      name: 'benefit',
      sortMethod: inputRefsSortMethod,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <div className='relative flex items-center mb-1 group' css={[isMob ? tw`pl-6 pr-7` : tw`pl-8 pr-9`]}>
      <div
        className='absolute top-1/2 -translate-y-1/2 h-full invisible group-hover:visible'
        css={[isMob ? tw`right-1` : tw`right-2`]}
      >
        <Dialog>
          <Tooltip content='Remove benefit'>
            <DialogTrigger
              className='flex items-center justify-center h-full bg-offWhite hover:bg-offWhite-dark'
              css={[isMob ? tw`w-5 rounded-md` : tw`w-6 rounded-lg`]}
              aria-label='Remove benefit'
            >
              <CgTrash css={[isMob ? tw`w-4 h-4` : tw`w-5 h-5`]} />
            </DialogTrigger>
          </Tooltip>
          <DialogContent>
            <DeleteDialogBanner
              text={`This will delete benefit #${index + 1}`}
              handleDelete={() => handleDelete(index)}
            />
          </DialogContent>
        </Dialog>
      </div>
      <CgCheck className='w-8 h-8 text-tGreen min-w-8' />
      <PreviewInput
        inputRef={benefitRef}
        name='benefit'
        inputCss={tw`text-sm py-1`}
        value={benefit}
        maxLength={CHECKOUT_FIELDS_MAXLENGTH.benefits}
        onChange={(_, value) => setValue(`benefits.${index}`, value)}
      />
    </div>
  )
}
